import { Box, SxProps } from "@mui/material";
import OpenFolderImage from "./OpenFolderImage";
import { useTranslation } from "react-i18next";
import TextHeader from "./TextHeader";
import DefaultText from "./DefaultText";

interface EmptyTrackingResultProps {
  header: string;
  message?: string;
  sx?: SxProps;
}

const EmptyTrackingResult = ({ header, message, sx }: EmptyTrackingResultProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: "24px 35px",
        height: "350px",
        backgroundColor: "rgba(247, 248, 251, 1)",
        border: "1px solid rgba(213, 219, 227, 1)",
        borderRadius: "8px",
        ...sx,
      }}
    >
      <TextHeader sx={{ fontSize: "24px" }}>{header}</TextHeader>
      {message && <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", color: "rgba(78, 93, 105, 1)" }}>{message}</DefaultText>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "center",
          height: "inherit",
          justifyContent: "center",
          marginTop: "-15px",
        }}
      >
        <OpenFolderImage />
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 700, color: "rgba(78, 93, 105, 1)" }}>{t("no-data-for-selected-period")}</DefaultText>
      </Box>
    </Box>
  );
};

export default EmptyTrackingResult;
