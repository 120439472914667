import TextHeader from "./TextHeader";
import { Trans, useTranslation } from "react-i18next";
import { Box, SxProps } from "@mui/material";
import ObservationItem from "./ObservationItem";
import DefaultText from "./DefaultText";
import { useAppState } from "../state/AppState";
import InfoIcon from "./icons/InfoIcon";
import { ObservationReportEntry, ObservationType, ScheduleType } from "../model/report.model";
import EmptyTrackingResult from "./EmptyTrackingResult";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import { useParams } from "react-router";
import Link from "./Link";

interface ObservationsGroupProps {
  data: ObservationReportEntry[];
  observationType: ObservationType;
  scheduleType?: ScheduleType;
  scheduleId?: string;
  sx?: SxProps;
}

const ObservationsGroup = ({ data, observationType, scheduleType, scheduleId, sx }: ObservationsGroupProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { patientProfilePageState } = useAppState();
  const sortedObservations = data.sort((a, b) => t(a.code.replace(/_/g, "-").toLowerCase()).localeCompare(t(b.code.replace(/_/g, "-").toLowerCase())));
  const header = observationType.replace(/_/g, "-").toLowerCase();

  const observationsChartsUrl = (): string => {
    const queryParams = new URLSearchParams({
      observationType: observationType,
    });
    if (scheduleType) {
      queryParams.append("scheduleType", scheduleType);
    }
    if (scheduleId) {
      queryParams.append("scheduleId", scheduleId);
    }
    return `/patients/${id}/${patientProfilePageState.numberOfDays}/trackingHistory?${queryParams}`;
  };

  return (
    <Box sx={{ paddingBottom: "24px" }}>
      {data.length > 0 && (
        <Box sx={{ padding: "24px 35px", backgroundColor: "rgba(247, 248, 251, 1)", border: "1px solid rgba(213, 219, 227, 1)", borderRadius: "8px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <TextHeader sx={{ fontSize: "24px" }}>{t(header)}</TextHeader>
              <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", color: "rgba(78, 93, 105, 1)", paddingBottom: "16px" }}>{t("observations-by-guardian")}</DefaultText>
            </Box>
            <Link to={observationsChartsUrl()}>
              <DefaultSecondaryButton sx={{ marginLeft: "34px" }}>{t("view-details")}</DefaultSecondaryButton>
            </Link>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "13px" }}>
            {sortedObservations.map((item, index) => (
              <ObservationItem key={index} {...item} />
            ))}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px", paddingTop: "8px" }}>
            <InfoIcon sx={{ width: "16px", height: "16px" }} />
            <DefaultText sx={{ fontSize: "12px" }}>
              <Trans
                i18nKey="arrows-show-trend"
                components={{ b: <b style={{ color: "rgba(4, 11, 16, 1)" }}></b> }}
                values={{
                  days: patientProfilePageState.numberOfDays,
                }}
              />
            </DefaultText>
          </Box>
        </Box>
      )}
      {data.length === 0 && <EmptyTrackingResult header={t(header)} message={t("observations-by-guardian")} sx={{ ...sx }} />}
    </Box>
  );
};

export default ObservationsGroup;
