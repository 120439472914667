import { Box, SxProps } from "@mui/material";
import { useAppState } from "../state/AppState";
import { PatientProfileTab } from "../state/PatientProfilePageState";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import DefaultTabs from "./DefaultTabs";
import ReportTypeSelectorTab from "./ReportTypeSelectorTab";
import RatingScalesTab from "./RatingScalesTab";
import { useParams } from "react-router-dom";

const PatientProfileTabs = ({ sx }: { sx: SxProps }) => {
  const { t } = useTranslation();
  const { patientProfilePageState } = useAppState();
  const { id, reportPeriod } = useParams();

  const tabsData = [
    {
      value: PatientProfileTab.REPORT,
      label: t("report"),
      link: `/patients/${id}/${reportPeriod}/`,
    },
    {
      value: PatientProfileTab.RATING_SCALES,
      label: t("rating-scales"),
      link: `/patients/${id}/${reportPeriod}/ratingScales/`,
    },
  ];

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      patientProfilePageState.activeTab = tabIndex;
    },
    [patientProfilePageState]
  );

  return (
    <Box sx={{ ...sx }}>
      <DefaultTabs data={tabsData} value={patientProfilePageState.activeTab} onChange={handleTabChange} />
      <Box
        sx={{
          backgroundColor: "rgba(249, 249, 249, 1)",
          padding: "32px",
          border: "1px solid rgba(180, 116, 237, 1)",
          borderRadius: "0px 8px 8px 8px",
        }}
      >
        {patientProfilePageState.isActive(PatientProfileTab.REPORT) && <ReportTypeSelectorTab />}
        {patientProfilePageState.isActive(PatientProfileTab.RATING_SCALES) && <RatingScalesTab />}
      </Box>
    </Box>
  );
};

export default observer(PatientProfileTabs);
