import { BasicPatientListEntry, Patient, PatientListEntry, TimeRangeInput } from "../model/patient.model";
import { PatientTrackingHistoryReport, PatientReport, ObservationType, ScheduleType, MedicationScheduleDto } from "../model/report.model";
import { Api, ApiResponsePromise, withErrorHandling, withErrorPage } from "./Api";
import dayjs from "dayjs";
import { refreshAccessTokenHook } from "./responseHooks/refreshAccessTokenHook";
import { EventDto } from "../model/event.model";

export interface TrackingHistory {
  patientId: string;
  timeRangeInput: TimeRangeInput;
  observationType?: ObservationType;
  scheduleType?: ScheduleType;
  scheduleId?: string;
}

const dateFromTimestamp = (timestamp: number) => {
  return dayjs(timestamp).format("YYYY-MM-DD");
};

class PatientApi extends Api {
  getPatient = (id: string): ApiResponsePromise<Patient> => this.get(`patients/${id}`, withErrorPage());
  getEmrPatients = (healthRecordNumber: string): ApiResponsePromise<BasicPatientListEntry[]> => this.get(`emr/patients?hrn=${healthRecordNumber}`, withErrorPage());
  getPatients = (): ApiResponsePromise<PatientListEntry[]> => this.get("patients", withErrorPage());
  getReport = (id: string, { from, to, tzName }: TimeRangeInput): ApiResponsePromise<PatientReport> =>
    this.get(`patients/${id}/report?from=${dateFromTimestamp(from)}&to=${dateFromTimestamp(to)}&tzName=${tzName}`, withErrorPage());
  getSchedules = (id: string, { from, to }: TimeRangeInput): ApiResponsePromise<MedicationScheduleDto[]> => {
    return this.get(`patients/${id}/schedules?from=${from}&to=${to}`, withErrorPage());
  };
  getTrackingHistoryData = ({ patientId, timeRangeInput, observationType, scheduleId, scheduleType }: TrackingHistory): ApiResponsePromise<PatientTrackingHistoryReport> => {
    const queryParams = new URLSearchParams({
      from: dateFromTimestamp(timeRangeInput.from),
      to: dateFromTimestamp(timeRangeInput.to),
      tzName: timeRangeInput.tzName as string,
    });
    if (observationType) {
      queryParams.append("observationType", observationType);
    }
    if (scheduleType) {
      queryParams.append("scheduleType", scheduleType);
    }
    if (scheduleId) {
      queryParams.append("scheduleId", scheduleId);
    }
    return this.get(`patients/${patientId}/report/trackingHistory?${queryParams.toString()}`, withErrorPage());
  };
  getEvents = (id: string, { from, to }: TimeRangeInput): ApiResponsePromise<EventDto[]> =>
    this.get(`patients/${id}/report/events?from=${dateFromTimestamp(from)}&to=${dateFromTimestamp(to)}`, withErrorPage());
  setHrn = (patientId: string, healthRecordNumber: string): ApiResponsePromise<void> => {
    return this.put(`patients/${patientId}/hrn`, withErrorHandling({ requestPayload: { healthRecordNumber }, expectJsonResponse: false }));
  };
}

export const patientApi = new PatientApi().useResponseHooks([refreshAccessTokenHook]);
