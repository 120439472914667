import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import TextLabel from "./TextLabel";

interface IconAndTextProps {
  text: string;
  description: string;
}

const TextAndDescription = ({ text, description }: IconAndTextProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <DefaultText>{text}</DefaultText>
      <TextLabel sx={{ paddingLeft: "15px", fontSize: "12px" }}>{`(${description})`}</TextLabel>
    </Box>
  );
};

export default TextAndDescription;
