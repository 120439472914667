import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { initTranslations } from "./i18n";
import { RootContext, state } from "./state/AppState";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import MainLayout from "./layouts/MainLayout";
import MyPatientsPage from "./pages/MyPatientsPage";
import PatientProfilePage from "./pages/PatientProfilePage";
import UserGuidePage from "./pages/UserGuidePage";
import RegistrationLayout from "./layouts/RegistrationLayout";
import AnonymousLayout from "./layouts/AnonymousLayout";
import NewInvitationPage from "./pages/NewInvitationPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import InvitationGuard from "./InvitationGuard";
import CreateCredentialsPage from "./pages/CreateCredentialsPage";
import DeclinedTermsConditionsPage from "./pages/DeclinedTermsConditionsPage";
import CreatePersonalProfilePage from "./pages/CreatePersonalProfilePage";
import LoginPage from "./pages/LoginPage";
import NotificationProvider from "./components/NotificationProvider";
import PublicOnlyGuard from "./PublicOnlyGuard";
import AuthGuard from "./AuthGuard";
import PatientProfileRedirect from "./components/PatientProfileRedirect";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import CreateNewPasswordPage from "./pages/CreateNewPasswordPage";
import ResetPasswordLayout from "./layouts/ResetPasswordLayout";
import FhirLaunchPage from "./pages/FhirLaunchPage";
import FhirRedirectHandlerPage from "./pages/FhirRedirectHandlerPage";
import EmrPatientsPage from "./pages/EmrPatientsPage";
import SettingsPage from "./pages/SettingsPage";
import RatingScaleFormPage from "./pages/RatingScaleFormPage";

initTranslations();
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route Component={() => <AuthGuard />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<MyPatientsPage />} />
          <Route path="/patients" element={<MyPatientsPage />} />
          <Route path="/patients/:id/" element={<PatientProfileRedirect />} />
          <Route path="/patients/:id/ratingScales/:ratingScaleId/:secondRatingScaleId?" element={<RatingScaleFormPage />} />
          <Route path="/patients/:id/:reportPeriod/*" element={<PatientProfilePage />} />
          <Route path="/user-guide" element={<UserGuidePage />} />
          <Route path="/fhir/launch" element={<FhirLaunchPage />} />
          <Route path="/fhir/handler" element={<FhirRedirectHandlerPage />} />
          <Route path="/fhir/patients/:hrn" element={<EmrPatientsPage />} />
          <Route path="/settings/*" element={<SettingsPage />} />
          <Route path="/*" element={<ErrorPage code={404} />} />
        </Route>
      </Route>
      <Route element={<RegistrationLayout />}>
        <Route element={<InvitationGuard type="NewInvitation" />}>
          <Route path="/invitation" element={<NewInvitationPage />} />
        </Route>
        <Route element={<InvitationGuard type="TermsAndConditions" />}>
          <Route path="/register/step1" element={<TermsAndConditionsPage />} />
        </Route>
        <Route element={<InvitationGuard type="DeclinedTermsConditions" />}>
          <Route path="/register/step1/declined" element={<DeclinedTermsConditionsPage />} />
        </Route>
        <Route element={<InvitationGuard type="CreateCredentials" />}>
          <Route path="/register/step2" element={<CreateCredentialsPage />} />
        </Route>
        <Route element={<InvitationGuard type="CreatePersonalProfile" />}>
          <Route path="/register/step3" element={<CreatePersonalProfilePage />} />
        </Route>
      </Route>

      <Route element={<PublicOnlyGuard />}>
        <Route element={<RegistrationLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        </Route>
      </Route>

      <Route element={<ResetPasswordLayout />}>
        <Route path="/passwordReset" element={<CreateNewPasswordPage />} />
      </Route>

      <Route element={<AnonymousLayout />}>
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Route>
    </>
  )
);

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RootContext.Provider value={state}>
        <NotificationProvider>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </NotificationProvider>
      </RootContext.Provider>
    </LocalizationProvider>
  );
};

export default App;
