import { Box, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import FHIR from "fhirclient";
import Client from "fhirclient/lib/Client";
import FhirErrorPage from "./FhirErrorPage";
import { fhirclient } from "fhirclient/lib/types";
import { useAppState } from "../state/AppState";
import { FhirPatient, FhirPatientIdentifier } from "../model/patient.model";
import { Page } from "../state/NavigationState";
import { useNavigate } from "react-router-dom";

const FhirRedirectHandlerPage = () => {
  const { navigation } = useAppState();
  const [error, setError] = useState<boolean>(false);
  const { user } = useAppState();
  const navigate = useNavigate();

  useEffect(() => {
    navigation.backgroundColor = "#F0F4FA";
    navigation.activePage = Page.MY_PATIENTS;
  }, [navigation]);

  const handleEhrRedirect = useCallback(async () => {
    const fhirPatient: FhirPatient | null = await getPatientFromEhr();
    if (fhirPatient) {
      const systemIdentifier: FhirPatientIdentifier | undefined = fhirPatient.identifier.find(
        (identifierEntry) => identifierEntry.system === user.loggedUser?.healthcareProvider?.ehrLaunchParams?.systemName
      );
      if (systemIdentifier) {
        navigate(`/fhir/patients/${systemIdentifier.value}`);
      } else {
        console.error(`Cannot find HRN number by systemName=${user.loggedUser?.healthcareProvider?.ehrLaunchParams?.systemName}`);
        setError(true);
      }
    } else {
      console.error("Cannot retrieve patient data from EHR");
      setError(true);
    }
  }, [navigate, user.loggedUser?.healthcareProvider?.ehrLaunchParams?.systemName]);

  const getPatientFromEhr = async (): Promise<FhirPatient | null> => {
    try {
      const client: Client = await FHIR.oauth2.ready();
      const patient: fhirclient.FHIR.Patient = await client.patient.read();
      //@ts-ignore
      return patient as FhirPatient;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  useEffect(() => {
    navigation.activePage = Page.MY_PATIENTS;
    const isEhrLaunchEnabled = user.loggedUser?.healthcareProvider !== null && user.loggedUser?.healthcareProvider?.ehrLaunchEnabled;
    if (isEhrLaunchEnabled) {
      handleEhrRedirect();
    } else {
      console.error("EHR Launch is not enabled or Healthcare Provider is not set");
      setError(true);
    }
  }, [handleEhrRedirect, navigation, user.loggedUser?.healthcareProvider]);

  if (error) {
    return <FhirErrorPage />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)" }} size="56px" />
    </Box>
  );
};

export default FhirRedirectHandlerPage;
