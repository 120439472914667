import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import FHIR from "fhirclient";
import { useAppState } from "../state/AppState";
import FhirErrorPage from "./FhirErrorPage";
import { Page } from "../state/NavigationState";
import { useSearchParams } from "react-router-dom";

const FhirLaunchPage = () => {
  const { user } = useAppState();
  const { navigation } = useAppState();
  const [error, setError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    navigation.activePage = Page.MY_PATIENTS;
    const isEhrLaunchEnabled = user.loggedUser?.healthcareProvider !== null && user.loggedUser?.healthcareProvider?.ehrLaunchEnabled;
    const ehrLaunchParams = user.loggedUser?.healthcareProvider?.ehrLaunchParams;
    if (!isEhrLaunchEnabled || ehrLaunchParams?.iss !== searchParams.get("iss")) {
      console.error("EHR Launch is not enabled or issuer URL is wrong");
      setError(true);
    } else {
      try {
        FHIR.oauth2.authorize({
          client_id: ehrLaunchParams?.clientId,
          scope: ehrLaunchParams.scopes,
          redirectUri: `https://${window.location.host}/fhir/handler`,
        });
      } catch (e) {
        console.error("Error while calling EHR");
        console.error(e);
        setError(true);
      }
    }
  }, [navigation, searchParams, user.loggedUser?.healthcareProvider]);

  if (error) {
    return <FhirErrorPage />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)" }} size="56px" />
    </Box>
  );
};

export default FhirLaunchPage;
