import { makeAutoObservable } from "mobx";

export enum Page {
  MY_PATIENTS,
  USER_GUIDE,
}

export interface BreadcrumbsElement {
  path?: string;
  labelId: string;
}

export class NavigationState {
  private _activePage: Page | null = null;
  private _breadcrumbsElements: BreadcrumbsElement[] = [];
  private _backgroundColor = "white";
  constructor() {
    makeAutoObservable(this);
  }

  set activePage(value: Page | null) {
    this._activePage = value;
  }

  get activePage(): Page | null {
    return this._activePage;
  }

  set breadcrumbsElements(value: BreadcrumbsElement[]) {
    this._breadcrumbsElements = value;
  }

  get breadcrumbsElements() {
    return this._breadcrumbsElements;
  }

  set backgroundColor(value: string) {
    this._backgroundColor = value;
  }

  get backgroundColor() {
    return this._backgroundColor;
  }

  isActive(page: Page) {
    return this._activePage === page;
  }
}
