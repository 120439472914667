import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import { Box, Skeleton } from "@mui/material";
import { useAppState } from "../state/AppState";
import { ObservationType, PatientTrackingHistoryReport } from "../model/report.model";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TrackingHistory, patientApi } from "../api/PatientApi";
import { observer } from "mobx-react-lite";
import PurpleArrowLeftIcon from "./icons/PurpleArrowLeftIcon";
import { withLoader } from "../hoc/withLoader";
import TrackingHistoryChartsGroup from "./TrackingHistoryChartsGroup";
import Link from "./Link";
import MedicationScheduleSwitcher from "./MedicationScheduleSwitcher";

const LoadingTrackingHistoryChartsGroup = withLoader(TrackingHistoryChartsGroup);

const TrackingHistoryChartsContainer = () => {
  const { t } = useTranslation();
  const { patientProfilePageState } = useAppState();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const observationType = queryParams.get("observationType");
  const scheduleType = queryParams.get("scheduleType");
  const scheduleId = queryParams.get("scheduleId");
  const [trackingHistoryChartData, setTrackingHistoryChartData] = useState<PatientTrackingHistoryReport | null>(null);
  const header = observationType?.replace(/_/g, "-").toLowerCase() || "medication-taken";

  useEffect(() => {
    const getData = async () => {
      const requestData = {
        patientId: id as string,
        timeRangeInput: patientProfilePageState.timeRangeInput,
        observationType: observationType as ObservationType,
        scheduleType,
        scheduleId,
      } as TrackingHistory;
      const response = await patientApi.getTrackingHistoryData(requestData);
      if (response.ok) {
        setTrackingHistoryChartData(response.data);
      }
    };
    setTrackingHistoryChartData(null);
    if (patientProfilePageState.numberOfDays) {
      getData();
    }
  }, [id, observationType, patientProfilePageState, patientProfilePageState.timeRangeInput, scheduleId, scheduleType]);

  return (
    <Box sx={{ paddingBottom: "25px" }}>
      <TextHeader sx={{ paddingBottom: "25px" }}>{t(header as string)}</TextHeader>
      <Link
        to={`/patients/${id}/${patientProfilePageState.numberOfDays}`}
        sx={{ width: "160px", gap: "8px", display: "flex", alignItems: "center", fontSize: "16px", color: "rgba(145, 84, 201, 1)", marginBottom: "32px" }}
      >
        <PurpleArrowLeftIcon />
        {t("back-to-overview")}
      </Link>
      <MedicationScheduleSwitcher />
      <LoadingTrackingHistoryChartsGroup trackingHistoryData={trackingHistoryChartData as PatientTrackingHistoryReport} isLoading={trackingHistoryChartData === null}>
        <Box sx={{ height: 84, flex: 1, display: "flex", marginY: "16px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
        <Box sx={{ height: 48, flex: 1, display: "flex", marginBottom: "40px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
        <Box sx={{ height: 32, flex: 1, display: "flex", marginBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "200px" }} />
        </Box>
        <Box sx={{ height: 322, flex: 1, display: "flex", marginBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
      </LoadingTrackingHistoryChartsGroup>
    </Box>
  );
};

export default observer(TrackingHistoryChartsContainer);
