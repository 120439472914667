import { Box } from "@mui/material";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ErrorBoundary } from "../ErrorBoundary";
import ErrorPage from "../pages/ErrorPage";
import { getErrorState } from "../state/ErrorState";
import Footer from "../Footer";
import Logo from "../components/Logo";
import HcpImageDescription from "../components/HcpImageDescription";
import { observer } from "mobx-react-lite";

const RegistrationLayout = () => {
  const errorState = getErrorState();

  return (
    <>
      {errorState.code !== null ? (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#F0F4FA",
            paddingX: "5%",
            overflowY: "auto",
          }}
        >
          <Logo sx={{ width: "201px", height: "42px", paddingTop: "60px" }} />
          <ErrorPage code={errorState.code as number} />
          <Footer privacyPolicy sx={{ paddingTop: "40px" }} />
        </Box>
      ) : (
        <ErrorBoundary>
          <Box sx={{ overflowY: "auto", paddingX: "40px", paddingTop: "40px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", columnGap: "130px" }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: "60px", paddingLeft: "70px" }}>
                <Box sx={{ minWidth: "370px" }}>
                  <Logo sx={{ width: "201px", height: "42px" }} />
                  <Outlet />
                </Box>
              </Box>
              <HcpImageDescription />
            </Box>
            <Footer privacyPolicy sx={{ marginTop: "-40px", paddingLeft: "70px", paddingBottom: "40px" }} />
          </Box>
        </ErrorBoundary>
      )}
      <ScrollRestoration />
    </>
  );
};

export default observer(RegistrationLayout);
