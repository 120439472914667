import { Box } from "@mui/material";
import { useEffect } from "react";
import { useAppState } from "../state/AppState";
import { Page } from "../state/NavigationState";

const UserGuidePage = () => {
  const { navigation } = useAppState();

  useEffect(() => {
    navigation.activePage = Page.USER_GUIDE;
    navigation.breadcrumbsElements = [
      {
        labelId: "user-guide",
        path: "/user-guide",
      },
    ];
  }, [navigation]);

  return <Box>TODO: User Guide Page</Box>;
};

export default UserGuidePage;
