import { Api, ApiResponse, ApiResponsePromise, RequestParams, withErrorHandling, withErrorPage } from "./Api";
import { HcpUpdateDto, HcpUserWithHealthcareProvider } from "../model/user.model";
import { Dictionary } from "../model/dictionary.model";
import { refreshAccessTokenHook } from "./responseHooks/refreshAccessTokenHook";
import { HealthcareProviders } from "../model/healthcareProvider.model";
import { TerminablePromise, createTerminableRequest } from "./controllers/TerminableRequest";

class UserApi extends Api {
  login = (email: string, password: string, params?: Partial<RequestParams>): ApiResponsePromise<void> => {
    return this.post(
      "users/login",
      withErrorHandling({
        requestPayload: { email, password },
        refreshAT: false,
        expectJsonResponse: false,
        ...params,
      })
    );
  };
  me = (params?: Partial<RequestParams>): ApiResponsePromise<HcpUserWithHealthcareProvider> => {
    return this.get("users/me", withErrorHandling(params));
  };
  updateUserData = (userData: HcpUpdateDto): ApiResponsePromise<HcpUserWithHealthcareProvider> => {
    return this.put("users/me", withErrorPage({ requestPayload: userData, expectJsonResponse: false }));
  };
  logout = (): ApiResponsePromise<void> => {
    return this.post("users/logout", withErrorHandling({ refreshAT: false, expectJsonResponse: false }));
  };
  resetPasswordInit = (email: string): ApiResponsePromise<void> => {
    return this.post("password/reset/init", withErrorHandling({ requestPayload: { email }, refreshAT: false, expectJsonResponse: false }));
  };
  resetPasswordVerify = (email: string, token: string): ApiResponsePromise<void> => {
    return this.post("password/reset/verify", withErrorHandling({ requestPayload: { email, token }, refreshAT: false, expectJsonResponse: false }));
  };
  resetPasswordConfirm = (email: string, token: string, password: string, confirmationCode: string): ApiResponsePromise<void> => {
    return this.post("password/reset/confirm", withErrorHandling({ requestPayload: { email, token, password, confirmationCode }, refreshAT: false, expectJsonResponse: false }));
  };
  getDictionary = (locale: string): ApiResponsePromise<Dictionary> => {
    return this.get(`dictionary/hcp?locale=${locale}`, withErrorPage());
  };
  getHealthcareProviders = (namePrefix: string): TerminablePromise<HealthcareProviders> => {
    const url = `healthcareProviders?namePrefix=${namePrefix}`;
    const response = createTerminableRequest<ApiResponse<HealthcareProviders>>((signal) => this.get(url, withErrorHandling({ signal })));
    return {
      promise: response.promise.then((apiResponse) => apiResponse.data) as Promise<HealthcareProviders>,
      abort: response.abort,
    };
  };
}

export const userApi = new UserApi().useResponseHooks([refreshAccessTokenHook]);
