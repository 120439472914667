import { Box } from "@mui/material";
import { useEffect } from "react";
import { useAppState } from "../state/AppState";
import { t } from "i18next";
import TextHeader from "../components/TextHeader";
import DefaultText from "../components/DefaultText";
import NeedHelpIcon from "../components/icons/NeedHelpIcon";
import MyProfile from "../components/MyProfile";
import Link from "../components/Link";
import { Routes, Route, Navigate } from "react-router-dom";
import MyProfileEdit from "../components/MyProfileEdit";

export const SUPPORT_EMAIL = "support@ondosis.com";

const SettingsPage = () => {
  const { navigation } = useAppState();

  useEffect(() => {
    navigation.backgroundColor = "#F0F4FA";
    navigation.activePage = null;
    navigation.breadcrumbsElements = [];
  }, [navigation]);

  return (
    <Box sx={{ backgroundColor: "white", padding: "32px", marginTop: "-38px", minWidth: "650px" }}>
      <TextHeader sx={{ fontSize: "32px", lineHeight: "40px", marginBottom: "24px" }}> {t("settings")}</TextHeader>
      <hr style={{ width: "100%", border: "1px solid rgba(230,234,239,1)", marginBottom: "32px" }} />
      <Box sx={{ display: "flex", gap: "40px" }}>
        <Box sx={{ width: "240px", display: "flex", flexDirection: "column", gap: "24px" }}>
          <Box sx={{ backgroundColor: "rgba(232, 229, 255, 1)", padding: "12px 16px", borderRadius: "8px" }}>
            <DefaultText sx={{ color: "rgba(145, 84, 201, 1)" }}>{t("my-profile")}</DefaultText>
          </Box>
          <Box sx={{ backgroundColor: "rgba(247, 248, 251, 1)", padding: "10px 12px", borderRadius: "8px", display: "flex", flexDirection: "column", gap: "8px" }}>
            <Box sx={{ backgroundColor: "rgba(255, 255, 255, 1)", padding: "4px", borderRadius: "8px", display: "flex", gap: "4px" }}>
              <NeedHelpIcon />
              <DefaultText sx={{ fontSize: "12px" }}>{t("need-help")}</DefaultText>
            </Box>
            <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", fontSize: "12px" }}>{t("need-help-description")}</DefaultText>
            <Link to={`mailto:${SUPPORT_EMAIL}`}>
              <DefaultText sx={{ fontSize: "12px", color: "rgba(4, 11, 16, 1)", textDecoration: "underline" }}>{SUPPORT_EMAIL}</DefaultText>
            </Link>
          </Box>
        </Box>
        <Box sx={{ width: "calc(100% - 240px)", display: "flex", flexDirection: "column", marginTop: "4px" }}>
          <Routes>
            <Route path="" element={<MyProfile />} />
            <Route path="my-profile" element={<MyProfile />} />
            <Route path="my-profile/edit" element={<MyProfileEdit />} />
            <Route path="/*" element={<Navigate to={`/404`} />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsPage;
