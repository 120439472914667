import DefaultChip from "./DefaultChip";
import { UserRole } from "../model/user.model";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/material";

const UserRoleChip = ({ role, sx }: { role: UserRole; sx?: SxProps }) => {
  const { t } = useTranslation();
  return (
    <DefaultChip
      sx={{
        fontSize: "12px",
        height: "20px",
        padding: "2px 8px",
        borderRadius: "4px",
        "& .MuiChip-label": {
          padding: "0",
        },
        backgroundColor: role === UserRole.TEACHER ? "rgba(251, 239, 253, 1)" : "rgba(230, 251, 241, 1)",
        ...sx,
      }}
      text={t(role.toLowerCase())}
    />
  );
};

export default UserRoleChip;
