import { Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import IdentificationCardIcon from "./icons/IdentificationCardIcon";
import DefaultText from "./DefaultText";
import { CareTeamMember, UserRole } from "../model/user.model";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultModal from "./DefaultModal";
import { useMemo, useState } from "react";
import CareTeamMembers from "./CareTeamMembers";
import UsersIcon from "./icons/UsersIcon";
import DefaultPrimaryButton from "./DefaultPrimaryButton";

interface CareTeamProps {
  name: string;
  email: string;
  careTeamMembers: CareTeamMember[];
  patient: string;
}

const CareTeam = ({ name, email, careTeamMembers, patient }: CareTeamProps) => {
  const { t } = useTranslation();
  const [careTeamModalOpen, setCareTeamModalOpen] = useState(false);

  const sortedCareTeam = useMemo((): CareTeamMember[] => {
    const roleOrder: { [key in UserRole]: number } = {
      ADMIN_GUARDIAN: 0,
      GUARDIAN: 1,
      TEACHER: 2,
      HCP: 3,
    };
    const compareByRoleAndFirstName = (a: CareTeamMember, b: CareTeamMember) => {
      const roleDiff = roleOrder[a.role] - roleOrder[b.role];
      if (roleDiff !== 0) return roleDiff;
      return a.firstName.localeCompare(b.firstName);
    };
    return careTeamMembers.sort(compareByRoleAndFirstName);
  }, [careTeamMembers]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "15px", paddingLeft: "13px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "8px" }}>
          <DefaultText sx={{ fontWeight: "700" }}>{t("main-contact-person")}</DefaultText>
          <IdentificationCardIcon />
        </Box>
        <DefaultText sx={{ paddingBottom: "4px" }}>{name}</DefaultText>
        <DefaultText sx={{ paddingBottom: "20px" }}>{email}</DefaultText>
        <DefaultSecondaryButton
          startIcon={<UsersIcon />}
          sx={{ fontSize: "14px", height: "36px", padding: "8px 14px 8px 18px", width: "fit-content" }}
          onClick={() => setCareTeamModalOpen(true)}
        >
          {t("view-care-team")}
        </DefaultSecondaryButton>
      </Box>
      <DefaultModal
        isOpen={careTeamModalOpen}
        onClose={() => setCareTeamModalOpen(false)}
        titleElement={
          <Box sx={{ marginBottom: "-8px" }}>
            <DefaultText sx={{ fontSize: "21px", lineHeight: "24px" }}>{t("care-team")}</DefaultText>
            <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", marginTop: "6px" }}>
              <Trans i18nKey={"care-team-for"} values={{ patient: patient }} />
            </DefaultText>
          </Box>
        }
      >
        <CareTeamMembers
          careTeamMembers={sortedCareTeam}
          sx={{
            marginTop: "-8px",
            maxHeight: "285px",
            overflowY: "auto",
            paddingRight: sortedCareTeam.length > 5 ? "14px" : undefined,
            marginRight: sortedCareTeam.length > 5 ? "-26px" : undefined,
            scrollbarColor: "rgba(145, 84, 201, 1) rgba(231, 234, 238, 1)",
            scrollbarWidth: "thin",
          }}
        />
        <DefaultPrimaryButton onClick={() => setCareTeamModalOpen(false)} sx={{ width: "166px", marginTop: "32px", float: "right", height: "44px" }}>
          {t("close")}
        </DefaultPrimaryButton>
      </DefaultModal>
    </Box>
  );
};

export default CareTeam;
