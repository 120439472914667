import { Accordion, AccordionDetails, AccordionSummary, Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import CaretDownIconViolet from "./icons/CaretDownIconViolet";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DefaultAccordionProps {
  summary: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  sx?: SxProps;
}

const DefaultAccordion = ({ summary, children, defaultExpanded, sx }: DefaultAccordionProps) => {
  const [expand, setExpand] = useState(false || !!defaultExpanded);
  const { t } = useTranslation();
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  return (
    <Accordion
      expanded={expand}
      disableGutters
      elevation={0}
      square={true}
      sx={{
        ".MuiAccordionSummary-root:hover": { cursor: "auto !important" },
        ".MuiAccordionSummary-root": { alignItems: "flex-start", flexDirection: "column", padding: "0px" },
        ".MuiAccordionSummary-expandIconWrapper": { width: "100%" },
        ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": { transform: "none" },
        ".MuiAccordionSummary-expandIconWrapper.Mui-expanded .icon": { transform: "rotate(180deg)" },
        "&:before": { display: "none" },
        border: "1px solid rgba(213, 219, 227, 1)",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
        borderRadius: "8px",
        ...sx,
      }}
    >
      <AccordionSummary
        expandIcon={
          <Box sx={{ width: "100%" }}>
            <hr style={{ width: "100%", border: "0.5px solid rgba(231, 234, 238, 1)" }} />
            <Box onClick={toggleAcordion} sx={{ display: "flex", cursor: "pointer", padding: "8px 32px 16px 32px" }}>
              <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", color: "rgba(145, 84, 201, 1)", width: "40px", paddingRight: "6px" }}>
                {expand ? t("hide") : t("open")}
              </DefaultText>
              <CaretDownIconViolet className="icon" style={{ width: "24px", height: "24px" }} />
            </Box>
          </Box>
        }
        sx={{ ".MuiAccordionSummary-content": { margin: "24px 32px 8px 32px" } }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", userSelect: "text" }}>{summary}</Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "16px 32px 16px 32px " }}>{children} </AccordionDetails>
    </Accordion>
  );
};

export default DefaultAccordion;
