export interface PatientReport {
  medicationTrackingSummary: MedicationTrackingData[];
  observationTrackingSummary: ObservationTrackingData[];
  moodTrackingSummary: MoodTrackingData[];
}

interface DoseStatusSummary {
  total: number;
  taken: number;
  missed: number;
  skipped: number;
  noData: number;
}

export interface MedicationTrackingData {
  scheduleId?: string;
  medicationName: string;
  scheduleType: ScheduleType;
  strength: number;
  doseUnit: DoseUnit;
  dosageForm: DosageForm;
  summary: DoseStatusSummary;
  dosesPerDay?: number;
  dosageManagerInformation?: DosageManagerInfo;
  condition?: string;
}

export enum ScheduleType {
  DEVICE = "DEVICE",
  MANUAL = "MANUAL",
}

export enum DoseUnit {
  MICROGRAM = "mcg",
  MILLIGRAM = "mg",
  GRAM = "g",
}

export enum DosageForm {
  TABLET = "TABLET",
  CAPSULE = "CAPSULE",
  GRANULATE = "GRANULATE",
}

export interface DosageManagerInfo {
  lastSyncTimestamp: number;
  remainingDoses: number;
  remainingDosesPercentage: number;
  doseChangeHistory: DoseChangeHistoryEntry[];
}

export interface DoseChangeHistoryEntry {
  date: string; //YYYY-MM-DD hh:mm:ss
  previousDoseSize: number;
  newDoseSize: number;
  doseUnit: DoseUnit;
}

export interface ObservationTrackingData {
  observationType: ObservationType;
  data: ObservationReportEntry[];
}

export interface ObservationReportEntry {
  code: ObservationCode;
  averageRating: number;
  averageRatingChange?: number;
  ratingsCount: number;
  highestRating: number;
  averageObservationLastDoseDifference?: number; //in milliseconds
}

export enum ObservationType {
  SYMPTOMS = "SYMPTOMS",
  REBOUND_SYMPTOMS = "REBOUND_SYMPTOMS",
  SIDE_EFFECTS = "SIDE_EFFECTS",
}

export enum ObservationCode {
  SAD = "SAD",
  EXTREME_HYPERACTIVITY = "EXTREME_HYPERACTIVITY",
  GRUMPY_IRRITABLE = "GRUMPY_IRRITABLE",
  WITHDRAWN = "WITHDRAWN",
  PSYCHOSIS_DEPRESSION = "PSYCHOSIS_DEPRESSION",
  INSOMNIA = "INSOMNIA",
  DRY_MOUTH = "DRY_MOUTH",
  HEADACHE = "HEADACHE",
  RAPID_HEART_CARDIAC_COMPLICATIONS = "RAPID_HEART_CARDIAC_COMPLICATIONS",
  NAUSEA_STOMACH_PAIN = "NAUSEA_STOMACH_PAIN",
  DECREASED_APPETITE = "DECREASED_APPETITE",
  EXACERBATION_OF_TICKS = "EXACERBATION_OF_TICKS",
  TASKS_COMPLETING_DIFFICULTIES = "TASKS_COMPLETING_DIFFICULTIES",
  POOR_ORGANISATION_SKILLS = "POOR_ORGANISATION_SKILLS",
  TASKS_ENGAGEMENT_RELUCTANCE = "TASKS_ENGAGEMENT_RELUCTANCE",
  DIFFICULTY_SUSTAINING_ATTENTION = "DIFFICULTY_SUSTAINING_ATTENTION",
  FIDGETING_OFTEN = "FIDGETING_OFTEN",
  FORGETFULNESS = "FORGETFULNESS",
  OFTEN_LOSING_THINGS = "OFTEN_LOSING_THINGS",
  OFTEN_RUNNING_AND_CLIMBING = "OFTEN_RUNNING_AND_CLIMBING",
  IMPATIENCE = "IMPATIENCE",
  OFTEN_INTERRUPTS_PEOPLE = "OFTEN_INTERRUPTS_PEOPLE",
  EXCESSIVE_TALKING = "EXCESSIVE_TALKING",
  POOR_ATTENTION_TO_DETAIL = "POOR_ATTENTION_TO_DETAIL",
  UNABLE_TO_SIT_STILL = "UNABLE_TO_SIT_STILL",
  REFUSES_TO_LISTEN = "REFUSES_TO_LISTEN",
}

export interface MoodTrackingData {
  rating: number;
  count: number;
}

export interface ObservationTrackingHistoryEntry {
  code: ObservationCode;
  rating?: number;
  loggedBy?: string;
}

export enum DeviceMedicationLoggedBy {
  AUTOMATICALLY_CAPTURED = "automatically.captured",
}

export interface MedicationLoggedByEntry {
  taken: string[];
  missed: string[];
  skipped: string[];
}

export interface MedicationTrackingHistoryEntry {
  doseSummary: DoseStatusSummary;
  doseBaseLine?: number;
  doseTaken: number;
  loggedBy: MedicationLoggedByEntry;
}

export interface PatientTrackingHistoryReportEntry {
  date: string;
  medication?: MedicationTrackingHistoryEntry;
  observations: ObservationTrackingHistoryEntry[];
}

export interface PatientTrackingHistoryReport {
  doseUnit?: DoseUnit;
  data: PatientTrackingHistoryReportEntry[];
}

export interface ChartData {
  date: string;
  observationRating?: number;
  observationLoggedBy?: string;
  medication?: MedicationTrackingHistoryEntry;
}

export interface TrackingHistoryChartData {
  observationNameCode?: ObservationCode;
  doseUnit?: string;
  chartData: ChartData[];
}

export interface MedicationScheduleDto {
  uuid?: string;
  scheduleType: ScheduleType;
  medicationDetails: MedicationDetails;
  dosesPerDay?: number;
}

export interface MedicationDetails {
  medicationName: string;
  strength: number;
  doseUnit: DoseUnit;
  condition?: string;
  dosageForm: DosageForm;
  expirationDate?: string;
}
