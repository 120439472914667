import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import SendInviteIcon from "./icons/SendInviteIcon";
import { useState } from "react";
import DefaultModal from "./DefaultModal";
import RequestVanderbiltForm from "./RequestVanderbiltForm";
import DefaultText from "./DefaultText";
import Link from "./Link";

export const ONDOSIS_WEBSITE = "https://www.ondosis.com/";

const RatingScalesTab = () => {
  const { t } = useTranslation();
  const [requestFormModal, setRequestFormModal] = useState(false);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <DefaultText sx={{ fontWeight: 700 }}>{t("adhd-vanderbilt-assessment")}</DefaultText>
        <Box>
          <DefaultText>{t("about-the-form")}</DefaultText>
          <DefaultText>{t("this-form-is-licensed-by")}</DefaultText>
        </Box>
        <Link to={ONDOSIS_WEBSITE} target="_blank" sx={{ color: "rgba(145, 84, 201, 1)", fontWeight: 700 }}>
          {t("click-here-to-read-more")}
        </Link>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <DefaultPrimaryButton onClick={() => setRequestFormModal(true)} sx={{ width: "256px" }} startIcon={<SendInviteIcon />}>
          {t("request-form")}
        </DefaultPrimaryButton>
        <DefaultModal modalTitle={t("request-form")} isOpen={requestFormModal} onClose={() => setRequestFormModal(false)}>
          <RequestVanderbiltForm handleClose={() => setRequestFormModal(false)} />
        </DefaultModal>
      </Box>
    </Box>
  );
};

export default RatingScalesTab;
