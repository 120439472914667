import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import { useTranslation } from "react-i18next";

interface ChartLegendProps {
  rating: boolean;
  trend: boolean;
  doseTaken: boolean;
  baseline: boolean;
}

const ChartLegend = ({ rating, trend, doseTaken, baseline }: ChartLegendProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        marginBottom: "16px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        padding: "22px 32px 16px 32px",
        borderRadius: "7px",
        border: "1px solid rgba(213, 219, 227, 1)",
        borderTop: "1px solid rgba(213, 219, 227, 1)",
      }}
    >
      <DefaultText sx={{ fontSize: "14px", fontWeight: 700, paddingBottom: "6px" }}>{t("chart-legend")}</DefaultText>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "24px",
          rowGap: "6px",
          paddingBottom: "6px",
        }}
      >
        <DefaultText sx={{ fontSize: "12px" }}>{t("dosage-statuses")}</DefaultText>
        {rating && (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "16px", height: "8px", borderRadius: "4px", backgroundColor: "rgba(117, 64, 180, 1)", margin: "4px 8px 0 0" }} />
            <DefaultText sx={{ fontSize: "12px" }}>{t("symptom-rating")}</DefaultText>
          </Box>
        )}
        {trend && (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: "11.5px",
                height: "0px",
                border: "3px dotted rgba(254, 183, 1, 1)",
                borderTop: "none",
                margin: "7px 8px 0 0",
              }}
            />
            <DefaultText sx={{ fontSize: "12px" }}>{t("symptom-trend-for-period")}</DefaultText>
          </Box>
        )}
        {doseTaken && (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "16px", height: "8px", borderRadius: "4px", backgroundColor: "rgba(72, 197, 29, 1)", margin: "4px 8px 0 0" }} />
            <DefaultText sx={{ fontSize: "12px" }}>{t("dose-taken")}</DefaultText>
          </Box>
        )}
        {baseline && (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "17px", height: "2px", borderRadius: "4px", backgroundColor: "rgba(221, 49, 49, 1)", margin: "7px 8px 0 0" }} />
            <DefaultText sx={{ fontSize: "12px" }}>{t("daily-dose-baseline")}</DefaultText>
          </Box>
        )}
        {rating && (
          <Box sx={{ display: "flex", gap: "16px" }}>
            <DefaultText sx={{ fontSize: "12px" }}>{t("symptoms-scale")}</DefaultText>
            <DefaultText sx={{ fontSize: "12px" }}>
              0 - {t("no-symptoms-or-data")}, 5 - {t("severe-symptoms")}
            </DefaultText>
          </Box>
        )}
      </Box>
      {rating && (
        <DefaultText sx={{ fontSize: "12px" }}>
          {t("symptom-chart")} - {t("manual-information-from-guardian")}
        </DefaultText>
      )}
    </Box>
  );
};

export default ChartLegend;
