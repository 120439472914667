import { useTranslation } from "react-i18next";
import DefaultDosesCard from "./DefaultDosesCard";
import { Box } from "@mui/material";
import DosesSourceChip from "./DosesSourceChip";

interface MissedDosesCardProps {
  value: number;
  limit: number;
  doseSourceText?: boolean;
}
const MissedDosesCard = ({ value, limit, doseSourceText }: MissedDosesCardProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <DefaultDosesCard
        text={t("missed")}
        value={value}
        limit={limit}
        sx={{
          borderColor: "rgba(252, 137, 137, 1)",
          background: "rgba(255, 242, 242, 1)",
          color: "rgba(221, 49, 49, 1)",
        }}
      />
      {doseSourceText && <DosesSourceChip source={t("manual-input")} />}
    </Box>
  );
};

export default MissedDosesCard;
