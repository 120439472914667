import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppState } from "../state/AppState";
import { Page } from "../state/NavigationState";
import { observer } from "mobx-react-lite";
import { PatientListEntry } from "../model/patient.model";
import PatientsList, { RECORDS_PER_PAGE } from "../components/PatientsList";
import { patientApi } from "../api/PatientApi";
import { withLoader } from "../hoc/withLoader";

const LoadingTrackingResults = withLoader(PatientsList);

const MyPatientsPage = () => {
  const { navigation } = useAppState();
  const [patients, setPatients] = useState<PatientListEntry[] | null>(null);

  useEffect(() => {
    navigation.backgroundColor = "#F0F4FA";
    navigation.activePage = Page.MY_PATIENTS;
    navigation.breadcrumbsElements = [
      {
        labelId: "my-patients",
        path: "/patients",
      },
    ];
  }, [navigation]);

  useEffect(() => {
    const getData = async () => {
      setPatients((await patientApi.getPatients()).data);
    };
    getData();
  }, []);

  return (
    <Box>
      <LoadingTrackingResults patients={patients as PatientListEntry[]} isLoading={patients === null}>
        <Box sx={{ height: 40, width: 170, paddingTop: "30px", paddingBottom: "55px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit" }} />
        </Box>
        <Box sx={{ height: 16, display: "flex", direction: "row", padding: "10px 16px", minWidth: "800px" }}>
          <Box sx={{ height: "inherit", flex: 2 }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", width: "50px" }} />
          </Box>
          <Box sx={{ height: "inherit", flex: 1 }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", width: "50px" }} />
          </Box>
          <Box sx={{ height: "inherit", flex: 1, minWidth: "115px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", width: "50px" }} />
          </Box>
          <Box sx={{ height: "inherit", flex: 1, minWidth: "170px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", width: "50px" }} />
          </Box>
          <Box sx={{ height: "inherit", flex: 3 }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", width: "50px" }} />
          </Box>
        </Box>
        {Array(RECORDS_PER_PAGE)
          .fill(1)
          .map((el, i) => (
            <Box key={i} sx={{ height: 44, paddingTop: "2px", paddingBottom: "6px" }}>
              <Skeleton variant="rounded" sx={{ height: "inherit" }} />
            </Box>
          ))}
      </LoadingTrackingResults>
    </Box>
  );
};

export default observer(MyPatientsPage);
