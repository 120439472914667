import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import LinkBreakIcon from "./icons/LinkBreakIcon";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { useNavigate } from "react-router-dom";

const ExpiredResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "420px", paddingTop: "80px" }}>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <LinkBreakIcon />
        <TextHeader sx={{ paddingBottom: "58px" }}>{t("expired-invitation")}</TextHeader>
      </Box>
      <TextHeader sx={{ fontSize: "21px", lineHeight: "24px" }}>{t("dear-healthcare-professional")}</TextHeader>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", paddingTop: "10px" }}>{t("expired-reset-password")}</DefaultText>
      <DefaultPrimaryButton sx={{ width: "288px", marginTop: "40px" }} onClick={() => navigate("/login")}>
        {t("take-me-back")}
      </DefaultPrimaryButton>
    </Box>
  );
};

export default ExpiredResetPassword;
