import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import { Box, SxProps } from "@mui/material";
import { MoodTrackingData } from "../model/report.model";
import EmptyTrackingResult from "./EmptyTrackingResult";
import LoggedItem from "./LoggedItem";

interface PatientMoodProps {
  data: MoodTrackingData[];
  sx?: SxProps;
}

const PatientMood = ({ data, sx }: PatientMoodProps) => {
  const { t } = useTranslation();
  const emptyTrackingResult = data.reduce((acc, curr) => acc + curr.count, 0) === 0;

  return (
    <>
      {emptyTrackingResult ? (
        <EmptyTrackingResult header={t("patient-mood")} sx={{ height: "164px", ...sx }} />
      ) : (
        <Box sx={{ padding: "24px 35px", backgroundColor: "rgba(247, 248, 251, 1)", border: "1px solid rgba(213, 219, 227, 1)", borderRadius: "8px", ...sx }}>
          <TextHeader sx={{ fontSize: "24px", paddingBottom: "24px" }}>{t("patient-mood")}</TextHeader>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "9px" }}>
            {data.map((moodTrackingData) => (
              <LoggedItem key={moodTrackingData.rating} title={t(`mood-rating.${moodTrackingData.rating}`)} count={moodTrackingData.count} sx={{ flex: 1 }} />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PatientMood;
