import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface InfoRedIconProps {
  sx?: SxProps;
}

const InfoRedIcon = ({ sx }: InfoRedIconProps) => <DefaultIcon src={"infoRed.svg"} sx={{ width: "16px", height: "16px", ...sx }} />;

export default InfoRedIcon;
