import { Box, CircularProgress } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import { useAppState } from "../state/AppState";
import { throttle } from "lodash";
import { useState, useRef, useMemo, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TerminablePromise } from "../api/controllers/TerminableRequest";
import { HealthcareProvider, HealthcareProviders } from "../model/healthcareProvider.model";
import { validate, latinCharactersPattern, patterns } from "../util/validation";
import DefaultSelect, { Data } from "./DefaultSelect";
import DefaultInput from "./DefaultInput";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import HealthcareProviderAutocomplete from "./HealthcareProviderAutocomplete";
import { HcpUpdateDto } from "../model/user.model";
import { userApi } from "../api/UserApi";
import DefaultModal from "./DefaultModal";
import { useClosePageModal } from "../util/closePageModal";
import LeaveConfirmationModal from "./LeaveConfirmationModal";

const MyProfileEdit = () => {
  const { t } = useTranslation();
  const { user, notification } = useAppState();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(user.loggedUser?.firstName as string);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState(user.loggedUser?.lastName as string);
  const [lastNameError, setLastNameError] = useState("");
  const [hcpSpecialty, setHcpSpecialty] = useState(user.loggedUser?.specialty as string);
  const [hcpSpecialtyError, setHcpSpecialtyError] = useState("");
  const otherHealthcareProvider = { id: "other", name: t("other"), location: "", npi: "", ehrLaunchEnabled: true };
  const [healthcareProvider, setHealthcareProvider] = useState<HealthcareProvider | null>(user.loggedUser?.healthcareProvider || otherHealthcareProvider);
  const [healthcareProviderInput, setHealthcareProviderInput] = useState("");
  const [healthcareProviderInputError, setHealthcareProviderInputError] = useState("");
  const [newPracticeName, setNewPracticeName] = useState(user.loggedUser?.practiceName || "");
  const [newPracticeNameError, setNewPracticeNameError] = useState("");
  const [newPracticeLocation, setNewPracticeLocation] = useState(user.loggedUser?.practiceLocation || "");
  const [newPracticeLocationError, setNewPracticeLocationError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [healthcareProviders, setHealthcareProviders] = useState<HealthcareProvider[]>([otherHealthcareProvider]);
  const [healthcareProviderLoading, setHealthcareProviderLoading] = useState(false);
  const [healthcareProvidersFetched, setHealthcareProvidersFetched] = useState(false);
  const [emptyHealthcareProvidersList, setEmptyHealthcareProvidersList] = useState(false);
  const [leaveConfirmationModal, setLeaveConfirmationModal] = useState(false);
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("id");
  const [showChangedProviderModal, setShowChangedProviderModal] = useState(false);
  const terminableRequestRef = useRef<TerminablePromise<HealthcareProviders> | null>(null);
  const correctUserProvider = emptyHealthcareProvidersList
    ? !(!newPracticeName || !newPracticeLocation)
    : !(!healthcareProviderInput || !healthcareProvider?.id || (healthcareProvider?.id === "other" && (!newPracticeName || !newPracticeLocation)));
  const disabledSaveBtn =
    firstNameError.length > 0 ||
    lastNameError.length > 0 ||
    hcpSpecialtyError.length > 0 ||
    newPracticeNameError.length > 0 ||
    newPracticeLocationError.length > 0 ||
    healthcareProviderInputError.length > 0 ||
    !firstName ||
    !lastName ||
    !hcpSpecialty ||
    !correctUserProvider ||
    healthcareProviderLoading ||
    saveLoading;

  useClosePageModal(firstName);
  useClosePageModal(lastName);
  useClosePageModal(hcpSpecialty);
  useClosePageModal(newPracticeName);
  useClosePageModal(newPracticeLocation);
  useClosePageModal(healthcareProviderInput);

  const hcpSpecialties: Data[] = [
    {
      label: t("general-physician"),
      value: "59058001",
    },
    {
      label: t("nurse-practitioner"),
      value: "224571005",
    },
    {
      label: t("pediatrician"),
      value: "82296001",
    },
    {
      label: t("psychiatrist"),
      value: "80584001",
    },
    {
      label: t("psychologist"),
      value: "59944000",
    },
  ];

  const setUserDataWithOptionalHrnMigration = async (hrnMigration: boolean) => {
    setShowChangedProviderModal(false);
    setSaveLoading(true);
    const userData: HcpUpdateDto = {
      firstName,
      lastName,
      specialty: hcpSpecialty,
      providerId: healthcareProvider?.id,
      hrnMigration,
    };
    await updateUserData(userData);
  };

  const setUserDataWithoutHrnMigration = async () => {
    setSaveLoading(true);
    const userData: HcpUpdateDto = {
      firstName,
      lastName,
      specialty: hcpSpecialty,
    };
    if (healthcareProvider?.id !== "other" && !emptyHealthcareProvidersList) {
      userData.providerId = healthcareProvider?.id;
    } else {
      userData.practiceName = newPracticeName;
      userData.practiceLocation = newPracticeLocation;
    }
    await updateUserData(userData);
  };

  const updateUserData = async (userData: HcpUpdateDto) => {
    const response = await userApi.updateUserData(userData);
    if (response.ok) {
      const meResponse = await userApi.me({ showLoginOnRefreshATFail: false });
      if (meResponse.ok) {
        user.loggedUser = meResponse.data;
      }
      setSaveLoading(false);
      navigate(`/settings/my-profile`);
      notification.addNotification({
        message: t("profile-saved"),
      });
    }
  };

  const handleSaveButton = () => {
    setLeaveConfirmationModal(false);
    if (healthcareProvider?.id !== "other" && !emptyHealthcareProvidersList && user.loggedUser?.practiceName && user.loggedUser?.practiceLocation) {
      setShowChangedProviderModal(true);
    } else {
      setUserDataWithoutHrnMigration();
    }
  };

  const handleInputChange = (value: string, setter: (value: string) => void, errorSetter: (value: string) => void) => {
    if (!disabledSaveBtn) {
      setLeaveConfirmationModal(true);
    }
    setter(value);
    const error = validate(value, latinCharactersPattern);
    if (error) {
      errorSetter(t(error));
    } else {
      errorSetter("");
    }
  };

  const handleNewPracticeNameChange = (value: string) => {
    if (!disabledSaveBtn) {
      setLeaveConfirmationModal(true);
    }
    setNewPracticeName(value);
    const error = validate(value, [patterns.requiredField, patterns.oneHundredCharacters]);
    if (error) {
      setNewPracticeNameError(t(error));
    } else {
      setNewPracticeNameError("");
    }
  };

  const handleNewPracticeLocationChange = (value: string) => {
    if (!disabledSaveBtn) {
      setLeaveConfirmationModal(true);
    }
    setNewPracticeLocation(value);
    const error = validate(value, [patterns.requiredField, patterns.twoHundredFiftyFiveCharacters]);
    if (error) {
      setNewPracticeLocationError(t(error));
    } else {
      setNewPracticeLocationError("");
    }
  };

  const fetchHealthcareProviders = useMemo(() => {
    return throttle(async () => {
      if (terminableRequestRef.current) {
        terminableRequestRef.current.abort();
      }
      terminableRequestRef.current = userApi.getHealthcareProviders(healthcareProviderInput);
      setHealthcareProviderLoading(true);
      try {
        const fetchedHealthcareProviders = await terminableRequestRef.current?.promise;
        if (fetchedHealthcareProviders) {
          setHealthcareProvidersFetched(true);
          if (fetchedHealthcareProviders.items?.length === 0 && !healthcareProviderInput) {
            setEmptyHealthcareProvidersList(true);
          }
          const sortedHealthcareProviders = fetchedHealthcareProviders.items?.sort((a, b) => a.name.localeCompare(b.name));
          setHealthcareProviderLoading(false);
          setHealthcareProviders([otherHealthcareProvider, ...sortedHealthcareProviders]);
        }
      } catch (e) {
        console.error(e);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcareProviderInput]);

  useEffect(() => {
    if (healthcareProviderInput !== t("other") && !emptyHealthcareProvidersList) {
      setNewPracticeNameError("");
      setNewPracticeLocationError("");
      fetchHealthcareProviders();
    }
  }, [emptyHealthcareProvidersList, fetchHealthcareProviders, healthcareProviderInput, invitationId, t, user.registrationToken]);

  return (
    <>
      <TextHeader sx={{ fontSize: "24px", marginBottom: "32px" }}> {t("edit-profile")}</TextHeader>
      <>
        {healthcareProvidersFetched ? (
          <Box sx={{ display: "flex", flexDirection: "column", width: "390px", gap: "20px" }}>
            <DefaultInput
              label={t("first-name")}
              error={firstNameError.length > 0}
              errorMsg={firstNameError}
              value={firstName}
              onChange={(value) => handleInputChange(value, setFirstName, setFirstNameError)}
              sx={{ width: "100%" }}
            />
            <DefaultInput
              label={t("last-name")}
              error={lastNameError.length > 0}
              errorMsg={lastNameError}
              value={lastName}
              onChange={(value) => handleInputChange(value, setLastName, setLastNameError)}
              sx={{ width: "100%" }}
            />
            <DefaultInput label={t("email")} value={user.loggedUser?.email as string} sx={{ width: "100%" }} disabled />
            <DefaultSelect
              label={t("specialty")}
              error={hcpSpecialtyError.length > 0}
              errorMsg={hcpSpecialtyError}
              value={hcpSpecialty}
              options={hcpSpecialties}
              selectHandler={(value) => handleInputChange(value, setHcpSpecialty, setHcpSpecialtyError)}
              sx={{ width: "100%" }}
            />
            {!emptyHealthcareProvidersList && (
              <HealthcareProviderAutocomplete
                options={healthcareProviders}
                value={healthcareProvider as HealthcareProvider}
                onChange={(newValue: HealthcareProvider | null) => {
                  setHealthcareProvider(newValue);
                }}
                onInputChange={(inputValue) => {
                  handleInputChange(inputValue, setHealthcareProviderInput, setHealthcareProviderInputError);
                }}
                loading={healthcareProviderLoading}
                error={healthcareProviderInputError.length > 0}
                errorMsg={healthcareProviderInputError}
                sx={{ width: "100%" }}
              />
            )}
            {((healthcareProvider?.id === "other" && healthcareProviderInput === t("other")) || emptyHealthcareProvidersList) && (
              <>
                <DefaultInput
                  label={t("healthcare-provider-practice-name")}
                  error={newPracticeNameError.length > 0}
                  errorMsg={newPracticeNameError}
                  value={newPracticeName}
                  onChange={(value) => handleNewPracticeNameChange(value)}
                  sx={{ width: "100%" }}
                />
                <DefaultInput
                  label={t("healthcare-provider-practice-location")}
                  error={newPracticeLocationError.length > 0}
                  errorMsg={newPracticeLocationError}
                  value={newPracticeLocation}
                  onChange={(value) => handleNewPracticeLocationChange(value)}
                  hint={<Trans i18nKey="custom-healthcare-provider-emr-hint" />}
                  sx={{ width: "100%" }}
                />
              </>
            )}
            <Box sx={{ paddingTop: "12px", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
              <DefaultSecondaryButton onClick={() => navigate("/settings/my-profile")} sx={{ width: "187px", height: "40px" }}>
                {t("cancel")}
              </DefaultSecondaryButton>
              <DefaultPrimaryButton onClick={handleSaveButton} isLoading={saveLoading} loadingText={t("saving")} disabled={disabledSaveBtn} sx={{ width: "187px", height: "40px" }}>
                {t("save-data")}
              </DefaultPrimaryButton>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "640px", width: "calc(100% - 280px)" }}>
            <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)" }} size="56px" />
          </Box>
        )}
      </>
      <DefaultModal modalTitle={t("changed-provider-title")} isOpen={showChangedProviderModal} onClose={() => setShowChangedProviderModal(false)}>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>
          <Trans i18nKey="changed-provider-description" />
        </DefaultText>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "40px" }}>
          <DefaultSecondaryButton onClick={() => setUserDataWithOptionalHrnMigration(false)} sx={{ width: "160px", height: "44px" }}>
            {t("skip")}
          </DefaultSecondaryButton>
          <DefaultPrimaryButton onClick={() => setUserDataWithOptionalHrnMigration(true)} sx={{ width: "167px", height: "44px", marginLeft: "16px" }}>
            {t("continue")}
          </DefaultPrimaryButton>
        </Box>
      </DefaultModal>
      <LeaveConfirmationModal active={leaveConfirmationModal} />
    </>
  );
};

export default MyProfileEdit;
