import { Page } from "../state/NavigationState";
import { useEffect, useState } from "react";
import { useAppState } from "../state/AppState";
import { patientApi } from "../api/PatientApi";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Patient } from "../model/patient.model";
import { withLoader } from "../hoc/withLoader";
import { Box, Skeleton } from "@mui/material";
import PatientProfileTabs from "../components/PatientProfileTabs";
import PatientProfile from "../components/PatientProfile";
import PatientReports from "../components/PatientReports";
import TrackingHistoryChartsContainer from "../components/TrackingHistoryChartsContainer";
import { ReportPeriod } from "../state/PatientProfilePageState";
import RatingScalesRequestsContainer from "../components/RatingScalesRequestsContainer";

const LoadingPatientProfile = withLoader(PatientProfile);

const PatientProfilePage = () => {
  const { navigation, patientProfilePageState } = useAppState();
  const { id, reportPeriod } = useParams();
  const [patient, setPatient] = useState<Patient | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    navigation.backgroundColor = "white";
    navigation.activePage = Page.MY_PATIENTS;
    navigation.breadcrumbsElements = [
      {
        labelId: "my-patients",
        path: "/patients",
      },
    ];
  }, [navigation]);

  useEffect(() => {
    const getData = async () => {
      setPatient((await patientApi.getPatient(id as string)).data);
    };
    getData();
  }, [id]);

  useEffect(() => {
    if (patient) {
      navigation.breadcrumbsElements = [
        {
          labelId: "my-patients",
          path: "/patients",
        },
        {
          labelId: `${patient.firstName} ${patient.lastName}`,
        },
      ];
    }
  }, [navigation, patient]);

  useEffect(() => {
    if (Object.values(ReportPeriod).includes(reportPeriod as ReportPeriod)) {
      patientProfilePageState.numberOfDays = reportPeriod as ReportPeriod;
    } else {
      navigate(`/patients/${id}/30/`);
    }
  }, [reportPeriod, patientProfilePageState, id, navigate]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ backgroundColor: "rgba(240, 244, 250, 1)", width: "100vw", height: "380px", position: "absolute", right: "calc(-5vw + 1px)", marginTop: "-25px" }} />
      <Box sx={{ position: "relative" }}>
        <LoadingPatientProfile patient={patient as Patient} setPatient={setPatient} isLoading={patient === null}>
          <Box sx={{ height: 225, flex: 1, display: "flex", gap: "40px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", flex: 0.8 }} />
            <Skeleton variant="rounded" sx={{ height: "inherit", flex: 0.2 }} />
          </Box>
        </LoadingPatientProfile>
        <PatientProfileTabs sx={{ paddingTop: "24px", marginBottom: "32px" }} />
        <Routes>
          <Route path="" element={<PatientReports />} />
          <Route path="/trackingHistory" element={<TrackingHistoryChartsContainer />} />
          <Route path="/ratingScales" element={<RatingScalesRequestsContainer />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default PatientProfilePage;
