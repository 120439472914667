import { Box, Chip } from "@mui/material";
import DefaultText from "./DefaultText";
import CareTeam from "./CareTeam";
import IconAndText from "./IconAndText";
import TextAndDescription from "./TextAndDescription";
import TextHeader from "./TextHeader";
import TextLabel from "./TextLabel";
import { Patient } from "../model/patient.model";
import { reformatDate, yearsPassedSinceDate } from "../util/date";
import { inchesToFeetAndInches } from "../util/unit";
import { CareTeamMember, UserRole } from "../model/user.model";
import { useTranslation } from "react-i18next";
import TextList from "./TextList";
import EditIcon from "./icons/EditIcon";
import DefaultModal from "./DefaultModal";
import { useState } from "react";
import EditHRN from "./EditHRN";
import DefaultTooltip from "./DefaultTooltip";
import { useAppState } from "../state/AppState";
import InfoBlackIcon from "./icons/InfoBlackIcon";

const PatientProfile = ({ patient, setPatient }: { patient: Patient; setPatient: (patient: Patient) => void }) => {
  const { t } = useTranslation();
  const adminGuardian: CareTeamMember | undefined = patient.careTeam.find((careTeamMember) => careTeamMember.role === UserRole.ADMIN_GUARDIAN);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAppState();
  const { healthcareProvider, practiceLocation, practiceName } = user.loggedUser || {};
  const handleClick = () => {
    setIsModalOpen(true);
  };
  if (!adminGuardian) {
    throw new Error("Admin guardian not found");
  }
  return (
    <Box sx={{ borderRadius: "8px", border: "1px solid #D5DBE3", display: "flex", flexWrap: "wrap", backgroundColor: "rgba(255, 255, 255, 1)" }}>
      <Box sx={{ borderRadius: "8px 0px 0px 8px", flex: 0.8, padding: "32px" }}>
        <Box sx={{ display: "flex" }}>
          <TextHeader>
            {patient.firstName} {patient.lastName}
          </TextHeader>
          <DefaultTooltip
            title={
              <Box sx={{ padding: "10px" }}>
                <DefaultText sx={{ color: "#4E5D69" }}>{t("hrn.press-to-edit")}</DefaultText>
              </Box>
            }
            placement={"right-start"}
            sx={{ maxWidth: "fit-content", boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)" }}
          >
            <Chip
              icon={!patient.healthRecordNumber ? <EditIcon sx={{ paddingLeft: "12px", marginRight: "-8px" }} /> : undefined}
              label={<DefaultText>{`${t("hrn")} ${patient.healthRecordNumber || t("not-assigned")}`}</DefaultText>}
              variant="outlined"
              sx={{ marginLeft: "15px", borderColor: "#040B10", marginTop: "1px" }}
              onClick={handleClick}
            />
          </DefaultTooltip>
        </Box>
        <DefaultTooltip
          title={
            <Box sx={{ padding: "10px" }}>
              <DefaultText sx={{ fontWeight: "700", marginBottom: "4px" }}>{healthcareProvider?.name || practiceName}</DefaultText>
              <DefaultText sx={{ fontSize: "12px" }}>{healthcareProvider?.location || practiceLocation}</DefaultText>
              {healthcareProvider?.npi && (
                <DefaultText sx={{ fontSize: "12px", color: "#4E5D69" }}>
                  {t("npi")} {healthcareProvider.npi}
                </DefaultText>
              )}
            </Box>
          }
          placement={"right-start"}
          sx={{ maxWidth: "fit-content", boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)" }}
        >
          <IconAndText icon={<InfoBlackIcon />} text={t("healthcare-provider-details")} sx={{ marginTop: "8px", width: "fit-content" }} textSx={{ color: "#040B10" }} />
        </DefaultTooltip>
        <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)", marginTop: "16px" }}>{t("patient-profile-information-source")}</DefaultText>
        <Box sx={{ marginTop: "8px", display: "flex", flexWrap: "wrap" }} columnGap={2} rowGap={4}>
          <Box sx={{ flex: 2 }}>
            <DefaultText sx={{ paddingBottom: "16px" }}>{t("basic-info")}</DefaultText>
            <Box sx={{ display: "flex", flexWrap: "wrap" }} columnGap={2} rowGap={4}>
              <Box sx={{ flex: 1, display: "grid", gridTemplateColumns: "repeat(2, max-content)" }} columnGap={4} rowGap={0.5}>
                <TextLabel>{t("first-name")}</TextLabel>
                <DefaultText>{patient.firstName}</DefaultText>
                <TextLabel>{t("last-name")}</TextLabel>
                <DefaultText>{patient.lastName}</DefaultText>
                <TextLabel>{t("gender")}</TextLabel>
                <DefaultText>{t(`gender.${patient.gender}`)}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, display: "grid", gridTemplateColumns: "repeat(2, max-content)" }} columnGap={4} rowGap={0.5}>
                <TextLabel>{t("date-of-birth")} </TextLabel>
                <TextAndDescription text={reformatDate(patient.dateOfBirth, "YYYY-MM-DD", "MM/DD/YYYY")} description={`${yearsPassedSinceDate(patient.dateOfBirth)} ${t("yo")}`} />
                <TextLabel>{t("weight")}</TextLabel>
                <TextAndDescription
                  text={`${patient.currentWeight.value} ${t(`weight.${patient.currentWeight.unit}`)}`}
                  description={`${t("logged")} ${reformatDate(patient.currentWeight.date, "YYYY-MM-DD", "MM/DD/YYYY")}`}
                />
                <TextLabel>{t("height")}</TextLabel>
                <TextAndDescription
                  text={inchesToFeetAndInches(patient.currentHeight.value)}
                  description={`${t("logged")} ${reformatDate(patient.currentHeight.date, "YYYY-MM-DD", "MM/DD/YYYY")}`}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <DefaultText sx={{ paddingBottom: "16px" }}>{t("health-information")}</DefaultText>
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, max-content)" }} columnGap={4} rowGap={0.5}>
              <TextLabel>{t("adhd-diagnosis")}</TextLabel>
              <DefaultText>{patient.mainConditionCode ? t("yes") : t("no")} </DefaultText>
              <TextLabel>{t("adhd-type")}</TextLabel>
              <DefaultText>{patient.mainConditionCode ? t(`condition.${patient.mainConditionCode}`, { adhdPrefix: "" }) : "-"}</DefaultText>
              <TextLabel>{t("conditions")}</TextLabel>
              {patient.otherConditionCodes ? (
                <TextList limit={3} items={patient.otherConditionCodes.map((code) => t(`condition.${code}`, { adhdPrefix: `${t("adhd")} ` })).sort()} />
              ) : (
                <DefaultText>-</DefaultText>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 0.2, background: "#F9F9F9", borderRadius: "0px 8px 8px 0px", padding: "32px" }}>
        <CareTeam
          name={`${adminGuardian.firstName} ${adminGuardian.lastName}`}
          email={adminGuardian.email}
          careTeamMembers={patient.careTeam}
          patient={`${patient.firstName} ${patient.lastName}`}
        />
      </Box>
      <DefaultModal modalTitle={patient.healthRecordNumber ? t("hrn.edit") : t("hrn.assign")} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <EditHRN
          healthRecordNumber={patient.healthRecordNumber}
          onSuccess={(healthRecordNumber) => setPatient({ ...patient, healthRecordNumber })}
          handleClose={() => setIsModalOpen(false)}
        />
      </DefaultModal>
    </Box>
  );
};

export default PatientProfile;
