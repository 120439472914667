import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import { PatientListEntry } from "../model/patient.model";
import { useTranslation } from "react-i18next";
import { reformatDate } from "../util/date";
import CaretRightIcon from "./icons/CaretRightIcon";
import TextHeader from "./TextHeader";
import EmptyPatientsList from "./EmptyPatientsList";
import { useEffect, useState } from "react";
import DefaultPagination from "./DefaultPagination";
import DefaultTableRow from "./PatientTableRow";
import InfoRedIcon from "./icons/InfoRedIcon";
import DefaultTooltip from "./DefaultTooltip";

export const RECORDS_PER_PAGE = 12;

interface PatientsListProps {
  patients: PatientListEntry[];
}

const PatientsList = ({ patients }: PatientsListProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [patientsToDisplay, setPatientsToDisplay] = useState<PatientListEntry[]>([]);

  useEffect(() => {
    const start = (page - 1) * RECORDS_PER_PAGE;
    const end = start + RECORDS_PER_PAGE;
    setPatientsToDisplay(patients.slice(start, end));
  }, [page, patients]);

  useEffect(() => {
    const totalPages = Math.ceil(patients.length / RECORDS_PER_PAGE);
    setNumberOfPages(totalPages);
  }, [patients]);

  const handlePageChange = (page: number) => {
    const topElement = document.querySelector("#top");
    if (topElement) {
      topElement.scrollIntoView();
    }
    setPage(page);
  };

  return (
    <>
      <Box id="top" style={{ marginTop: "-150px", marginBottom: "150px" }} />
      <TextHeader sx={{ fontSize: "32px", lineHeight: "40px", paddingTop: "30px", paddingBottom: "55px" }}>{t("my-patients")}</TextHeader>
      {patients.length > 0 && (
        <>
          <Box sx={{ display: "flex", direction: "row", marginBottom: "4px", padding: "10px 16px", minWidth: "800px" }}>
            <DefaultText sx={{ flex: 2 }}>{t("patient")}</DefaultText>
            <DefaultText sx={{ flex: 1 }}>{t("gender")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "115px" }}>{t("date-of-birth")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "170px" }}>{t("health-record-number")}</DefaultText>
            <DefaultText sx={{ flex: 3 }}>{t("main-contact-person")}</DefaultText>
          </Box>
          {patientsToDisplay.map((patient, index) => (
            <DefaultTableRow link={`/patients/${patient.id}/30/`} key={index}>
              <Box sx={{ flex: 2 }}>
                <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  {patient.lastName}, {patient.firstName}
                </DefaultText>
              </Box>
              <Box sx={{ flex: 1 }}>
                <DefaultText>{t(`gender.${patient.gender}`)}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, minWidth: "115px" }}>
                <DefaultText>{reformatDate(patient.dateOfBirth, "YYYY-MM-DD", "MM/DD/YYYY")}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, minWidth: "170px" }}>
                {patient.healthRecordNumber ? (
                  <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>{patient.healthRecordNumber}</DefaultText>
                ) : (
                  <DefaultTooltip
                    title={
                      <Box sx={{ padding: "16px", width: "394px", backgroundColor: "white", borderRadius: "8px" }}>
                        <DefaultText>{t("missing-healthcare-provider-info")}</DefaultText>
                      </Box>
                    }
                  >
                    <InfoRedIcon sx={{ marginTop: "4px" }} />
                  </DefaultTooltip>
                )}
              </Box>
              <Box sx={{ flex: 3, display: "flex", justifyContent: "space-between" }}>
                <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  {patient.mainContactPerson.firstName} {patient.mainContactPerson.lastName}
                </DefaultText>
                <Box
                  sx={{
                    backgroundColor: "rgba(231, 234, 238, 1)",
                    width: "79px",
                    marginRight: "-16px",
                    marginY: "-14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0px 4px 4px 0px",
                  }}
                >
                  <CaretRightIcon />
                </Box>
              </Box>
            </DefaultTableRow>
          ))}
          {patients.length > RECORDS_PER_PAGE && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <DefaultPagination count={numberOfPages} page={page} setPage={handlePageChange} sx={{ marginTop: "28px" }} />
            </Box>
          )}
        </>
      )}
      {patients.length === 0 && <EmptyPatientsList />}
    </>
  );
};

export default PatientsList;
