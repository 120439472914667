import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultText from "./DefaultText";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { EventPerformer } from "../model/event.model";

interface EventSummaryProps {
  performers: EventPerformer[];
  handleClose: () => void;
}

const EventSummary = ({ performers, handleClose }: EventSummaryProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "40% 28% 32%", backgroundColor: "rgba(231, 234, 238, 1)", padding: "12px 10px" }}>
          <DefaultText sx={{ fontSize: "12px", marginRight: "10px", borderRight: "1px solid rgba(179, 187, 196, 1)" }}>{t("logged-by")}</DefaultText>
          <DefaultText sx={{ fontSize: "12px", marginRight: "10px", borderRight: "1px solid rgba(179, 187, 196, 1)" }}>{t("role")}</DefaultText>
          <DefaultText sx={{ fontSize: "12px" }}>{t("days-logged-event")}</DefaultText>
        </Box>
        {performers.map((performer, index) => (
          <Box key={index} sx={{ display: "grid", gridTemplateColumns: "40% 28% 32%", padding: "8px 10px", border: "1px solid rgba(222, 227, 233, 1)", borderTop: "none" }}>
            <Box sx={{ display: "flex", gap: "3px" }}>
              <DefaultText sx={{ fontSize: "12px", fontWeight: 700 }}>{performer.fullName} </DefaultText>
              {!performer.active && <DefaultText sx={{ fontSize: "12px" }}>({t("inactive").toLowerCase()})</DefaultText>}
            </Box>
            <DefaultText sx={{ fontSize: "12px" }}>{t(`${performer.role.toLowerCase()}`)}</DefaultText>
            <DefaultText sx={{ fontSize: "12px" }}> {performer.daysLoggedCount}</DefaultText>
          </Box>
        ))}
      </Box>
      <DefaultPrimaryButton onClick={handleClose} sx={{ width: "166px", marginTop: "32px", float: "right" }}>
        {t("close")}
      </DefaultPrimaryButton>
    </>
  );
};

export default EventSummary;
