import { Box } from "@mui/material";
import DefaultText from "../components/DefaultText";
import { useTranslation } from "react-i18next";
import notFound from "../assets/img/404.svg";
import forbidden from "../assets/img/403.svg";
import internalError from "../assets/img/500.svg";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import { useNavigate } from "react-router-dom";
import { getErrorState } from "../state/ErrorState";
import { useEffect } from "react";
import { useAppState } from "../state/AppState";

const ERROR_IMAGES: Record<number, string> = {
  404: notFound,
  403: forbidden,
  500: internalError,
};

const ErrorPage = ({ code }: { code: number }) => {
  const { t } = useTranslation();
  const errorState = getErrorState();
  const imgSrc = ERROR_IMAGES[code] || internalError;
  const navigate = useNavigate();
  const { navigation } = useAppState();

  const handleErrorButton = () => {
    if (code === 404 || code === 403) {
      errorState.code = null;
      navigate("/");
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    navigation.backgroundColor = "#F0F4FA";
  }, [navigation]);

  useEffect(() => {
    if (code === 401) {
      errorState.code = null;
      navigate("/login");
    }
  }, [code, errorState, navigate]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(231, 234, 238, 1)",
          borderRadius: "8px",
          paddingY: "80px",
        }}
      >
        <Box component="img" src={imgSrc} sx={{ width: "160px", height: "160px", paddingBottom: "32px" }} />
        {code === 500 && <DefaultText sx={{ fontSize: "36px", fontWeight: 700, lineHeight: "40px", marginBottom: "16px" }}>{t("error.title")}</DefaultText>}
        <DefaultText sx={{ fontSize: "24px", fontWeight: 700, lineHeight: "24px" }}>{t(`error.${code}`, t("error.500"))}</DefaultText>
        <DefaultText sx={{ marginTop: "8px", color: "rgba(78, 93, 105, 1)" }}>{t(`error.message.${code}`, t("error.message.500"))}</DefaultText>
        <DefaultPrimaryButton onClick={handleErrorButton} sx={{ marginTop: "32px" }}>
          {code === 404 || code === 403 ? t("go-back-to-dashboard") : t("reload-page")}
        </DefaultPrimaryButton>
      </Box>
    </Box>
  );
};

export default ErrorPage;
