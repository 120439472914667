import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";
import { ChartData, DoseUnit, TrackingHistoryChartData, ScheduleType } from "../model/report.model";
import TextHeader from "./TextHeader";
import { Box } from "@mui/material";
import { reformatDate } from "../util/date";
import { useLocation } from "react-router-dom";
import ChartTooltip from "./ChartTooltip";

interface TrackingHistoryChartProps {
  data: TrackingHistoryChartData;
  numberOfDays: number;
}

const TrackingHistoryChart = ({ data, numberOfDays }: TrackingHistoryChartProps) => {
  const svgRef = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const chartData = data.chartData;
  const queryParams = new URLSearchParams(location.search);
  const scheduleType = queryParams.get("scheduleType");
  const header = data.observationNameCode?.replace(/_/g, "-").toLowerCase() || "";
  const observationChartData = !!chartData.find((item) => item.observationRating !== undefined);
  const medicationChartData = !!chartData.find((item) => item.medication !== undefined);
  const medicationBaseline = !!chartData.find((item) => item.medication?.doseBaseLine !== undefined);
  const bothCharts = observationChartData && medicationChartData;
  const maxDoseTaken = Math.max(
    ...chartData.map((data) => {
      const doseTaken = data.medication?.doseTaken || 0;
      const doseBaseLine = data.medication?.doseBaseLine || 0;
      return Math.max(doseTaken, doseBaseLine);
    })
  );
  const doseDigitsCount = maxDoseTaken.toString().length;
  const xAxisOffset = doseDigitsCount > 3 ? (doseDigitsCount - 3) * 8 : 0;

  const margin = { top: 0, right: numberOfDays === 90 ? 50 : 95, bottom: 0, left: 45 };
  const minWidth = window.innerWidth - window.innerWidth / 10 > 800 ? window.innerWidth - window.innerWidth / 10 : 800;
  const width = minWidth - margin.left - margin.right;
  const labelNamesShift = 20;
  const beginningShift = 65 + xAxisOffset;
  const height = 322 - margin.top - margin.bottom - (bothCharts ? 0 : 80);
  const graphDivisionRatio = (height + (bothCharts ? 0 : 80)) / 1.8;
  const shiftGraphDivisionRatio = graphDivisionRatio + 28;
  const baseLineWidth = width / (chartData.length + 1);
  const fontFamily = "Lato";
  const fontSize = 12;
  const upperBarPrefix = "UB_";
  const lowerBarPrefix = "LB_";
  const DEFAULT_LOWER_BAR_COLOR = "rgba(72, 197, 29, 1)";
  const DEFAULT_UPPER_BAR_COLOR = observationChartData ? "rgba(117, 64, 180, 1)" : DEFAULT_LOWER_BAR_COLOR;

  const xScale = d3
    .scaleLinear()
    .domain([1, numberOfDays])
    .range([20, width - xAxisOffset]);
  const horizontalLine = xScale(chartData.length) + beginningShift + (numberOfDays === 90 ? 10 : 20);

  let xAxis: d3.Axis<d3.NumberValue>;
  if (numberOfDays === 30) {
    xAxis = d3
      .axisBottom(xScale)
      .tickValues(chartData.map((d, i) => i + 1))
      .ticks(numberOfDays)
      .tickSize(0)
      .tickFormat((d, i) => (chartData[i].date ? chartData[i].date.slice(5).replace("-", "/") : ""));
  } else if (numberOfDays === 60) {
    xAxis = d3
      .axisBottom(xScale)
      .tickValues(chartData.map((d, i) => i + 1))
      .tickSizeInner(10)
      .tickFormat((d, i) => {
        const date = chartData[i].date ? chartData[i].date.slice(5).replace("-", "/") : "";
        if (date.endsWith("/01")) {
          return date;
        } else {
          return "";
        }
      });
  } else if (numberOfDays === 90) {
    xAxis = d3
      .axisBottom(xScale)
      .tickValues(chartData.map((d, i) => i + 1))
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .tickFormat((d, i) => {
        const date = chartData[i].date ? chartData[i].date.slice(5) : "";
        if (date.endsWith("-01")) {
          return reformatDate(chartData[i].date, "YYYY-MM-DD", "MMMM");
        } else {
          return "";
        }
      });
  }

  const roundToNearestTen = (value: number): number => {
    return Math.ceil(value / 10) * 10;
  };

  const roundToDivisibleByThree = (value: number): number => {
    return Math.ceil(value / 3) * 3;
  };

  const calculateMedicationTicks = (): number[] => {
    const maxYaxis = maxDoseTaken >= 10 ? roundToNearestTen(maxDoseTaken) : roundToDivisibleByThree(maxDoseTaken);
    const result = [0];
    const step = Math.floor(maxYaxis / 3);
    for (let i = 1; i <= 3; i++) {
      if (i === 3) {
        result.push(maxYaxis);
      } else {
        result.push(step * i);
      }
    }
    return result.reverse();
  };

  const medicationTicks: number[] = calculateMedicationTicks();
  const upperRatingTicks: number[] = observationChartData ? [5, 4, 3, 2, 1, 0] : medicationTicks;

  const yScaleUpper = observationChartData
    ? d3.scaleLinear().domain([0, 5]).range([0, graphDivisionRatio])
    : d3.scaleLinear().domain([0, medicationTicks[0]]).range([0, graphDivisionRatio]);

  const yScaleLower = d3
    .scaleLinear()
    .domain([0, medicationTicks[0]])
    .range([0, height / 5])
    .nice();

  const yAxisUpper = d3
    .axisLeft(yScaleUpper)
    .ticks(upperRatingTicks.length)
    .tickValues(upperRatingTicks.map((d) => d))
    .tickFormat((d, i) => {
      return [...upperRatingTicks].reverse()[i].toString();
    })
    .tickSize(0)
    .tickPadding(20 - beginningShift);

  const yAxisLower = d3
    .axisLeft(yScaleLower)
    .ticks(4)
    .tickValues(medicationTicks.map((d) => d))
    .tickSize(0)
    .tickPadding(20 - beginningShift);

  const calculateAverageTrend = (data: ChartData[]) => {
    const observations = data.filter((data: ChartData) => data.observationRating !== undefined);
    if (observations.length < 2) {
      return;
    }
    const midpoint = Math.ceil(observations.length / 2);
    const firstHalf = observations.slice(0, midpoint);
    const secondHalf = observations.slice(-midpoint);
    const trendStart = (firstHalf.reduce((acc, item) => acc + (item.observationRating as number), 0) * 2) / observations.length;
    const trendEnd = (secondHalf.reduce((acc, item) => acc + (item.observationRating as number), 0) * 2) / observations.length;
    return { trendStart, trendEnd };
  };

  const buildSvg = () => {
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("font-family", fontFamily)
      .attr("font-size", fontSize)
      .attr("color", "rgba(78, 93, 105, 1)");
    return svg;
  };

  const addUpperYaxisAndLabel = (svg: any) => {
    svg
      .append("g")
      .attr("class", "y-axis-upper")
      .call(yAxisUpper)
      .attr("transform", "translate(0,28)")
      .attr("font-family", fontFamily)
      .attr("font-size", fontSize)
      .selectAll("path,line")
      .remove();
    if (observationChartData) {
      svg.append("text").attr("class", "y label").attr("text-anchor", "end").attr("x", labelNamesShift).attr("y", 30).text(t("rating")).style("fill", "rgba(78, 93, 105, 1)");
    } else {
      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("x", labelNamesShift)
        .attr("y", 30)
        .text(t("doses").slice(0, 1).toUpperCase() + t("doses").slice(1, t("doses").length) + ` [${t(data.doseUnit as string)}]`)
        .style("fill", "rgba(78, 93, 105, 1)");
    }
  };

  const addLowerYaxisAndLabel = (svg: any) => {
    svg
      .append("g")
      .attr("class", "y-axis-lower")
      .call(yAxisLower)
      .attr("transform", `translate(0,${shiftGraphDivisionRatio})`)
      .attr("font-family", fontFamily)
      .attr("font-size", fontSize)
      .selectAll("path,line")
      .remove();
    svg
      .append("text")
      .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("x", labelNamesShift)
      .attr("y", 275)
      .text(t("doses").slice(0, 1).toUpperCase() + t("doses").slice(1, t("doses").length) + ` [${t(data.doseUnit as string)}]`)
      .style("fill", "rgba(78, 93, 105, 1)");
  };

  const ticksTransform = () => {
    if (numberOfDays === 30) {
      return `translate(${beginningShift + 10},${bothCharts ? 293 : 214})`;
    } else if (numberOfDays === 60) {
      return `translate(${beginningShift + 20},${bothCharts ? 286 : 215})`;
    } else if (numberOfDays === 90) {
      return `translate(${beginningShift + 45},${bothCharts ? 310 : 220})`;
    }
  };

  const addXaxisandLabel = (svg: any) => {
    svg
      .append("g")
      .attr("class", "x-axis")
      .call(xAxis)
      .attr("transform", ticksTransform())
      .attr("font-family", fontFamily)
      .attr("font-size", fontSize)
      .style("color", "rgba(78, 93, 105, 1)")
      .style("font-color", "green")
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("transform", numberOfDays === 30 ? "rotate(-45)" : "");
    svg.selectAll("path,line").remove();
    svg
      .append("text")
      .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("x", labelNamesShift)
      .attr("y", numberOfDays === 90 ? (bothCharts ? 322 : 232) : bothCharts ? 307 : 237)
      .text(numberOfDays === 90 ? t("months") : t("days").slice(0, 1).toUpperCase() + t("days").slice(1, t("days").length))
      .style("fill", "rgba(78, 93, 105, 1)");
  };

  const addUpperDataGridLines = (svg: any) => {
    svg
      .selectAll(null)
      .data(observationChartData ? upperRatingTicks : medicationTicks)
      .enter()
      .append("line")
      .attr("class", "y-grid-line")
      .attr("x1", beginningShift)
      .attr("x2", horizontalLine)
      .attr("y1", (d: number) => yScaleUpper(d) + 28)
      .attr("y2", (d: number) => yScaleUpper(d) + 28)
      .style("stroke", "rgba(213, 219, 227, 1)")
      .style("stroke-dasharray", "2");
  };

  const addLowerDataGridLines = (svg: any) => {
    if (medicationTicks && medicationTicks.length > 0) {
      svg
        .selectAll(".y-grid-line-lower")
        .data(medicationTicks)
        .enter()
        .append("line")
        .attr("class", "y-grid-line-lower")
        .attr("x1", beginningShift)
        .attr("x2", horizontalLine)
        .attr("y1", (d: number) => shiftGraphDivisionRatio + yScaleLower(d))
        .attr("y2", (d: number) => shiftGraphDivisionRatio + yScaleLower(d))
        .style("stroke", "rgba(213, 219, 227, 1)")
        .style("stroke-dasharray", "2");
    }
  };

  const addHorizontalLine = (svg: any) => {
    svg
      .append("line")
      .attr("x1", beginningShift)
      .attr("x2", horizontalLine)
      .attr("y1", shiftGraphDivisionRatio)
      .attr("y2", shiftGraphDivisionRatio)
      .style("stroke", "rgba(78, 93, 105, 1)")
      .style("stroke-width", 1)
      .style("stroke-line", 1);
  };

  const addBaselineOfMedications = (svg: any) => {
    svg
      .selectAll(".lower-point")
      .data(chartData)
      .enter()
      .append("line")
      .attr("class", "lower-point")
      .attr("x1", (d: any, index: number) => (d.medication.doseBaseLine ? xScale(index + 1) + beginningShift + 3 - baseLineWidth / 1.9 : null))
      .attr("x2", (d: any, index: number) => (d.medication.doseBaseLine ? xScale(index + 1) + beginningShift + 4 + baseLineWidth / 1.9 : null))
      .attr("y1", (d: any) =>
        d.medication.doseBaseLine ? shiftGraphDivisionRatio + (observationChartData ? yScaleLower(d.medication.doseBaseLine) : -yScaleUpper(d.medication.doseBaseLine)) : null
      )
      .attr("y2", (d: any) =>
        d.medication.doseBaseLine ? shiftGraphDivisionRatio + (observationChartData ? yScaleLower(d.medication.doseBaseLine) : -yScaleUpper(d.medication.doseBaseLine)) : null
      )
      .style("stroke", "#DD3131")
      .style("stroke-width", 1)
      .on("mouseover", mouseover)
      .on("mouseout", mouseout);
  };

  const addObservationsTrendLine = async (svg: any) => {
    const averageTrend = await calculateAverageTrend(chartData);
    if (!averageTrend) {
      return;
    }
    svg
      .append("line")
      .attr("x1", beginningShift)
      .attr("x2", horizontalLine)
      .attr("y1", shiftGraphDivisionRatio - yScaleUpper(averageTrend.trendStart))
      .attr("y2", shiftGraphDivisionRatio - yScaleUpper(averageTrend.trendEnd))
      .style("stroke", "rgba(254, 183, 1, 1)")
      .style("stroke-width", 2)
      .style("stroke-dasharray", "2");
  };

  const addUpperDataBars = (svg: any) => {
    svg
      .selectAll(".upper-bar")
      .data(chartData)
      .enter()
      .append("rect")
      .attr("class", "upper-bar")
      .attr("id", (d: number, index: number) => header + upperBarPrefix + index)
      .attr("x", (d: number, index: number) => xScale(index + 1) + beginningShift)
      .attr("y", (d: any) => shiftGraphDivisionRatio - yScaleUpper(observationChartData ? (d.observationRating ? d.observationRating : 0) : d.medication.doseTaken))
      .attr("width", numberOfDays === 90 ? 4 : 8)
      .attr("height", (d: any) =>
        yScaleUpper(observationChartData ? (d.observationRating ? d.observationRating : 0) : d.medication.doseTaken) < 2
          ? 0
          : yScaleUpper(observationChartData ? (d.observationRating ? d.observationRating : 0) : d.medication.doseTaken) - 2
      )
      .style("stroke", DEFAULT_UPPER_BAR_COLOR)
      .style("fill", DEFAULT_UPPER_BAR_COLOR)
      .attr("rx", numberOfDays === 90 ? 2 : 4)
      .on("mouseover", mouseover)
      .on("mouseout", mouseout);
  };

  const addLowerDataBars = (svg: any) => {
    svg
      .selectAll(".lower-bar")
      .data(chartData)
      .enter()
      .append("rect")
      .attr("class", "lower-bar")
      .attr("id", (d: number, index: number) => header + lowerBarPrefix + index)
      .attr("x", (d: number, index: number) => xScale(index + 1) + beginningShift)
      .attr("y", shiftGraphDivisionRatio + 2)
      .attr("width", numberOfDays === 90 ? 4 : 8)
      .attr("height", (d: any) => (yScaleLower(d.medication.doseTaken) < 2 ? 0 : yScaleLower(d.medication.doseTaken) - 2))
      .style("stroke", DEFAULT_LOWER_BAR_COLOR)
      .style("fill", DEFAULT_LOWER_BAR_COLOR)
      .attr("rx", numberOfDays === 90 ? 2 : 4)
      .on("mouseover", mouseover)
      .on("mouseout", mouseout);
  };

  const mouseover = function (event: any, element: any) {
    const prevTooltip = d3.selectAll("#tooltip");
    prevTooltip.style("opacity", 0).remove();
    highlightChartBar(event.target, true);
    const [x, y] = d3.pointer(event);

    const chartSvg = svgRef.current;
    if (chartSvg) {
      d3.select(chartSvg)
        .append("foreignObject")
        .html(ChartTooltip({ element, header, scheduleType: scheduleType as ScheduleType, doseUnit: data.doseUnit as DoseUnit }))
        .attr("id", "tooltip")
        .style("overflow", "visible");
      const tooltip = d3.selectAll("#tooltip");
      const tooltipDiv = document.getElementById("tooltip-content");
      const tooltipRect = tooltipDiv?.getBoundingClientRect() || { width: 800, height: 200 };
      const chartRect = (chartSvg as HTMLElement).getBoundingClientRect();

      const enoughSpaceX = chartRect.width - x < tooltipRect.width;
      const xPosition = enoughSpaceX ? chartRect.width - tooltipRect.width : x + 10;
      const enoughSpaceY = y > height / 2;
      const yPosition = enoughSpaceY ? y - tooltipRect.height - 10 : y + 10;

      tooltip.attr("x", xPosition).attr("y", yPosition);
    }
  };

  const mouseout = (event: any) => {
    highlightChartBar(event.target, false);
    const tooltip = d3.selectAll("#tooltip");
    tooltip.style("opacity", 0).remove();
  };

  const highlightChartBar = (target: any, isHovered: boolean) => {
    const targetId = target.getAttribute("id");
    if (!targetId) return;
    const index = targetId.split("_")[1];
    const isLowerBar = targetId.includes(lowerBarPrefix);
    const oppositeBarId = "#" + header + (isLowerBar ? upperBarPrefix : lowerBarPrefix) + index;
    const oppositeBar = d3.selectAll(oppositeBarId);
    const highlightedLowerBarColor = "rgba(218, 255, 205, 1)";
    const highlightedUpperBarColor = observationChartData ? "rgba(213, 168, 255, 1)" : highlightedLowerBarColor;

    if (isHovered) {
      target.style.fill = isLowerBar ? highlightedLowerBarColor : highlightedUpperBarColor;
      oppositeBar && oppositeBar.style("fill", isLowerBar ? highlightedUpperBarColor : highlightedLowerBarColor);
    } else {
      target.style.fill = isLowerBar ? DEFAULT_LOWER_BAR_COLOR : DEFAULT_UPPER_BAR_COLOR;
      oppositeBar && oppositeBar.style("fill", isLowerBar ? DEFAULT_UPPER_BAR_COLOR : DEFAULT_LOWER_BAR_COLOR);
    }
  };

  useEffect(() => {
    if (!observationChartData && !medicationChartData) {
      return;
    }
    const svg = buildSvg();
    addXaxisandLabel(svg);
    addUpperYaxisAndLabel(svg);
    addUpperDataGridLines(svg);
    if (observationChartData) {
      addObservationsTrendLine(svg);
    }
    if (bothCharts) {
      addLowerYaxisAndLabel(svg);
      addLowerDataGridLines(svg);
      addHorizontalLine(svg);
      addLowerDataBars(svg);
      addUpperDataBars(svg);
    } else {
      addHorizontalLine(svg);
      addUpperDataBars(svg);
    }
    if (medicationBaseline) {
      addBaselineOfMedications(svg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  return (
    <Box>
      {header && <TextHeader sx={{ fontSize: "24px", position: "relative" }}>{t(header)}</TextHeader>}
      <svg style={{ overflow: "visible" }} ref={svgRef as any}></svg>
    </Box>
  );
};

export default TrackingHistoryChart;
