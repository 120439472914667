import { Box } from "@mui/material";
import Logo from "./components/Logo";
import Avatar from "./components/Avatar";
import TopNavigation from "./TopNavigation";
import UserProfileMenu from "./components/UserProfileMenu";
import { useAppState } from "./state/AppState";

const Header = () => {
  const { user } = useAppState();

  return (
    <Box
      sx={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        position: "static",
        backgroundColor: "rgba(255, 255, 255, 1)",
        paddingLeft: "5%",
        paddingRight: "5%",
        boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
      }}
    >
      <Box sx={{ flex: 0.5 }}>
        <Logo />
      </Box>
      <Box sx={{ flex: 1, height: "100%" }}>
        <TopNavigation />
      </Box>
      <Box sx={{ flex: 0.5, justifyContent: "flex-end", alignItems: "center", display: "flex", gap: "32px" }}>
        <Avatar firstName={user.loggedUser?.firstName as string} lastName={user.loggedUser?.lastName as string} email={user.loggedUser?.email as string} />
        <UserProfileMenu />
      </Box>
    </Box>
  );
};

export default Header;
