import { Box, CircularProgress } from "@mui/material";
import TextHeader from "../components/TextHeader";
import TextLabel from "../components/TextLabel";
import RegistrationStepper from "../components/RegistrationStepper";
import { Trans, useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import DefaultSecondaryButton from "../components/DefaultSecondaryButton";
import { useAppState } from "../state/AppState";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import DefaultInput from "../components/DefaultInput";
import { useEffect, useMemo, useRef, useState } from "react";
import DefaultSelect, { Data } from "../components/DefaultSelect";
import { validate, latinCharactersPattern, patterns } from "../util/validation";
import { invitationApi } from "../api/InvitationApi";
import { RegistrationDto } from "../model/user.model";
import { userApi } from "../api/UserApi";
import HealthcareProviderAutocomplete from "../components/HealthcareProviderAutocomplete";
import { HealthcareProvider, HealthcareProviders } from "../model/healthcareProvider.model";
import { TerminablePromise } from "../api/controllers/TerminableRequest";
import { throttle } from "lodash";

const CreatePersonalProfilePage = () => {
  const { t } = useTranslation();
  const { user, notification } = useAppState();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [hcpSpecialty, setHcpSpecialty] = useState("");
  const [hcpSpecialtyError, setHcpSpecialtyError] = useState("");
  const [healthcareProvider, setHealthcareProvider] = useState<HealthcareProvider | null>(null);
  const [healthcareProviderInput, setHealthcareProviderInput] = useState("");
  const [healthcareProviderInputError, setHealthcareProviderInputError] = useState("");
  const [newPracticeName, setNewPracticeName] = useState("");
  const [newPracticeNameError, setNewPracticeNameError] = useState("");
  const [newPracticeLocation, setNewPracticeLocation] = useState("");
  const [newPracticeLocationError, setNewPracticeLocationError] = useState("");
  const [continueLoading, setContinueLoading] = useState(false);
  const otherHealthcareProvider = { id: "other", name: t("other"), location: "", npi: "", ehrLaunchEnabled: true };
  const [healthcareProviders, setHealthcareProviders] = useState<HealthcareProvider[]>([otherHealthcareProvider]);
  const [healthcareProviderLoading, setHealthcareProviderLoading] = useState(false);
  const [healthcareProvidersFetched, setHealthcareProvidersFetched] = useState(false);
  const [emptyHealthcareProvidersList, setEmptyHealthcareProvidersList] = useState(false);
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("id");
  const terminableRequestRef = useRef<TerminablePromise<HealthcareProviders> | null>(null);
  const correctProviderOrPractice = emptyHealthcareProvidersList
    ? !(!newPracticeName || !newPracticeLocation)
    : !(!healthcareProviderInput || !healthcareProvider?.id || (healthcareProvider?.id === "other" && (!newPracticeName || !newPracticeLocation)));
  const disabledContinueBtn =
    firstNameError.length > 0 ||
    lastNameError.length > 0 ||
    hcpSpecialtyError.length > 0 ||
    newPracticeNameError.length > 0 ||
    newPracticeLocationError.length > 0 ||
    healthcareProviderInputError.length > 0 ||
    !firstName ||
    !lastName ||
    !hcpSpecialty ||
    !correctProviderOrPractice ||
    healthcareProviderLoading ||
    continueLoading;

  const hcpSpecialties: Data[] = [
    {
      label: t("general-physician"),
      value: "59058001",
    },
    {
      label: t("nurse-practitioner"),
      value: "224571005",
    },
    {
      label: t("pediatrician"),
      value: "82296001",
    },
    {
      label: t("psychiatrist"),
      value: "80584001",
    },
    {
      label: t("psychologist"),
      value: "59944000",
    },
  ];

  const handleBack = () => {
    navigate(`/register/step2${user.invitationUrl()}`);
  };

  const handleContinue = async () => {
    setContinueLoading(true);
    const registrationData: RegistrationDto = {
      invitationId: user.invitationId as string,
      registrationToken: user.registrationToken as string,
      firstName,
      lastName,
      password: user.password as string,
      specialty: hcpSpecialty,
      acceptedTermsAndConditions: user.acceptedTermsAndConditions,
    };
    if (healthcareProvider?.id !== "other" && !emptyHealthcareProvidersList) {
      registrationData.providerId = healthcareProvider?.id;
      registrationData.practiceName = undefined;
      registrationData.practiceLocation = undefined;
    } else {
      registrationData.providerId = undefined;
      registrationData.practiceName = newPracticeName;
      registrationData.practiceLocation = newPracticeLocation;
    }
    const response = await invitationApi.registerHcp(registrationData);
    if (response.ok) {
      await login();
    }
    user.clearInvitationData();
  };

  const login = async (): Promise<void> => {
    const response = await userApi.login(user.email as string, user.password as string, { showErrorPage: true });
    if (response.ok) {
      navigate("/patients");
      notification.addNotification({ title: t("registration-completed"), message: t("you-have-registered") });
      notification.addNotification({
        title: t("new-patient-record"),
        message: (
          <Trans
            i18nKey="access-to-patient"
            components={{ b: <b></b> }}
            values={{
              newPatientFirstName: user.newPatientFirstName,
              newPatientLastName: user.newPatientLastName,
            }}
          />
        ) as unknown as string,
      });
    }
  };

  const handleInputChange = (value: string, setter: (value: string) => void, errorSetter: (value: string) => void) => {
    setter(value);
    const error = validate(value, latinCharactersPattern);
    if (error) {
      errorSetter(t(error));
    } else {
      errorSetter("");
    }
  };

  const handleNewPracticeNameChange = (value: string) => {
    setNewPracticeName(value);
    const error = validate(value, [patterns.requiredField, patterns.oneHundredCharacters]);
    if (error) {
      setNewPracticeNameError(t(error));
    } else {
      setNewPracticeNameError("");
    }
  };

  const handleNewPracticeLocationChange = (value: string) => {
    setNewPracticeLocation(value);
    const error = validate(value, [patterns.requiredField, patterns.twoHundredFiftyFiveCharacters]);
    if (error) {
      setNewPracticeLocationError(t(error));
    } else {
      setNewPracticeLocationError("");
    }
  };

  const fetchHealthcareProviders = useMemo(() => {
    return throttle(async () => {
      if (terminableRequestRef.current) {
        terminableRequestRef.current.abort();
      }
      terminableRequestRef.current = invitationApi.getHealthcareProviders(invitationId as string, user.registrationToken as string, healthcareProviderInput);
      setHealthcareProviderLoading(true);
      try {
        const fetchedHealthcareProviders = await terminableRequestRef.current?.promise;
        if (fetchedHealthcareProviders) {
          setHealthcareProvidersFetched(true);
          if (fetchedHealthcareProviders.items?.length === 0 && !healthcareProviderInput) {
            setEmptyHealthcareProvidersList(true);
          }
          const sortedHealthcareProviders = fetchedHealthcareProviders.items?.sort((a, b) => a.name.localeCompare(b.name));
          setHealthcareProviderLoading(false);
          setHealthcareProviders([otherHealthcareProvider, ...sortedHealthcareProviders]);
        }
      } catch (e) {
        console.error(e);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthcareProviderInput]);

  useEffect(() => {
    if (healthcareProviderInput !== t("other") && !emptyHealthcareProvidersList) {
      setNewPracticeNameError("");
      setNewPracticeLocationError("");
      fetchHealthcareProviders();
    }
  }, [emptyHealthcareProvidersList, fetchHealthcareProviders, healthcareProviderInput, invitationId, t, user.registrationToken]);

  return (
    <Box sx={{ width: "390px", paddingTop: "47px" }}>
      <TextLabel sx={{ paddingBottom: "2px", color: "rgba(78, 93, 105, 1)" }}>{t("register")}</TextLabel>
      <TextHeader sx={{ paddingBottom: "24px" }}>{t("credentials-for-account")}</TextHeader>
      <RegistrationStepper step={3} />
      {healthcareProvidersFetched ? (
        <>
          <DefaultInput
            label={t("first-name")}
            error={firstNameError.length > 0}
            errorMsg={firstNameError}
            value={firstName}
            onChange={(value) => handleInputChange(value, setFirstName, setFirstNameError)}
            sx={{ width: "100%" }}
          />
          <DefaultInput
            label={t("last-name")}
            error={lastNameError.length > 0}
            errorMsg={lastNameError}
            value={lastName}
            onChange={(value) => handleInputChange(value, setLastName, setLastNameError)}
            sx={{ width: "100%", marginY: "34px" }}
          />
          <DefaultSelect
            label={t("specialty")}
            error={hcpSpecialtyError.length > 0}
            errorMsg={hcpSpecialtyError}
            value={hcpSpecialty}
            options={hcpSpecialties}
            selectHandler={(value) => handleInputChange(value, setHcpSpecialty, setHcpSpecialtyError)}
            sx={{ width: "100%", marginBottom: "34px" }}
          />
          {!emptyHealthcareProvidersList && (
            <HealthcareProviderAutocomplete
              options={healthcareProviders}
              onChange={(newValue: HealthcareProvider | null) => {
                setHealthcareProvider(newValue);
              }}
              onInputChange={(inputValue) => {
                handleInputChange(inputValue, setHealthcareProviderInput, setHealthcareProviderInputError);
              }}
              loading={healthcareProviderLoading}
              error={healthcareProviderInputError.length > 0}
              errorMsg={healthcareProviderInputError}
              sx={{ width: "100%", marginBottom: "34px" }}
            />
          )}
          {((healthcareProvider?.id === "other" && healthcareProviderInput === t("other")) || emptyHealthcareProvidersList) && (
            <>
              <DefaultInput
                label={t("healthcare-provider-practice-name")}
                error={newPracticeNameError.length > 0}
                errorMsg={newPracticeNameError}
                value={newPracticeName}
                onChange={(value) => handleNewPracticeNameChange(value)}
                sx={{ width: "100%" }}
              />
              <DefaultInput
                label={t("healthcare-provider-practice-location")}
                error={newPracticeLocationError.length > 0}
                errorMsg={newPracticeLocationError}
                value={newPracticeLocation}
                onChange={(value) => handleNewPracticeLocationChange(value)}
                hint={<Trans i18nKey="custom-healthcare-provider-emr-hint" />}
                sx={{ width: "100%", marginTop: "34px" }}
              />
            </>
          )}
          <Box sx={{ paddingTop: "56px", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "70px" }}>
            <DefaultSecondaryButton onClick={handleBack} sx={{ border: "none", padding: "0", height: "inherit", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}>
              {t("back")}
            </DefaultSecondaryButton>
            <DefaultPrimaryButton onClick={handleContinue} isLoading={continueLoading} loadingText={t("loading")} disabled={disabledContinueBtn} sx={{ width: "180px" }}>
              {t("continue")}
            </DefaultPrimaryButton>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "640px" }}>
          <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)" }} size="56px" />
        </Box>
      )}
    </Box>
  );
};

export default observer(CreatePersonalProfilePage);
