import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { Trans, useTranslation } from "react-i18next";
import { RatingScalesCalculationResult } from "../model/ratingScales.model";

interface CalculationResultProps {
  scoringInstruction: RatingScalesCalculationResult;
  sx?: SxProps;
}

const CalculationResult = ({ scoringInstruction, sx }: CalculationResultProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...sx }}>
      <DefaultText sx={{ fontSize: "18px", lineHeight: "24px", fontWeight: 700, marginBottom: "20px" }}>{scoringInstruction.conditionName}</DefaultText>
      {scoringInstruction.suspected && (
        <DefaultText
          sx={{
            width: "fit-content",
            marginBottom: "20px",
            fontWeight: 700,
            color: "#966E07",
            padding: "12px 16px",
            backgroundColor: "#FFEAA7",
            border: "1px solid #966E07",
            borderRadius: "8px",
          }}
        >
          {t("suspected")}
        </DefaultText>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", marginBottom: "32px" }}>
        {scoringInstruction.rules.map((rule, index) => (
          <Box key={index} sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Box sx={{ border: "1px solid #B3BBC4", borderRadius: "8px", padding: "8px 16px" }}>
              <DefaultText sx={{ color: "#4E5D69" }}>{rule}</DefaultText>
            </Box>
            {scoringInstruction.rules.length > index + 1 && <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", textTransform: "uppercase" }}>{t("and")}</DefaultText>}
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
        {scoringInstruction.evaluationResults.map((evaluationResult, index) => (
          <Box key={index} sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "40px", alignItems: "center" }}>
              <DefaultText sx={{ fontSize: "18px", lineHeight: "24px" }}>
                {evaluationResult.expectedScores.length > 1 ? (
                  <Trans
                    i18nKey="scoring.long.instruction"
                    values={{ firstExpectedScore: evaluationResult.expectedScores[0], secondExpectedScore: evaluationResult.expectedScores[1] }}
                  />
                ) : (
                  <Trans i18nKey="scoring.short.instruction" values={{ expectedScore: evaluationResult.expectedScores[0] }} />
                )}
                {evaluationResult.isRange
                  ? ` ${evaluationResult.questionIndexes[0]}-${evaluationResult.questionIndexes[1]}`
                  : ` ${evaluationResult.questionIndexes[0]}, ${evaluationResult.questionIndexes[1]}`}
              </DefaultText>
              <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "#707C88", borderRadius: "4px", padding: "4px 16px", width: "11px", height: "24px" }}>
                <DefaultText sx={{ fontSize: "18px", lineHeight: "24px", color: "#FFF" }}>{evaluationResult.count}</DefaultText>
              </Box>
            </Box>
            {index + 1 < scoringInstruction.evaluationResults.length && <hr style={{ width: "100%", border: "0.5px solid rgba(213, 219, 227, 1)" }} />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CalculationResult;
