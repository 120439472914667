interface Pattern {
  regex: RegExp;
  error: string;
}

export const patterns: Record<string, Pattern> = {
  requiredField: {
    regex: /^.*\S.*$/,
    error: "mandatory-field",
  },
  latinCharactersPlus: {
    regex: /^[a-zA-Z\u0080-\u00FF\s0-9!@#$%^&*()_+{}\\[\]:;<>,.?~\\-]+$/,
    error: "latin-characters",
  },
  passwordRepeatedCharacters: {
    regex: /^(?!.*(.)\1{2})/,
    error: "password-repeated-characters",
  },
  anyLowercaseLetter: {
    regex: /(?=.*[a-z])/,
    error: "password-pattern-error",
  },
  anyUppercaseLetter: {
    regex: /(?=.*[A-Z])/,
    error: "password-pattern-error",
  },
  anyDigit: {
    regex: /(?=.*\d)/,
    error: "password-pattern-error",
  },
  anySpecialSymbol: {
    regex: /(?=.*?[!@#$%^&*()_+={}\\[\]:;<>,.?~\\-])/,
    error: "password-pattern-error",
  },
  passwordLength: {
    regex: /^.{8,64}$/,
    error: "password-invalid-length",
  },
  defaultLength: {
    regex: /^.{1,128}$/,
    error: "default-field-length",
  },
  oneHundredCharacters: {
    regex: /^.{1,100}$/,
    error: "one-hundred-chars",
  },
  twoHundredFiftyFiveCharacters: {
    regex: /^.{1,255}$/,
    error: "two-hundred-fifty-five-chars",
  },
  passwordAvailableCharacters: {
    regex: /^[A-Za-z\d!@#$%^&*()_+={}\\[\]:;<>,.?~\\-]+$/,
    error: "latin-characters",
  },
  email: {
    regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/,
    error: "email-invalid",
  },
  emailLength: {
    regex: /^.{6,128}$/,
    error: "email-invalid-length",
  },
};

export const passwordValidationPatterns: Pattern[] = [
  patterns.requiredField,
  patterns.passwordRepeatedCharacters,
  patterns.anyLowercaseLetter,
  patterns.anyUppercaseLetter,
  patterns.anyDigit,
  patterns.anySpecialSymbol,
  patterns.passwordAvailableCharacters,
  patterns.passwordLength,
];

export const emailValidationPatterns: Pattern[] = [patterns.requiredField, patterns.email, patterns.emailLength];

export const latinCharactersPattern: Pattern[] = [patterns.requiredField, patterns.latinCharactersPlus, patterns.defaultLength];

export const validate = (input: string, validationPatterns: Pattern[]): string => {
  const error = validationPatterns.find((pattern) => !pattern.regex.test(input));
  return error ? error.error : "";
};
