import { Box } from "@mui/material";
import { t } from "i18next";
import { RatingScalesRequest } from "../model/ratingScales.model";
import DefaultModal from "./DefaultModal";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import { useState } from "react";
import { ratingScalesApi } from "../api/RatingScalesApi";
import DefaultText from "./DefaultText";
import IconAndText from "./IconAndText";
import WarningIcon from "./icons/Warning";
import { Trans } from "react-i18next";

interface RevokeVanderbiltFormModalProps {
  handleClose: () => void;
  formRevokedCallback: () => void;
  ratingScalesRequest: RatingScalesRequest;
  patientId: string;
  isOpen: boolean;
}

const RevokeVanderbiltFormModal = ({ formRevokedCallback, handleClose, isOpen, ratingScalesRequest, patientId }: RevokeVanderbiltFormModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleRevoke = async () => {
    setLoading(true);
    await ratingScalesApi.revokeRatingScalesRequest(patientId, ratingScalesRequest.id, ratingScalesRequest.respondent.id);
    setLoading(false);
    formRevokedCallback();
  };

  const onClose = () => {
    setLoading(false);
    handleClose();
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <DefaultModal modalTitle={t("revoke-requested-form")} isOpen={isOpen} onClose={onClose}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {!ratingScalesRequest.requestor.active ? (
          <IconAndText
            icon={<WarningIcon />}
            text={
              <Trans
                i18nKey="revoke-form-confirmation-inactive-requestor"
                values={{ requestor: `${ratingScalesRequest.requestor.firstName} ${ratingScalesRequest.requestor.lastName}` }}
              />
            }
            textSx={{ fontSize: "16px", color: "rgba(221, 49, 49, 1)" }}
            sx={{ marginBottom: "24px" }}
          />
        ) : (
          <></>
        )}
        <DefaultText sx={{ fontSize: "16px" }}>{t("revoke-form-confirmation")}</DefaultText>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "16px", paddingTop: "40px" }}>
        <DefaultSecondaryButton onClick={onClose} sx={{ height: "44px", minWidth: "84px" }}>
          {t("cancel")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleRevoke} isLoading={loading} disabled={loading} sx={{ height: "44px", minWidth: "100px" }}>
          {t("proceed")}
        </DefaultPrimaryButton>
      </Box>
    </DefaultModal>
  );
};

export default RevokeVanderbiltFormModal;
