import { Box, SxProps } from "@mui/material";
import { MedicationTrackingData, ScheduleType } from "../model/report.model";
import TextHeader from "./TextHeader";
import DefaultDosesCard from "./DefaultDosesCard";
import TakenDosesCard from "./TakenDosesCard";
import { Trans, useTranslation } from "react-i18next";
import MissedDosesCard from "./MissedDosesCard";
import SkippedDosesCard from "./SkippedDosesCard";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import TextLabel from "./TextLabel";
import DoserInformation from "./DosageManagerInformation";
import { useParams } from "react-router-dom";
import Link from "./Link";
import { useAppState } from "../state/AppState";
import { useCallback } from "react";
import DefaultText from "./DefaultText";

interface TrackingResultsProps {
  medicationTrackingData: MedicationTrackingData;
  sx?: SxProps;
}

const TrackingResults = ({ medicationTrackingData, sx }: TrackingResultsProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { patientProfilePageState } = useAppState();

  const isDevice: boolean = medicationTrackingData.scheduleType === ScheduleType.DEVICE;
  const initiallyScheduledDose = `${medicationTrackingData.strength} ${medicationTrackingData.doseUnit}`;
  const description: string =
    `${medicationTrackingData.dosageForm.toLowerCase()}, ${initiallyScheduledDose}` +
    (medicationTrackingData.dosesPerDay
      ? `, ${medicationTrackingData.dosesPerDay} ${medicationTrackingData.dosesPerDay <= 1 ? t("dose") : t("doses")} ${t("scheduled-every-day")}`
      : "");

  const displayConditionName = useCallback((): React.ReactNode => {
    if (medicationTrackingData.condition) {
      const conditionName = t(`condition.${medicationTrackingData.condition}`, { adhdPrefix: `${t("adhd")} ` });
      const conditionLabel = <Trans i18nKey="condition.label" components={{ b: <b style={{ color: "rgb(4, 11, 16)" }}></b> }} values={{ conditionName }} />;
      return <DefaultText sx={{ color: "rgb(78, 93, 105)", marginTop: "12px" }}>{conditionLabel}</DefaultText>;
    }
    return <></>;
  }, [medicationTrackingData.condition, t]);

  const observationsChartsUrl = () => {
    const queryParams = new URLSearchParams({
      scheduleType: medicationTrackingData.scheduleType,
    });
    if (medicationTrackingData.scheduleId) {
      queryParams.append("scheduleId", medicationTrackingData.scheduleId);
    }
    return `/patients/${id}/${patientProfilePageState.numberOfDays}/trackingHistory?${queryParams}`;
  };

  return (
    <Box
      sx={{
        padding: "24px 32px",
        backgroundColor: "rgba(247, 248, 251, 1)",
        borderRadius: "8px",
        border: "1px solid rgba(213, 219, 227, 1)",
        display: "flex",
        justifyContent: "space-between",
        gap: "32px",
        ...sx,
      }}
    >
      <Box>
        <TextHeader sx={{ marginBottom: "8px", fontSize: "24px" }}>{medicationTrackingData.medicationName}</TextHeader>
        <TextLabel sx={{ color: "rgba(78, 93, 105, 1)", fontWeight: 700 }}>{description}</TextLabel>
        {displayConditionName()}
        {isDevice && medicationTrackingData.dosageManagerInformation && (
          <DoserInformation dosageManagerInformation={medicationTrackingData.dosageManagerInformation} initiallyScheduledDose={initiallyScheduledDose} />
        )}
      </Box>
      <Box
        sx={{
          minWidth: isDevice ? "517px" : "644px",
          display: "flex",
          gap: "16px",
          alignItems: "flex-start",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <TakenDosesCard doseSourceText={isDevice} value={medicationTrackingData.summary.taken} limit={medicationTrackingData.summary.total} />
        <MissedDosesCard doseSourceText={isDevice} value={medicationTrackingData.summary.missed} limit={medicationTrackingData.summary.total} />
        {!isDevice && <SkippedDosesCard value={medicationTrackingData.summary.skipped} limit={medicationTrackingData.summary.total} />}
        <DefaultDosesCard text={isDevice ? t("not-synchronized") : t("no-data")} value={medicationTrackingData.summary.noData} limit={medicationTrackingData.summary.total} />
        <Link to={observationsChartsUrl()}>
          <DefaultSecondaryButton sx={{ marginLeft: "34px" }}>{t("view-details")}</DefaultSecondaryButton>
        </Link>
      </Box>
    </Box>
  );
};

export default TrackingResults;
