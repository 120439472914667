import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface InfoIconProps {
  sx?: SxProps;
}

const InfoBlackIcon = ({ sx }: InfoIconProps) => <DefaultIcon src={"infoBlack.svg"} sx={{ width: "16px", height: "16px", ...sx }} />;

export default InfoBlackIcon;
