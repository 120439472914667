import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import CalendarBlankIcon from "./icons/CalendarBlankIcon";
import { dayjsDate } from "../util/date";

interface DefaultDatePickerProps {
  label?: string;
  disabled?: boolean;
  defaultValue?: Date;
  value?: Date;
  sx?: SxProps;
}

const DefaultDatePicker = ({ label, defaultValue, value, disabled, sx }: DefaultDatePickerProps) => {
  return (
    <>
      {label && <DefaultText sx={{ marginBottom: "8px" }}>{label}</DefaultText>}
      <DatePicker
        disabled={disabled}
        defaultValue={defaultValue && dayjsDate(defaultValue)}
        value={value && dayjsDate(value)}
        slots={{
          openPickerIcon: CalendarBlankIcon,
        }}
        sx={{
          width: "370px",
          backgroundColor: disabled ? "rgba(222, 227, 233, 1)" : "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          "& .MuiInputBase-input": {
            height: "24px",
            padding: "12px 16px",
          },
          "& .MuiButtonBase-root": {
            paddingRight: "14px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
          ...sx,
        }}
      />
    </>
  );
};

export default DefaultDatePicker;
