import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { invitationApi } from "../api/InvitationApi";
import { InvitationData } from "../model/invitationData.model";
import { useAppState } from "../state/AppState";
import HrnValidation from "../components/HrnValidation";
import { withLoader } from "../hoc/withLoader";
import { getErrorState } from "../state/ErrorState";
import ExpiredInvitation from "../components/ExpiredInvitation";
import { observer } from "mobx-react-lite";
import { userApi } from "../api/UserApi";
import { HcpUserWithHealthcareProvider } from "../model/user.model";

const LoadingHrnValidation = withLoader(HrnValidation);

const NewInvitationPage = () => {
  const [accessData, setAccessData] = useState<InvitationData>();
  const [expiredInvitation, setExpiredInvitation] = useState<boolean>(false);
  const { user } = useAppState();
  const errorState = getErrorState();

  useEffect(() => {
    const getData = async () => {
      if (user.invitationId && user.invitationToken) {
        const response = await invitationApi.getAccessData(user.invitationId, user.invitationToken);
        if (response.ok) {
          const data = response.data as InvitationData;
          const meResponse = await userApi.me({ showLoginOnRefreshATFail: false });
          if (meResponse.ok && data.email !== (meResponse.data as HcpUserWithHealthcareProvider).email) {
            setExpiredInvitation(true);
          } else {
            setAccessData(data);
            user.newPatientFirstName = data.patientFirstName;
            user.newPatientLastName = data.patientLastName;
            user.email = data.email;
          }
        } else if (response.error.code === 403) {
          setExpiredInvitation(true);
        } else {
          errorState.code = response.error.code;
        }
      } else {
        errorState.code = 404;
      }
    };
    getData();
  }, [errorState, user]);

  return (
    <Box sx={{ paddingTop: "80px" }}>
      {expiredInvitation ? (
        <ExpiredInvitation />
      ) : (
        <LoadingHrnValidation accessData={accessData as InvitationData} isLoading={accessData === undefined}>
          <Box sx={{ height: 417, width: "600px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit" }} />
          </Box>
        </LoadingHrnValidation>
      )}
    </Box>
  );
};

export default observer(NewInvitationPage);
