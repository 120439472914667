import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextHeader from "./TextHeader";
import DefaultText from "./DefaultText";
import PatientsListImage from "./PatientsListImage";

const EmptyPatientsList = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginTop: "35px",
        height: "480px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        border: "1px solid rgba(213, 219, 227, 1)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PatientsListImage />
      <TextHeader sx={{ fontSize: "24px", paddingTop: "32px" }}>{t("patient-list")}</TextHeader>
      <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", paddingTop: "8px" }}>{t("patients-will-appear")}</DefaultText>
    </Box>
  );
};

export default EmptyPatientsList;
