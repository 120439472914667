import { FC } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CheckCircleIcon from "./icons/CheckCircleIcon";
import DefaultText from "./DefaultText";
import { observer } from "mobx-react-lite";
import { useAppState } from "../state/AppState";
import { Notification } from "../state/NotificationState";

const NotificationProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { notification } = useAppState();

  const handleClose = (notificationItem: Notification) => {
    notification.removeNotification(notificationItem);
  };

  return (
    <>
      {children}
      {notification.list.map((notification, index) => (
        <Snackbar
          key={index}
          open={true}
          autoHideDuration={5000}
          onClose={() => handleClose(notification)}
          sx={{
            "&.MuiSnackbar-root": {
              top: `calc(170px + (100px * ${index}))`,
              right: "5%",
            },
            boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={() => handleClose(notification)}
            severity="success"
            sx={{
              width: "430px",
              padding: "16px",
              background: "rgba(218, 255, 205, 1)",
              color: "rgba(43, 142, 8, 1)",
              border: "1px solid rgba(72, 197, 29, 1)",
              borderRadius: "8px",
              "& .MuiAlert-icon": {
                padding: "0px",
                mr: "8px",
              },
              "& .MuiAlert-message": {
                padding: "0px",
              },
              "& .MuiAlert-action": {
                padding: "0px",
                mt: "-7px",
              },
            }}
            icon={<CheckCircleIcon />}
          >
            <DefaultText sx={{ fontSize: "18px", lineHeight: "24px", color: "inherit", fontWeight: 700, paddingBottom: "8px" }}>{notification.title}</DefaultText>
            <DefaultText sx={{ color: "inherit", marginTop: notification.title ? "" : "-4px" }}>{notification.message}</DefaultText>
          </MuiAlert>
        </Snackbar>
      ))}
    </>
  );
};

export default observer(NotificationProvider);
