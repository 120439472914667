import { HealthcareProvider } from "./healthcareProvider.model";

export interface Guardian {
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: boolean;
}

export interface RegistrationDto {
  invitationId: string;
  registrationToken: string;
  firstName: string;
  lastName: string;
  password: string;
  specialty: string;
  providerId?: string;
  practiceName?: string;
  practiceLocation?: string;
  acceptedTermsAndConditions: boolean;
}

export interface HcpUserWithHealthcareProvider extends Guardian {
  uuid: string;
  specialty: string;
  healthcareProvider?: HealthcareProvider;
  practiceName?: string;
  practiceLocation?: string;
}

export enum UserRole {
  ADMIN_GUARDIAN = "ADMIN_GUARDIAN",
  GUARDIAN = "GUARDIAN",
  TEACHER = "TEACHER",
  HCP = "HCP",
}

export interface HcpUpdateDto {
  firstName: string;
  lastName: string;
  specialty: string;
  providerId?: string;
  practiceName?: string;
  practiceLocation?: string;
  hrnMigration?: boolean;
}

export interface CareTeamMember {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  specialty?: string;
  teacherProfession?: string;
  relationType?: string;
}
