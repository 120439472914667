import { useTranslation } from "react-i18next";
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Skeleton } from "@mui/material";
import DefaultText from "./DefaultText";
import { useAppState } from "../state/AppState";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { patientApi } from "../api/PatientApi";
import { observer } from "mobx-react-lite";
import CaretDownIconViolet from "./icons/CaretDownIconViolet";
import { MedicationScheduleDto, ScheduleType } from "../model/report.model";

interface MedicationSchedule {
  scheduleType: ScheduleType | string;
  scheduleId: string;
}

const MedicationScheduleSwitcher = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { patientProfilePageState } = useAppState();
  const [selectedSchedule, setSelectedSchedule] = useState<MedicationSchedule>({ scheduleType: "", scheduleId: "" });
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medicationSchedules, setMedicationSchedules] = useState<MedicationScheduleDto[]>([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const result = await patientApi.getSchedules(id as string, patientProfilePageState.timeRangeInput);
      if (result.ok && result.data) {
        setMedicationSchedules(result.data);
      }
      setIsLoading(false);
    };
    if (patientProfilePageState.numberOfDays) {
      getData();
    }
  }, [id, patientProfilePageState, patientProfilePageState.timeRangeInput]);

  useEffect(() => {
    const scheduleId = searchParams.get("scheduleId");
    const scheduleType = searchParams.get("scheduleType");
    if (scheduleId || scheduleType) {
      setSelectedSchedule({ scheduleType: scheduleType as string, scheduleId: scheduleId as string });
    } else if (medicationSchedules && medicationSchedules.length > 0) {
      setSelectedSchedule({ scheduleType: medicationSchedules[0].scheduleType, scheduleId: medicationSchedules[0].uuid || "" });
    }
  }, [location, medicationSchedules, searchParams]);

  const handleScheduleChange = (event: SelectChangeEvent) => {
    const scheduleId = event.target.value;
    setSelectedSchedule((prevSchedule) => {
      const schedule = medicationSchedules.find((scheduleData) => scheduleData.uuid === scheduleId || scheduleData.scheduleType === scheduleId);
      if (schedule) {
        setSearchParams((prevParams) => {
          if (prevParams && typeof prevParams === "object") {
            prevParams.set("scheduleType", schedule.scheduleType as string);
            if (schedule.uuid) {
              prevParams.set("scheduleId", schedule.uuid);
            } else {
              prevParams.delete("scheduleId");
            }
          }
          return prevParams;
        });

        return { scheduleType: schedule.scheduleType, scheduleId: schedule.uuid || "" };
      }

      return prevSchedule;
    });
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ height: 121 }}>
          <Skeleton variant="rounded" sx={{ height: "inherit" }} />
        </Box>
      )}
      {!isLoading && medicationSchedules && medicationSchedules.length > 0 && (
        <Box
          sx={{
            position: "relative",
            padding: "16px",
            borderRadius: "7px 7px 0px 0px",
            border: "1px solid rgba(213, 219, 227, 1)",
            backgroundColor: "rgba(247, 248, 251, 1)",
            marginBottom: "-4px",
          }}
        >
          <FormControl sx={{ padding: "14px 16px", width: "460px", backgroundColor: "rgba(255, 255, 255, 1)", borderRadius: "8px", border: "1px solid rgba(213, 219, 227, 1)" }}>
            <Select
              value={selectedSchedule.scheduleId || selectedSchedule.scheduleType}
              onChange={handleScheduleChange}
              IconComponent={CaretDownIconViolet}
              variant="standard"
              disableUnderline={true}
              sx={{
                width: "inherit",
                fontWeight: 700,
                fontSize: "22px",
                gap: "20px",
                "& .MuiSelect-icon": { marginRight: "8px", marginTop: "-3px" },
                "& .MuiInput-input:focus": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 450, marginTop: "14px" } } }}
            >
              {medicationSchedules.map((schedule, index) => (
                <MenuItem
                  key={index}
                  value={schedule.uuid ? schedule.uuid : schedule.scheduleType}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    "&:hover": { backgroundColor: "rgba(232, 229, 255, 1)" },
                    "&.Mui-selected": { backgroundColor: "rgba(255, 255, 255, 1) !important" },
                    "&.Mui-selected:hover": { backgroundColor: "rgba(232, 229, 255, 1) !important" },
                    borderTop: `${index > 0 ? "1px solid rgba(231, 234, 238, 1)" : "none"}`,
                    padding: "10px 16px 18px 16px",
                    width: "444px",
                    marginX: "24px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <DefaultText
                      sx={{ fontSize: "1.1em", lineHeight: "32px", fontWeight: "inherit", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "290px" }}
                    >
                      {schedule.medicationDetails.medicationName}
                    </DefaultText>
                    <DefaultText sx={{ fontSize: "1.1em", lineHeight: "32px", fontWeight: "inherit" }}>
                      &nbsp;- {schedule.medicationDetails.strength} {schedule.medicationDetails.doseUnit}
                    </DefaultText>
                  </Box>
                  <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)", fontWeight: "inherit" }}>
                    {schedule.medicationDetails.dosageForm.toLowerCase()}, {schedule.medicationDetails.strength} {schedule.medicationDetails.doseUnit}
                    {schedule.dosesPerDay ? `, ${schedule.dosesPerDay} ${schedule.dosesPerDay <= 1 ? t("dose") : t("doses")} ${t("scheduled-every-day")}` : ""}
                  </DefaultText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default observer(MedicationScheduleSwitcher);
