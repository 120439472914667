import { useTranslation } from "react-i18next";
import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import DefaultTooltip from "./DefaultTooltip";

interface TextListProps {
  items: string[];
  limit?: number;
  sx?: SxProps;
}

const TextList = ({ items, limit = 3, sx }: TextListProps) => {
  const { t } = useTranslation();
  if (items.length > limit) {
    return (
      <DefaultTooltip
        title={
          <Box sx={{ padding: "10px" }}>
            {items.map((item, index) => {
              return <DefaultText key={index}>{`${index + 1}. ${item}`}</DefaultText>;
            })}
          </Box>
        }
      >
        <DefaultText sx={{ color: "rgba(145, 84, 201, 1)", cursor: "pointer", ...sx }}>
          {items.slice(0, limit).join(", ") + ` (+ ${items.length - limit} ${t("more)")}`}
        </DefaultText>
      </DefaultTooltip>
    );
  } else {
    return <DefaultText>{items.join(", ")}</DefaultText>;
  }
};

export default TextList;
