import { makeAutoObservable } from "mobx";
import { TranslatedDictionaryEventType, TranslationItem } from "../model/dictionary.model";

export class DictionaryState {
  private _eventTypes: TranslatedDictionaryEventType[] = [];
  private _hcpEventGroups: TranslationItem[] = [];

  constructor() {
    makeAutoObservable(this);
    this.loadUserFromLocalStorage();
  }

  set eventTypes(eventTypes: TranslatedDictionaryEventType[]) {
    this._eventTypes = eventTypes;
    if (eventTypes) {
      localStorage.setItem("dictionary.eventTypes", JSON.stringify(eventTypes));
    } else {
      localStorage.removeItem("dictionary.eventTypes");
    }
  }

  get eventTypes(): TranslatedDictionaryEventType[] {
    return this._eventTypes;
  }

  set hcpEventGroups(hcpEventGroups: TranslationItem[]) {
    if (hcpEventGroups) {
      localStorage.setItem("dictionary.hcpEventGroups", JSON.stringify(hcpEventGroups));
    } else {
      localStorage.removeItem("dictionary.hcpEventGroups");
    }
    this._hcpEventGroups = hcpEventGroups;
  }

  get hcpEventGroups(): TranslationItem[] {
    return this._hcpEventGroups;
  }

  loadUserFromLocalStorage() {
    const eventTypes = localStorage.getItem("dictionary.eventTypes");
    const hcpEventGroups = localStorage.getItem("dictionary.hcpEventGroups");
    if (eventTypes) {
      this._eventTypes = JSON.parse(eventTypes);
    }
    if (hcpEventGroups) {
      this._hcpEventGroups = JSON.parse(hcpEventGroups);
    }
  }
}
