import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface IconProps {
  sx?: SxProps;
}

const SettingsIcon = ({ sx }: IconProps) => <DefaultIcon src={"settings.svg"} sx={{ width: "18px", height: "18px", ...sx }} />;

export default SettingsIcon;
