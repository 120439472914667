import { Select, SelectChangeEvent, SxProps, MenuItem, Box, FormControl } from "@mui/material";
import DefaultText from "./DefaultText";
import CaretDownIcon from "./icons/CaretDownIcon";
import WarningIcon from "./icons/Warning";

interface DefaultSelectProps<T extends Data> {
  label?: string;
  value?: string;
  options: T[];
  selectHandler?: (value: string) => void;
  placeholder?: string;
  renderOption?: (option: T) => React.ReactElement;
  renderValue?: React.ReactNode;
  displayEmpty?: boolean;
  error?: boolean;
  errorMsg?: string;
  sx?: SxProps;
}

export interface Data {
  label: string;
  value: string | number;
}

const DefaultSelect = <T extends Data>({
  label,
  value,
  options,
  selectHandler,
  renderOption,
  renderValue,
  placeholder,
  displayEmpty,
  error,
  errorMsg,
  sx,
}: DefaultSelectProps<T>) => {
  const handleSelectChange = (event: SelectChangeEvent) => {
    if (selectHandler) {
      selectHandler(event.target.value);
    }
  };

  const onBlur = () => {
    if (selectHandler && !value) {
      selectHandler("");
    }
  };

  return (
    <FormControl sx={{ width: "320px", ...sx }}>
      {label && <DefaultText sx={{ marginBottom: "8px" }}>{label}</DefaultText>}
      <Select
        value={value}
        onChange={handleSelectChange}
        onBlur={onBlur}
        IconComponent={CaretDownIcon}
        error={error}
        displayEmpty={displayEmpty}
        renderValue={
          renderValue || placeholder
            ? (selected) => {
                if (selected.length === 0 && placeholder) {
                  return <DefaultText sx={{ color: "rgba(146, 156, 166, 1)" }}>{placeholder}</DefaultText>;
                } else if (selected.length > 1 && renderValue) {
                  return renderValue;
                } else {
                  return <DefaultText>{selected}</DefaultText>;
                }
              }
            : undefined
        }
        sx={{
          lineHeight: "16px",
          color: "rgba(146, 156, 166, 1)",
          borderColor: "rgba(179, 187, 196, 1)",
          paddingTop: "8px",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 1)",
          "& .MuiInput-input:focus": { backgroundColor: "rgba(255, 255, 255, 1)" },
          "& .MuiSelect-icon": { marginRight: "8px", marginTop: "-4px" },
          width: "inherit",
          height: "48px",
        }}
      >
        {options?.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              "&:hover": { backgroundColor: "rgba(232, 229, 255, 1)" },
              "&.Mui-selected": { backgroundColor: "rgba(255, 255, 255, 1) !important" },
              "&.Mui-selected:hover": { backgroundColor: "rgba(232, 229, 255, 1) !important" },
            }}
          >
            {renderOption ? renderOption(item as any) : <DefaultText>{item.label}</DefaultText>}
          </MenuItem>
        ))}
      </Select>
      {errorMsg && (
        <Box sx={{ paddingTop: "8px", display: "flex" }}>
          <WarningIcon sx={{ paddingRight: "7px" }} />
          <DefaultText sx={{ fontSize: "12px", color: "rgba(221, 49, 49, 1)" }}>{errorMsg}</DefaultText>
        </Box>
      )}
    </FormControl>
  );
};

export default DefaultSelect;
