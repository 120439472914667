import { Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import { useEffect } from "react";
import { useAppState } from "../state/AppState";
import Link from "../components/Link";
import DefaultText from "../components/DefaultText";
import { Page } from "../state/NavigationState";
import FhirErrorIcon from "../components/icons/FhirErrorIcon";
import { SUPPORT_EMAIL } from "./SettingsPage";

const ERROR_MESSAGES = ["error.fhir.incorrectHrn", "error.fhir.notPartOfCareTeam", "error.fhir.wrongHealthcareProvider", "error.fhir.other"];

const FhirErrorPage = () => {
  const { t } = useTranslation();
  const { navigation } = useAppState();

  useEffect(() => {
    navigation.backgroundColor = "#F0F4FA";
    navigation.activePage = Page.MY_PATIENTS;
  }, [navigation]);

  const displayErrors = () => {
    return ERROR_MESSAGES.map((msg: string, index: number) => (
      <DefaultText key={msg} sx={{ color: "rgba(78, 93, 105, 1)", marginBottom: "24px" }}>
        {`${index + 1}. `}
        <Trans
          i18nKey={msg}
          components={{ a: <a href={`mailto:${SUPPORT_EMAIL}`} style={{ fontWeight: 700, color: "rgba(145, 84, 201, 1)", textDecoration: "underline", cursor: "pointer" }}></a> }}
          values={{ email: SUPPORT_EMAIL }}
        />
      </DefaultText>
    ));
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(231, 234, 238, 1)",
          borderRadius: "8px",
          paddingY: "80px",
        }}
      >
        <FhirErrorIcon />
        <DefaultText sx={{ fontSize: "36px", fontWeight: 700, lineHeight: "40px", marginBottom: "24px", marginTop: "36px" }}>{t("error.fhir.title")}</DefaultText>
        <DefaultText sx={{ fontSize: "21px", marginBottom: "32px" }}>{t("error.fhir.subtitle")}</DefaultText>
        <Box sx={{ width: "645px" }}>{displayErrors()}</Box>
        <Link to="/patients">
          <DefaultPrimaryButton sx={{ marginTop: "8px", padding: "10px 64px", height: "36px" }}>{t("close")}</DefaultPrimaryButton>
        </Link>
      </Box>
    </Box>
  );
};

export default FhirErrorPage;
