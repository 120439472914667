import { unstable_useBlocker as useBlocker, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { t } from "i18next";
import DefaultModal from "./DefaultModal";
import LeaveConfirmation from "./LeaveConfirmation";

interface LeaveConfirmationModalProps {
  active: boolean;
}

const LeaveConfirmationModal = ({ active }: LeaveConfirmationModalProps) => {
  const navigate = useNavigate();
  const blocker = useBlocker(active);

  const handleClose = () => {
    blocker.reset?.();
  };

  const handleConfirm = () => {
    blocker.proceed?.();
  };

  useEffect(() => {
    if (!active && blocker.location) {
      navigate(blocker.location.pathname);
    }
  }, [active, blocker, navigate]);

  return (
    <DefaultModal modalTitle={t("leaving-the-page")} isOpen={blocker.state === "blocked"} onClose={() => handleClose()}>
      <LeaveConfirmation handleClose={() => handleClose()} handleLeave={() => handleConfirm()} />
    </DefaultModal>
  );
};

export default LeaveConfirmationModal;
