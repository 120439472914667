import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultModal from "./DefaultModal";
import DoseChangeHistory from "./DoseChangeHistory";
import TextLabel from "./TextLabel";
import InfoIcon from "./icons/InfoIcon";
import { useState } from "react";
import { DoseChangeHistoryEntry } from "../model/report.model";
import { useAppState } from "../state/AppState";
import { observer } from "mobx-react-lite";

interface DoseChangeInformationProps {
  doseChangeHistory: DoseChangeHistoryEntry[];
  initiallyScheduledDose: string;
}

const DoseChangeInformation = ({ doseChangeHistory, initiallyScheduledDose }: DoseChangeInformationProps) => {
  const { t } = useTranslation();
  const { patientProfilePageState } = useAppState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        background: "rgba(255, 255, 255, 1)",
        border: "1px solid rgba(179, 187, 196, 1)",
        borderRadius: "8px",
        padding: "8px 16px",
        display: "flex",
        flexWrap: "wrap",
        gap: "4px",
      }}
    >
      <InfoIcon sx={{ width: "16px", height: "16px" }} />
      <TextLabel sx={{ color: "rgba(78, 93, 105, 1)" }}>{t("dose-change")}</TextLabel>
      <TextLabel sx={{ color: "rgba(78, 93, 105, 1)", fontWeight: 700 }}>
        {doseChangeHistory.length} {doseChangeHistory.length === 1 ? t("time") : t("times")}
      </TextLabel>
      <TextLabel sx={{ color: "rgba(78, 93, 105, 1)" }}>
        {t("in-last")} {patientProfilePageState.numberOfDays} {t("days")}.
      </TextLabel>
      <Link component="button" onClick={handleOpenModal}>
        <TextLabel sx={{ color: "rgba(145, 84, 201, 1)", fontWeight: 700, textDecoration: "underline" }}>{t("check-dose-change-history")} </TextLabel>
      </Link>
      <DefaultModal modalTitle={t("dose-change-history")} isOpen={isModalOpen} onClose={handleCloseModal}>
        <DoseChangeHistory doseChangeHistory={doseChangeHistory} handleClose={handleCloseModal} initiallyScheduledDose={initiallyScheduledDose} />
      </DefaultModal>
    </Box>
  );
};

export default observer(DoseChangeInformation);
