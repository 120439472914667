import { CareTeamMember, HcpUserWithHealthcareProvider, UserRole } from "./user.model";

export enum RatingScalesFormType {
  ADHD_VANDERBILT_BASELINE = "ADHD_VANDERBILT_BASELINE",
  ADHD_VANDERBILT_FOLLOW_UP = "ADHD_VANDERBILT_FOLLOW_UP",
}

export enum RelationType {
  PARENT = "PARENT",
  LEGAL_GUARDIAN = "LEGAL_GUARDIAN",
  OTHER = "OTHER",
}

export enum TeacherProfession {
  TEACHER = "TEACHER",
  EDUCATIONAL_RESOURCE_ASSISTANT = "EDUCATIONAL_RESOURCE_ASSISTANT",
  SPECIAL_EDUCATION_TEACHER = "SPECIAL_EDUCATION_TEACHER",
  SCHOOL_NURSE = "SCHOOL_NURSE",
  SCHOOL_PSYCHOLOGIST = "SCHOOL_PSYCHOLOGIST",
  GUIDANCE_COUNSELOR = "GUIDANCE_COUNSELOR",
}

export interface CreateRatingScalesRequestDto {
  respondentId: string;
  type: RatingScalesFormType;
}

export interface RatingScalesRespondent {
  id: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  teacherProfession?: TeacherProfession;
  relationType?: RelationType;
}

export enum RatingScalesFormStatus {
  IN_PROGRESS = "IN_PROGRESS",
  REQUESTED = "REQUESTED",
  COMPLETED = "COMPLETED",
  REVOKED = "REVOKED",
}

export interface RatingScalesRespondent extends CareTeamMember {
  id: string;
}

export type RatingScalesHcpDto = Pick<HcpUserWithHealthcareProvider, "firstName" | "lastName" | "uuid">;

export type RatingScalesRequestorDto = RatingScalesHcpDto & { active: boolean };

export type RatingScalesRespondentDto = Pick<CareTeamMember, "firstName" | "lastName" | "role" | "email"> & { active: boolean; id: string };

export interface RatingScalesRevocationDetailsDto {
  revocationTimestamp: number;
  revokedBy?: RatingScalesHcpDto;
}
export interface RatingScalesRequest {
  id: string;
  type: RatingScalesFormType;
  requestor: RatingScalesRequestorDto;
  respondent: RatingScalesRespondentDto;
  status: RatingScalesFormStatus;
  creationTimestamp: number;
  submissionTimestamp?: number;
  revocationDetails?: RatingScalesRevocationDetailsDto;
}

export interface RatingScalesFormDetails {
  scoring: RatingScalesCalculationResult[];
  sections: RatingScalesFormSection[];
}

export enum RatingScalesFormQuestionType {
  CODING = "coding",
  STRING = "string",
  TEXT = "text",
  DATE = "date",
  INTEGER = "integer",
}

export interface RatingScalesFormAnswer {
  text?: string;
  integer?: number;
}

export interface RatingScalesFormAnsweredQuestion {
  id: string;
  type: RatingScalesFormQuestionType;
  order?: number;
  text: string;
  explainer?: string;
  answer?: RatingScalesFormAnswer;
}

export interface RatingScalesFormSection {
  id: string;
  order: number;
  name: string;
  directions?: string;
  firstQuestionIndex?: number;
  lastQuestionIndex?: number;
  questions: RatingScalesFormAnsweredQuestion[];
}

export interface EvaluationResult {
  expectedScores: number[];
  isRange: boolean;
  questionIndexes: number[];
  count: number;
}

export interface RatingScalesCalculationResult {
  condition: string;
  conditionName: string;
  suspected: boolean;
  rules: string[];
  evaluationResults: EvaluationResult[];
}
