import { useTranslation } from "react-i18next";
import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import CheckIcon from "./icons/CheckIcon";

interface RegistrationStepperProps {
  step: number;
  sx?: SxProps;
}

const RegistrationStepper = ({ step, sx }: RegistrationStepperProps) => {
  const { t } = useTranslation();
  const list = [t("terms-&-conditions"), t("credentials"), t("personal-profile")];
  return (
    <Box sx={{ display: "flex", gap: "8px", paddingBottom: "40px", ...sx }}>
      {list.map((item, index) => {
        return (
          <Box key={index} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                width: "24px",
                height: "24px",
                color: step === index + 1 ? "rgba(255, 255, 255, 1)" : "rgba(78, 93, 105, 1)",
                backgroundColor: step === index + 1 ? "rgba(145, 84, 201, 1)" : step > index ? "rgba(72, 197, 29, 1)" : "rgba(222, 227, 233, 1)",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
              }}
            >
              {step - 1 > index ? <CheckIcon /> : index + 1}
            </Box>
            <DefaultText sx={{ fontSize: "12px" }}>{item}</DefaultText>
            {list.length > index + 1 && <Box sx={{ width: "16px", height: "2px", backgroundColor: "rgba(222, 227, 233, 1)" }} />}
          </Box>
        );
      })}
    </Box>
  );
};

export default RegistrationStepper;
