import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import { Box, SxProps } from "@mui/material";
import EmptyTrackingResult from "./EmptyTrackingResult";
import { EventCategory, EventDto } from "../model/event.model";
import EventsGroup from "./EventsGroup";
import { useAppState } from "../state/AppState";

interface PatientEventsProps {
  patientEvents: EventDto[];
  sx?: SxProps;
}

const PatientEvents = ({ patientEvents, sx }: PatientEventsProps) => {
  const { t } = useTranslation();
  const { dictionary } = useAppState();
  const allEventsCount = patientEvents.reduce((acc, event) => acc + event.daysLoggedCount, 0);

  const translatedPatientEvents = patientEvents.map((eventGroup) => {
    const translatedHcpEventGroup = dictionary.hcpEventGroups.find((hcpEventGroup) => hcpEventGroup.key === eventGroup.hcpEventGroup)?.text;
    return {
      ...eventGroup,
      hcpEventGroup: translatedHcpEventGroup || eventGroup.hcpEventGroup,
    };
  });

  const sortedPatientEvents = translatedPatientEvents.sort((a, b) => {
    if (a.daysLoggedCount < b.daysLoggedCount) {
      return 1;
    }
    if (a.daysLoggedCount > b.daysLoggedCount) {
      return -1;
    }
    return a.hcpEventGroup.localeCompare(b.hcpEventGroup);
  });

  const goodEvents = sortedPatientEvents.filter((event) => event.category === EventCategory.GOOD_DAY);
  const badEvents = sortedPatientEvents.filter((event) => event.category === EventCategory.BAD_DAY);

  return (
    <Box sx={{ paddingBottom: "24px" }}>
      {patientEvents.length === 0 || allEventsCount === 0 ? (
        <EmptyTrackingResult header={t("events")} sx={{ height: "164px", ...sx }} />
      ) : (
        <Box sx={{ padding: "24px 35px", backgroundColor: "rgba(247, 248, 251, 1)", border: "1px solid rgba(213, 219, 227, 1)", borderRadius: "8px", ...sx }}>
          <TextHeader sx={{ fontSize: "24px", paddingBottom: "24px" }}>{t("events")}</TextHeader>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "9px", rowGap: "24px" }}>
            <EventsGroup eventCategory={t("good-things")} patientEvents={goodEvents} />
            <EventsGroup eventCategory={t("bad-things")} patientEvents={badEvents} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PatientEvents;
