import { Outlet, useSearchParams, ScrollRestoration } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { userApi } from "../api/UserApi";
import { Box } from "@mui/material";
import { ErrorBoundary } from "../ErrorBoundary";
import Footer from "../Footer";
import Logo from "../components/Logo";
import HcpImageDescription from "../components/HcpImageDescription";
import ErrorPage from "../pages/ErrorPage";
import { getErrorState } from "../state/ErrorState";
import LinearLoader from "../components/LinearLoader";
import ExpiredResetPassword from "../components/ExpiredResetPassword";
import { HcpUserWithHealthcareProvider } from "../model/user.model";

const ResetPasswordLayout = () => {
  const [searchParams] = useSearchParams();
  const errorState = getErrorState();
  const [loading, setLoading] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const token = searchParams.get("token")?.replace(/ /g, "+");
  const email = searchParams.get("email")?.replace(/ /g, "+");

  useEffect(() => {
    const resetPasswordVerify = async () => {
      if (token && email) {
        const meResponse = await userApi.me({ showLoginOnRefreshATFail: false });
        if (meResponse.ok && email !== (meResponse.data as HcpUserWithHealthcareProvider).email) {
          setLoading(false);
          return;
        } else {
          const verify = await userApi.resetPasswordVerify(email, token);
          if (verify.ok) {
            setVerified(true);
          } else if (verify.error.code === 403) {
            setLoading(false);
            return;
          } else {
            errorState.code = verify.error.code;
          }
        }
      }
      setLoading(false);
    };
    resetPasswordVerify();
  }, [token, email, errorState]);

  return (
    <>
      {errorState.code !== null ? (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#F0F4FA",
            paddingX: "5%",
            overflowY: "auto",
          }}
        >
          <Logo sx={{ width: "201px", height: "42px", paddingTop: "60px" }} />
          <ErrorPage code={errorState.code as number} />
          <Footer privacyPolicy sx={{ paddingTop: "40px" }} />
        </Box>
      ) : (
        <ErrorBoundary>
          <Box sx={{ overflowY: "auto", paddingX: "40px", paddingTop: "40px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            {loading ? (
              <>
                <Logo sx={{ width: "201px", height: "42px", paddingTop: "60px", paddingLeft: "70px" }} />
                <LinearLoader header="please-wait" text="checking-request" />
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between", columnGap: "130px" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: "60px", paddingLeft: "70px" }}>
                    <Box sx={{ minWidth: "370px" }}>
                      <Logo sx={{ width: "201px", height: "42px" }} />
                      {!verified ? <ExpiredResetPassword /> : <Outlet />}
                    </Box>
                  </Box>
                  <HcpImageDescription />
                </Box>
                <Footer privacyPolicy sx={{ marginTop: "-40px", paddingLeft: "70px", paddingBottom: "40px" }} />
              </>
            )}
          </Box>
        </ErrorBoundary>
      )}
      <ScrollRestoration />
    </>
  );
};

export default observer(ResetPasswordLayout);
