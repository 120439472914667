import { Autocomplete, Box, CircularProgress, InputAdornment, SxProps, TextField } from "@mui/material";
import DefaultText from "./DefaultText";
import { useTranslation } from "react-i18next";
import SearchIcon from "./icons/SearchIcon";
import { useEffect, useState } from "react";
import { HealthcareProvider } from "../model/healthcareProvider.model";
import WarningIcon from "./icons/Warning";
import DefaultTooltip from "./DefaultTooltip";

interface HealthcareProviderAutocompleteProps {
  options: HealthcareProvider[];
  onChange: (value: HealthcareProvider | null) => void;
  onInputChange: (value: string) => void;
  loading: boolean;
  error: boolean;
  errorMsg: string;
  value?: HealthcareProvider;
  sx?: SxProps;
}

const HealthcareProviderAutocomplete = ({ options, onChange, onInputChange, loading, error, errorMsg, value, sx }: HealthcareProviderAutocompleteProps) => {
  const { t } = useTranslation();
  const [selectedHealthcareProvider, setSelectedHealthcareProvider] = useState<HealthcareProvider | null>(null);

  const handleOnChange = (value: HealthcareProvider | null) => {
    onChange(value);
    setSelectedHealthcareProvider(value);
  };

  const onBlur = () => {
    if (!selectedHealthcareProvider) {
      onInputChange("");
    }
  };

  useEffect(() => {
    if (value) {
      handleOnChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "390px", ...sx }}>
      <DefaultText sx={{ paddingBottom: "8px" }}>{t("healthcare-provider-practice")}</DefaultText>
      <Autocomplete
        value={selectedHealthcareProvider}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        onBlur={onBlur}
        options={options}
        onChange={(event, newValue) => {
          handleOnChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          onInputChange(newInputValue);
        }}
        loading={loading}
        renderOption={(props, option) => {
          if (option.id === "other") {
            return (
              <Box {...props} component="li" sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start !important" }}>
                <DefaultText sx={{ fontSize: "12px", paddingBottom: "3px", paddingTop: "8px" }}>{option.name}</DefaultText>
                <hr style={{ width: "100%", border: "1px solid rgba(230,234,239,1)", marginBottom: "-6px" }} />
              </Box>
            );
          } else {
            return (
              <Box {...props} component="li" sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start !important", margin: "4px 0px" }}>
                <DefaultText sx={{ fontSize: "12px", fontWeight: 700, paddingBottom: "4px" }}>{option.name}</DefaultText>
                <DefaultText sx={{ fontSize: "12px" }}>{option.location}</DefaultText>
                <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)" }}>
                  {t("NPI")} {option.npi}
                </DefaultText>
              </Box>
            );
          }
        }}
        renderInput={(params) => (
          <DefaultTooltip
            title={
              selectedHealthcareProvider !== null && (
                <Box sx={{ padding: "10px" }}>
                  <DefaultText sx={{ fontSize: "12px", fontWeight: 700, paddingBottom: "4px" }}>{selectedHealthcareProvider?.name}</DefaultText>
                  {selectedHealthcareProvider.id !== "other" && (
                    <>
                      <DefaultText sx={{ fontSize: "12px" }}>{selectedHealthcareProvider.location}</DefaultText>
                      <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)" }}>
                        {t("NPI")} {selectedHealthcareProvider.npi}
                      </DefaultText>
                    </>
                  )}
                </Box>
              )
            }
          >
            <TextField
              {...params}
              placeholder={t("start-typing-for-provider")}
              error={error}
              InputProps={{
                ...params.InputProps,
                style: { borderRadius: "8px", height: "48px", paddingTop: "7px", fontSize: "14px" },
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginRight: selectedHealthcareProvider ? "-50px" : "-24px", marginTop: "1px" }}>
                    {loading && <CircularProgress color="inherit" size={20} sx={{ marginRight: "10px" }} />}
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </DefaultTooltip>
        )}
        ListboxProps={{ style: { maxHeight: "240px" } }}
      />
      {errorMsg && (
        <Box sx={{ paddingTop: "8px", display: "flex" }}>
          <WarningIcon sx={{ paddingRight: "7px" }} />
          <DefaultText sx={{ fontSize: "12px", color: "rgba(221, 49, 49, 1)" }}>{errorMsg}</DefaultText>
        </Box>
      )}
    </Box>
  );
};

export default HealthcareProviderAutocomplete;
