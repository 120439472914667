import { getUserState } from "../../state/AppState";
import { Api, ApiResponse, RequestParams, ResponseHookFn, withErrorHandling } from "../Api";

const userState = getUserState();

export const refreshAccessTokenHook: ResponseHookFn = async (response: Response, requestParams: RequestParams, api: Api) => {
  if (response.status === 401 && requestParams.refreshAT) {
    const response: ApiResponse<void> = await api.post("users/refreshAccessToken", withErrorHandling({ refreshAT: false }));
    requestParams.retry = response.ok;
    if (!response.ok) {
      userState.isAuthorized = false;
      if (requestParams.showLoginOnRefreshATFail) {
        window.location.href = "/login";
      }
    }
  }
};
