import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";

interface LoggedItemProps {
  title: string;
  count: number;
  sx?: SxProps;
}

const LoggedItem = ({ title, count, sx }: LoggedItemProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(255, 255, 255, 1)",
        padding: "16px",
        border: "1px solid rgba(232, 229, 255, 1)",
        borderRadius: "8px",
        ...sx,
      }}
    >
      <DefaultText sx={{ fontSize: "14px", fontWeight: 700, paddingBottom: "8px" }}>{title}</DefaultText>
      <TextHeader>{count === 0 ? "-" : count}</TextHeader>
      <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)" }}>{t("times-logged")}</DefaultText>
    </Box>
  );
};

export default LoggedItem;
