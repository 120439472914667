import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { SxProps } from "@mui/material";
import { t } from "i18next";
import DefaultText from "./DefaultText";

interface DefaultRadioGroupProps {
  data: RadioGroupElement[];
  value: string;
  handleChange: (event: string) => void;
  sx?: SxProps;
}

export interface RadioGroupElement {
  label: string;
  value: string | number;
}

const DefaultRadioGroup = ({ data, value, handleChange, sx }: DefaultRadioGroupProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value as string);
  };

  return (
    <FormControl sx={{ ...sx }}>
      <DefaultText sx={{ fontSize: "16px", fontWeight: 700, lineHeight: "24px", marginBottom: "11px" }}>{t("select-form-type")}</DefaultText>
      <RadioGroup value={value} onChange={handleOnChange}>
        {data.map((item: RadioGroupElement) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={
              <Radio
                sx={{
                  color: "rgba(145, 84, 201, 1)",
                  paddingY: "5px",
                  paddingRight: "8px",
                  "&.Mui-checked": { color: "rgba(145, 84, 201, 1)" },
                  "& .MuiSvgIcon-root": { height: 16, width: 16 },
                }}
              />
            }
            label={<DefaultText sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: value === item.value ? 700 : 400 }}>{item.label}</DefaultText>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
export default DefaultRadioGroup;
