import { useTranslation } from "react-i18next";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import DefaultText from "./DefaultText";
import { useNavigate, useParams } from "react-router-dom";
import CaretDownIconViolet from "./icons/CaretDownIconViolet";
import { formatTimestamp } from "../util/date";
import { getUserState } from "../state/AppState";
import { RatingScalesRequest } from "../model/ratingScales.model";
import UserRoleChip from "./UserRoleChip";

const PLACEHOLDER = "PLACEHOLDER";
const MenuItemSX = (index = 0) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "&:hover": { backgroundColor: "rgba(232, 229, 255, 1)" },
  "&.Mui-selected": { backgroundColor: "rgba(255, 255, 255, 1) !important" },
  "&.Mui-selected:hover": { backgroundColor: "rgba(232, 229, 255, 1) !important" },
  borderTop: `${index > 0 ? "1px solid rgba(231, 234, 238, 1)" : "none"}`,
  padding: "10px 16px 18px 16px",
  marginX: "24px",
});

const RatingScaleFormSelect = ({
  ratingScales,
  value,
  name,
  label,
  onChange,
}: {
  ratingScales: RatingScalesRequest[];
  value: string;
  name: string;
  label?: string;
  onChange: (event: any) => void;
}) => {
  const { t } = useTranslation();
  return (
    <FormControl
      sx={{
        padding: "12px 16px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "8px",
        border: "1px solid rgba(213, 219, 227, 1)",
      }}
    >
      {!value && label ? <DefaultText sx={{ marginBottom: "-16px", zIndex: 1 }}>{label}</DefaultText> : null}
      <Select
        value={value || (label ? PLACEHOLDER : undefined)}
        onChange={onChange}
        IconComponent={CaretDownIconViolet}
        variant="standard"
        disableUnderline={true}
        disabled={ratingScales.length <= 1}
        name={name}
        sx={{
          display: "flex",
          width: "inherit",
          fontWeight: 700,
          fontSize: "22px",
          gap: "20px",
          "& .MuiSelect-icon": { marginRight: "8px", marginTop: "-3px" },
          "& .MuiInput-input:focus": {
            backgroundColor: "rgba(255, 255, 255, 1)",
          },
        }}
        MenuProps={{ PaperProps: { sx: { maxHeight: 450, marginTop: "14px" } } }}
      >
        {label && (
          <MenuItem key={ratingScales.length} value={PLACEHOLDER} sx={MenuItemSX(0)}>
            <DefaultText sx={{ lineHeight: "56px" }}>-</DefaultText>
          </MenuItem>
        )}
        {ratingScales.map((ratingScale, index) => (
          <MenuItem key={index} value={ratingScale.id} sx={MenuItemSX(label ? index + 1 : index)}>
            <Box sx={{ display: "flex", marginBottom: "4px", alignItems: "baseline" }}>
              <DefaultText>
                {t("completed-by")}{" "}
                <span style={{ fontWeight: "700" }}>
                  {ratingScale.respondent.firstName} {ratingScale.respondent.lastName}
                </span>
              </DefaultText>
              <UserRoleChip role={ratingScale.respondent.role} sx={{ marginLeft: "5px" }} />
            </Box>
            <DefaultText sx={{ fontSize: "12px" }}>{t(ratingScale.type.toLowerCase())}</DefaultText>
            {ratingScale.submissionTimestamp && (
              <DefaultText sx={{ fontSize: "12px", color: "#4E5D69" }}>
                {t("completed-on")} {formatTimestamp(ratingScale.submissionTimestamp, "MM/DD/YYYY")}
              </DefaultText>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const RatingScaleRequestDescription = ({ ratingScale, loggedUserUuid }: { ratingScale: RatingScalesRequest; loggedUserUuid: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <DefaultText sx={{ fontSize: "12px" }}>
        {t("requested-by")}
        {": "}
        {ratingScale.requestor.uuid === loggedUserUuid ? t("me") : `${ratingScale.requestor.firstName} ${ratingScale.requestor.lastName}`}
      </DefaultText>
      <DefaultText sx={{ fontSize: "12px" }}>
        {t("requested")}
        {": "}
        {formatTimestamp(ratingScale.creationTimestamp, "MM/DD/YYYY")}
      </DefaultText>
      <DefaultText sx={{ fontSize: "12px" }}>
        {t("respondent-email")}
        {": "}
        {ratingScale.respondent.email}
      </DefaultText>
    </>
  );
};

const RatingScalesSwitcher = ({ ratingScales }: { ratingScales: RatingScalesRequest[] }) => {
  const { t } = useTranslation();
  const { loggedUser } = getUserState();
  const { ratingScaleId = "", secondRatingScaleId = "", id } = useParams();
  const navigate = useNavigate();
  const selectedRatingScale = ratingScaleId ? ratingScales?.find((ratingScale) => ratingScale.id == ratingScaleId) : null;
  const secondSelectedRatingScale = secondRatingScaleId ? ratingScales?.find((ratingScale) => ratingScale.id == secondRatingScaleId) : null;

  const handleSelect = (event: any) => {
    const ratingScaleFormName = event.target.name;
    const selectValue = event.target.value;
    if (ratingScaleFormName === "ratingScale") {
      navigate(`/patients/${id}/ratingScales/${selectValue}/${secondRatingScaleId}`);
    } else if (ratingScaleFormName === "secondRatingScale") {
      const secondSelectValue = selectValue !== PLACEHOLDER ? selectValue : "";
      navigate(`/patients/${id}/ratingScales/${ratingScaleId}/${secondSelectValue}`);
    }
  };

  return (
    <Box sx={{ paddingBottom: "25px" }}>
      <Box
        sx={{
          display: "grid",
          gap: "24px",
          gridTemplateColumns: "repeat(2, 1fr)",
          position: "relative",
          padding: "16px",
          borderRadius: "7px 7px 0px 0px",
          border: "1px solid rgba(213, 219, 227, 1)",
          backgroundColor: "rgba(247, 248, 251, 1)",
          marginBottom: "-4px",
        }}
      >
        <RatingScaleFormSelect value={ratingScaleId} name={"ratingScale"} onChange={handleSelect} ratingScales={ratingScales} />
        <RatingScaleFormSelect
          value={secondRatingScaleId}
          name={"secondRatingScale"}
          onChange={handleSelect}
          label={t("choose-another-form-to-view-side-by-side")}
          ratingScales={ratingScales}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: "24px",
          gridTemplateColumns: "repeat(2, 1fr)",
          marginBottom: "16px",
          backgroundColor: "rgba(255, 255, 255, 1)",
          padding: "22px 32px 16px 32px",
          borderRadius: "7px",
          border: "1px solid rgba(213, 219, 227, 1)",
          borderTop: "1px solid rgba(213, 219, 227, 1)",
        }}
      >
        <Box>
          <DefaultText sx={{ fontSize: "12px", fontWeight: "700" }}>{t("description")}</DefaultText>
          {selectedRatingScale ? (
            <RatingScaleRequestDescription ratingScale={selectedRatingScale} loggedUserUuid={loggedUser?.uuid || ""} />
          ) : (
            <DefaultText sx={{ fontSize: "10px" }}>-</DefaultText>
          )}
        </Box>
        {secondSelectedRatingScale && (
          <Box sx={{ paddingLeft: "16px" }}>
            <DefaultText sx={{ fontSize: "12px", fontWeight: "700" }}>{t("description")}</DefaultText>
            <RatingScaleRequestDescription ratingScale={secondSelectedRatingScale} loggedUserUuid={loggedUser?.uuid || ""} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RatingScalesSwitcher;
