import { useEffect, useState } from "react";
import { ratingScalesApi } from "../api/RatingScalesApi";
import { RatingScalesFormDetails, RatingScalesRequest } from "../model/ratingScales.model";

export const useGetFormData = (ratingScaleId: string, id: string, ratingScalesRequests: RatingScalesRequest[], userLocale: string): RatingScalesFormDetails | null => {
  const [ratingScalesForm, setRatingScalesForm] = useState<RatingScalesFormDetails | null>(null);

  useEffect(() => {
    if (id && userLocale && ratingScalesRequests && ratingScaleId) {
      const getFormData = async () => {
        setRatingScalesForm(null);
        const respondentId = ratingScalesRequests?.find((rs) => rs.id === ratingScaleId)?.respondent.id;
        const response = await ratingScalesApi.getRatingScalesForm(id, ratingScaleId, respondentId as string, userLocale);
        setRatingScalesForm(response.data);
      };
      getFormData();
    }
  }, [id, ratingScaleId, ratingScalesRequests, userLocale]);

  return ratingScalesForm;
};
