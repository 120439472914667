import { Api, ApiResponsePromise, withErrorPage } from "./Api";
import { RatingScalesRequest, CreateRatingScalesRequestDto, RatingScalesRespondent, RatingScalesFormDetails } from "../model/ratingScales.model";
import { refreshAccessTokenHook } from "./responseHooks/refreshAccessTokenHook";

class RatingScalesApi extends Api {
  getRespondents = (patientId: string): ApiResponsePromise<RatingScalesRespondent[]> => {
    const url = `patients/${patientId}/ratingScales/respondents`;
    return this.get(url, withErrorPage());
  };
  createFormRequest = (patientId: string, ratingScalesRequest: CreateRatingScalesRequestDto): ApiResponsePromise<void> => {
    const { respondentId, type } = ratingScalesRequest;
    const url = `patients/${patientId}/ratingScales`;
    return this.post(url, withErrorPage({ requestPayload: { respondentId, type }, expectJsonResponse: false }));
  };
  getRatingScalesRequests = (patientId: string, status = ""): ApiResponsePromise<RatingScalesRequest[]> =>
    this.get(`patients/${patientId}/ratingScales?status=${status}`, withErrorPage());
  revokeRatingScalesRequest = (patientId: string, formId: string, respondentId: string) =>
    this.put(`patients/${patientId}/ratingScales/${formId}/revoke?respondentId=${respondentId}`, withErrorPage({ expectJsonResponse: false }));
  getRatingScalesForm = (patientId: string, ratingScaleId: string, respondentId: string, locale: string): ApiResponsePromise<RatingScalesFormDetails> =>
    this.get(`patients/${patientId}/ratingScales/${ratingScaleId}?respondentId=${respondentId}&locale=${locale}`, withErrorPage());
}

export const ratingScalesApi = new RatingScalesApi().useResponseHooks([refreshAccessTokenHook]);
