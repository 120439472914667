import { Box } from "@mui/material";
import OpenFolderImage from "./OpenFolderImage";
import { useTranslation } from "react-i18next";
import DefaultText from "./DefaultText";

const EmptyRatingScalesList = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: "24px 35px", height: "400px", backgroundColor: "rgba(247, 248, 251, 1)", border: "1px solid rgba(213, 219, 227, 1)", borderRadius: "8px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center", height: "inherit", justifyContent: "center" }}>
        <OpenFolderImage />
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 700, color: "rgba(78, 93, 105, 1)" }}>{t("no-rating-scales")}</DefaultText>
      </Box>
    </Box>
  );
};

export default EmptyRatingScalesList;
