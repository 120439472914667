import { useTranslation } from "react-i18next";
import DefaultDosesCard from "./DefaultDosesCard";
import DosesSourceChip from "./DosesSourceChip";
import { Box } from "@mui/material";

interface SkippedDosesCardProps {
  value: number;
  limit: number;
  doseSourceText?: boolean;
}
const SkippedDosesCard = ({ value, limit, doseSourceText }: SkippedDosesCardProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <DefaultDosesCard
        text={t("skipped")}
        value={value}
        limit={limit}
        sx={{
          borderColor: "rgba(254, 183, 1, 1)",
          background: "rgba(255, 245, 221, 1)",
          color: "rgba(228, 168, 11, 1)",
        }}
      />
      {doseSourceText && <DosesSourceChip source={t("manual-input")} />}
    </Box>
  );
};

export default SkippedDosesCard;
