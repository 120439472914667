import { Box, SxProps, Tooltip } from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";

interface DefaultTooltipProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  placement?: "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top";
  sx?: SxProps;
}

const DefaultTooltip = ({ children, title, placement = "right", sx }: DefaultTooltipProps) => {
  const [width, setWidth] = useState(0);
  const elementRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setWidth(elementRef.current?.offsetWidth || 0);
  }, []);

  return (
    <Tooltip
      ref={elementRef}
      followCursor
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "rgba(255, 255, 255, 1)",
            color: "rgb(78, 93, 105, 1)",
            border: "1px solid #E7EAEE",
            width: "fit-content",
            maxWidth: width,
            padding: 0,
            margin: 0,
            borderRadius: "8px",
            ...sx,
          },
        },
      }}
      title={title}
      placement={placement}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default DefaultTooltip;
