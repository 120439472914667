import { Box, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAppState } from "../state/AppState";
import { Page } from "../state/NavigationState";
import { BasicPatientListEntry } from "../model/patient.model";
import { patientApi } from "../api/PatientApi";
import { Navigate, useParams } from "react-router-dom";
import { t } from "i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import DefaultText from "../components/DefaultText";
import FhirErrorPage from "./FhirErrorPage";
import CaretRightIcon from "../components/icons/CaretRightIcon";
import Link from "../components/Link";
import FhirErrorIcon from "../components/icons/FhirErrorIcon";
import DefaultTableRow from "../components/PatientTableRow";
import WarningIcon from "../components/icons/Warning";
import { Trans } from "react-i18next";

const EmrPatientsPage = () => {
  const { navigation, notification } = useAppState();
  const { hrn } = useParams();
  const [patients, setPatients] = useState<BasicPatientListEntry[] | null>(null);

  const showEmrPatientNotification = useCallback(() => {
    notification.addNotification({
      title: (<Trans i18nKey={"patient-with-hrn-opened.title"} values={{ hrn }} />) as unknown as string,
      message: t("patient-with-hrn-opened.message"),
    });
  }, [hrn, notification]);

  useEffect(() => {
    navigation.backgroundColor = "#F0F4FA";
    navigation.activePage = Page.MY_PATIENTS;
  }, [navigation]);

  useEffect(() => {
    const getData = async () => {
      if (hrn) {
        const patients = (await patientApi.getEmrPatients(hrn)).data;
        if (patients && patients.length === 1) {
          showEmrPatientNotification();
        }
        setPatients(patients);
      }
    };
    getData();
  }, [hrn, showEmrPatientNotification]);

  if (!hrn) {
    return <Navigate to="/patients" />;
  }

  if (patients === null) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)" }} size="56px" />
      </Box>
    );
  }

  if (patients.length === 0) {
    console.error(`No patients with hrn=${hrn}`);
    return <FhirErrorPage />;
  }

  if (patients.length === 1) {
    return <Navigate to={`/patients/${patients[0].id}`} />;
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(231, 234, 238, 1)",
          borderRadius: "8px",
          paddingY: "80px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "60%" }}>
          <FhirErrorIcon />
          <DefaultText sx={{ fontSize: "36px", fontWeight: 700, lineHeight: "40px", marginBottom: "24px", marginTop: "36px" }}>{t("error.hrnConflict.title")}</DefaultText>
          <DefaultText sx={{ fontSize: "21px", marginBottom: "32px", lineHeight: "24px" }}>
            <Trans i18nKey={"error.hrnConflict.subtitle"} values={{ hrn }} components={{ b: <b style={{ fontWeight: 700 }}></b> }} />
          </DefaultText>
          <Box sx={{ width: "100%", maxHeight: "300px", overflowY: "auto" }}>
            {patients.map((patient, index) => (
              <DefaultTableRow link={`/patients/${patient.id}/30/`} key={index} sx={{ minWidth: undefined }} onClick={showEmrPatientNotification}>
                <Box sx={{ flex: 2 }}>
                  <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {patient.firstName} {patient.lastName}
                  </DefaultText>
                </Box>
                <Box sx={{ display: "flex", flex: 1, minWidth: "170px" }}>
                  <WarningIcon sx={{ marginRight: "5px" }} black />
                  <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>{hrn}</DefaultText>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DefaultText>{t(`check-patient-details`)}</DefaultText>
                </Box>
                <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                  <Box
                    sx={{
                      backgroundColor: "rgba(231, 234, 238, 1)",
                      width: "79px",
                      marginRight: "-16px",
                      marginY: "-22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0px 4px 4px 0px",
                    }}
                  >
                    <CaretRightIcon />
                  </Box>
                </Box>
              </DefaultTableRow>
            ))}
          </Box>
          <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", marginTop: "32px" }}>{t("error.hrnConflict.footer")}</DefaultText>
          <Link to="/patients">
            <DefaultPrimaryButton sx={{ marginTop: "32px", padding: "10px 64px", height: "36px" }}>{t("close")}</DefaultPrimaryButton>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default EmrPatientsPage;
