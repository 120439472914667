import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";

interface IconAndTextProps {
  icon: React.ReactNode;
  text: React.ReactNode;
  sx?: SxProps;
  textSx?: SxProps;
}

const IconAndText = ({ icon, text, sx, textSx }: IconAndTextProps) => {
  return (
    <Box sx={{ display: "flex", ...sx }}>
      {icon}
      <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)", paddingLeft: "5px", marginTop: "-1px", ...textSx }}>{text}</DefaultText>
    </Box>
  );
};

export default IconAndText;
