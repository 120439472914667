import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { Trans, useTranslation } from "react-i18next";
import { formatTimestamp } from "../util/date";
import { useEffect, useState } from "react";
import DefaultTableRow from "./PatientTableRow";
import EmptyRatingScalesList from "./EmptyRatingScalesList";
import { RatingScalesFormStatus, RatingScalesRequest, RatingScalesRevocationDetailsDto } from "../model/ratingScales.model";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import FileTextIcon from "./icons/FileTextIcon";
import InfoBlackIcon from "./icons/InfoBlackIcon";
import { useAppState } from "../state/AppState";
import { observer } from "mobx-react-lite";
import DefaultTooltip from "./DefaultTooltip";
import RevokeVanderbiltFormModal from "./RevokeVanderbiltFormModal";
import { useParams } from "react-router-dom";
import UserRoleChip from "./UserRoleChip";
import Link from "./Link";

export const RECORDS_PER_PAGE = 10;

interface RatingScalesRequestsListProps {
  requests: RatingScalesRequest[];
}

const RatingScalesRequestsList = ({ requests }: RatingScalesRequestsListProps) => {
  const { t } = useTranslation();
  const { user, patientProfilePageState, notification } = useAppState();
  const [requestsToDisplay, setRequestsToDisplay] = useState<RatingScalesRequest[]>([]);
  const [requestToRevoke, setRequestToRevoke] = useState<RatingScalesRequest | null>(null);
  const { id } = useParams();

  const handleRequestsToDisplay = () => {
    setRequestsToDisplay([]);
    const start = requestsToDisplay.length;
    const end = start + RECORDS_PER_PAGE;
    const newRequestsToDisplay = requests.slice(start, end);
    setRequestsToDisplay([...requestsToDisplay, ...newRequestsToDisplay]);
  };

  useEffect(() => {
    handleRequestsToDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRevocationDetails = (revocationDetails: RatingScalesRevocationDetailsDto) => {
    const { revocationTimestamp, revokedBy } = revocationDetails;
    const date = formatTimestamp(revocationTimestamp, "MMMM D, YYYY");
    const who = revokedBy ? (revokedBy.uuid === user.loggedUser?.uuid ? t("me").toLowerCase() : `${revokedBy.firstName} ${revokedBy.lastName}`) : undefined;
    const text = <Trans i18nKey={`revocation-details.${revokedBy ? "manual" : "automatic"}`} values={{ date, who }} />;
    return (
      <DefaultTooltip
        title={
          <Box sx={{ padding: "16px", maxWidth: "340px" }}>
            <DefaultText sx={{ fontWeight: "700", marginBottom: "4px" }}>{t("request-revoked")}</DefaultText>
            <DefaultText sx={{ fontSize: "12px" }}>{text}</DefaultText>
          </Box>
        }
        placement={"top"}
        sx={{ maxWidth: "fit-content", boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)" }}
      >
        <InfoBlackIcon sx={{ marginLeft: "5px" }} />
      </DefaultTooltip>
    );
  };

  const renderActionButton = (request: RatingScalesRequest): React.ReactNode => {
    const buttonSx: SxProps = { height: "28px", fontSize: "12px", padding: "6px 12px", borderRadius: "4px", minWidth: "63px" };
    if (request.status === RatingScalesFormStatus.COMPLETED) {
      return (
        <Link to={`/patients/${id}/ratingScales/${request.id}`}>
          <DefaultPrimaryButton sx={buttonSx}>{t("view")}</DefaultPrimaryButton>
        </Link>
      );
    } else if (request.status !== RatingScalesFormStatus.REVOKED && canRevoke(request)) {
      return (
        <DefaultPrimaryButton sx={buttonSx} onClick={() => handleRevokeAction(request)}>
          {t("revoke")}
        </DefaultPrimaryButton>
      );
    }

    return <></>;
  };

  const canRevoke = (request: RatingScalesRequest): boolean => {
    return request.requestor.uuid === user.loggedUser?.uuid || !request.requestor.active;
  };

  const handleRevokeAction = (request: RatingScalesRequest) => {
    setRequestToRevoke(request);
  };

  const handleCloseRevokeModal = () => {
    setRequestToRevoke(null);
  };

  const formRevokedCallback = () => {
    setRequestToRevoke(null);
    notification.addNotification({ title: t("rating-scale-form-revoked-title"), message: t("rating-scale-form-revoked-content") });
    patientProfilePageState.requestsListVersion++;
  };

  return (
    <>
      {(requests.length > 0 || requestsToDisplay.length > 0) && (
        <>
          <Box sx={{ display: "flex", direction: "row", marginBottom: "4px", padding: "12px 16px", gap: "28px", minWidth: "1000px" }}>
            <Box sx={{ flex: 2, minWidth: "160px" }}>
              <DefaultText sx={{ marginLeft: "48px" }}>{t("form-type")}</DefaultText>
            </Box>
            <DefaultText sx={{ flex: 1, minWidth: "112px" }}>{t("requested-by")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "100px" }}>{t("date-requested")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "112px" }}>{t("respondent")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "90px" }}>{t("role")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "100px" }}>{t("date-completed")}</DefaultText>
            <DefaultText sx={{ flex: 2, minWidth: "160px" }}>{t("status")}</DefaultText>
          </Box>
          {requestsToDisplay.map((request, index) => (
            <DefaultTableRow key={index} sx={{ minWidth: "1000px", gap: "28px", borderRadius: "8px" }}>
              <Box sx={{ flex: 2, minWidth: "160px", display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    backgroundColor: "rgba(231, 234, 238, 1)",
                    width: "48px",
                    minWidth: "48px",
                    height: "44px",
                    marginRight: "16px",
                    marginLeft: "-16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px 0px 0px 8px",
                  }}
                >
                  <FileTextIcon />
                </Box>
                <Box sx={{ minWidth: "112px" }}>
                  <DefaultTooltip
                    title={
                      <DefaultText sx={{ display: "flex", justifyContent: "center", padding: "10px", width: "286px", backgroundColor: "white", borderRadius: "8px" }}>
                        {t(request.type.toLowerCase())}
                      </DefaultText>
                    }
                    placement="bottom"
                  >
                    <DefaultText sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", direction: "rtl", "&::after": { content: '""', display: "block" } }}>
                      {t(request.type.toLowerCase())}
                    </DefaultText>
                  </DefaultTooltip>
                </Box>
              </Box>
              <Box sx={{ flex: 1, minWidth: "112px", display: "flex", gap: "2px" }}>
                <DefaultText sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {request.requestor.uuid === user.loggedUser?.uuid ? t("me") : `${request.requestor.firstName} ${request.requestor.lastName}`}
                </DefaultText>
                {!request.requestor.active && <DefaultText sx={{ fontSize: "12px" }}>({t("inactive")})</DefaultText>}
              </Box>
              <Box sx={{ flex: 1, minWidth: "100px" }}>
                <DefaultText>{formatTimestamp(request.creationTimestamp, "MM/DD/YYYY")}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, minWidth: "112px", display: "flex", gap: "2px" }}>
                <DefaultText sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {request.respondent.firstName} {request.respondent.lastName}
                </DefaultText>
                {!request.respondent.active && <DefaultText sx={{ fontSize: "12px" }}>({t("inactive")})</DefaultText>}
              </Box>
              <Box sx={{ flex: 1, minWidth: "90px" }}>
                <UserRoleChip role={request.respondent.role} />
              </Box>
              <Box sx={{ flex: 1, minWidth: "100px" }}>
                <DefaultText>{request.submissionTimestamp ? formatTimestamp(request.submissionTimestamp, "MM/DD/YYYY") : "-"}</DefaultText>
              </Box>
              <Box sx={{ flex: 2, minWidth: "160px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex" }}>
                  <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>{t(request.status.toLowerCase())}</DefaultText>
                  {request.status === RatingScalesFormStatus.REVOKED && renderRevocationDetails(request.revocationDetails as RatingScalesRevocationDetailsDto)}
                </Box>
                {renderActionButton(request)}
              </Box>
            </DefaultTableRow>
          ))}
          {requestsToDisplay.length < requests.length && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", minWidth: "1020px" }}>
              <Box onClick={() => handleRequestsToDisplay()}>
                <DefaultText sx={{ marginTop: "10px", fontWeight: 700, color: "rgba(145, 84, 201, 1)", cursor: "pointer" }}>{t("show-more")}...</DefaultText>
              </Box>
            </Box>
          )}
        </>
      )}
      {requests.length === 0 && requestsToDisplay.length === 0 && <EmptyRatingScalesList />}
      <RevokeVanderbiltFormModal
        handleClose={handleCloseRevokeModal}
        isOpen={requestToRevoke !== null}
        ratingScalesRequest={requestToRevoke as RatingScalesRequest}
        patientId={id as string}
        formRevokedCallback={formRevokedCallback}
      />
    </>
  );
};

export default observer(RatingScalesRequestsList);
