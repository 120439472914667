import { Page } from "../state/NavigationState";
import { useEffect, useState } from "react";
import { useAppState } from "../state/AppState";
import { patientApi } from "../api/PatientApi";
import { useParams } from "react-router-dom";
import { Patient } from "../model/patient.model";
import { withLoader } from "../hoc/withLoader";
import { Box, Skeleton } from "@mui/material";
import RatingScalesSwitcher from "../components/RatingScalesSwitcher";
import Link from "../components/Link";
import PurpleArrowLeftIcon from "../components/icons/PurpleArrowLeftIcon";
import TextHeader from "../components/TextHeader";
import { useTranslation } from "react-i18next";
import { ratingScalesApi } from "../api/RatingScalesApi";
import { RatingScalesCalculationResult, RatingScalesFormSection, RatingScalesFormStatus, RatingScalesRequest } from "../model/ratingScales.model";
import { getErrorState } from "../state/ErrorState";
import RatingScalesSummary from "../components/RatingScalesSummary";
import RatingScalesSections from "../components/RatingScalesSections";
import { useGetFormData } from "../hooks/useGetFormData";

const LoadingRatingScalesSwitcher = withLoader(RatingScalesSwitcher);
const LoadingRatingScalesSummary = withLoader(RatingScalesSummary);
const LoadingRatingScalesSections = withLoader(RatingScalesSections);

const RatingScaleFormPage = () => {
  const { navigation } = useAppState();
  const { t } = useTranslation();
  const { id, ratingScaleId, secondRatingScaleId } = useParams();
  const [patient, setPatient] = useState<Patient | null>(null);
  const [ratingScalesRequests, setRatingScalesRequests] = useState<RatingScalesRequest[] | null>(null);
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  const ratingScalesFirstForm = useGetFormData(ratingScaleId as string, id as string, ratingScalesRequests as RatingScalesRequest[], userLocale);
  const ratingScalesSecondForm = useGetFormData(secondRatingScaleId as string, id as string, ratingScalesRequests as RatingScalesRequest[], userLocale);
  const errorState = getErrorState();

  useEffect(() => {
    navigation.backgroundColor = "white";
    navigation.activePage = Page.MY_PATIENTS;
    navigation.breadcrumbsElements = [
      {
        labelId: "my-patients",
        path: "/patients",
      },
    ];
  }, [navigation]);

  useEffect(() => {
    const getSwitcherData = async () => {
      const ratingScales = (await ratingScalesApi.getRatingScalesRequests(id as string, RatingScalesFormStatus.COMPLETED)).data;
      const validRatingScaleId = ratingScales?.some((rs) => rs.id === ratingScaleId);
      let validSecondRatingScaleId = true;
      if (secondRatingScaleId) {
        validSecondRatingScaleId = ratingScales?.some((rs) => rs.id === secondRatingScaleId) || false;
      }
      if (ratingScales?.length === 0 || !validRatingScaleId || !validSecondRatingScaleId) {
        errorState.code = 403;
        navigation.breadcrumbsElements = [];
      } else {
        setRatingScalesRequests(ratingScales);
      }
    };
    getSwitcherData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, errorState, navigation]);

  useEffect(() => {
    const getPatient = async () => {
      setPatient((await patientApi.getPatient(id as string)).data);
    };
    getPatient();
  }, [id]);

  useEffect(() => {
    if (patient) {
      navigation.breadcrumbsElements = [
        {
          labelId: "my-patients",
          path: "/patients",
        },
        {
          labelId: `${patient.firstName} ${patient.lastName}`,
          path: `/patients/${id}`,
        },
        {
          labelId: "view-rating-scale-form",
        },
      ];
    }
  }, [navigation, patient, id]);

  return (
    <Box sx={{ paddingBottom: "25px" }}>
      <Link
        to={`/patients/${id}/30/ratingScales`}
        sx={{ gap: "8px", display: "flex", alignItems: "center", fontSize: "16px", color: "rgba(145, 84, 201, 1)", marginBottom: "32px", marginTop: "12px" }}
      >
        <PurpleArrowLeftIcon />
        {t("back-to-rating-scales")}
      </Link>
      <TextHeader sx={{ paddingBottom: "25px" }}>{t("view-rating-scale-form")}</TextHeader>
      <LoadingRatingScalesSwitcher ratingScales={ratingScalesRequests as RatingScalesRequest[]} isLoading={ratingScalesRequests === null}>
        <Box sx={{ height: 215, flex: 1, display: "flex", paddingBottom: "37px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
      </LoadingRatingScalesSwitcher>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", gap: "40px" }}>
        <Box sx={{ display: "flex", gap: "28px" }}>
          <LoadingRatingScalesSummary
            scoringInstructions={ratingScalesFirstForm?.scoring as RatingScalesCalculationResult[]}
            isLoading={ratingScalesFirstForm === null}
            sx={{ flex: 1 }}
          >
            <Box sx={{ height: 220, flex: 1, display: "flex" }}>
              <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
            </Box>
          </LoadingRatingScalesSummary>
          {secondRatingScaleId && (
            <LoadingRatingScalesSummary
              scoringInstructions={ratingScalesSecondForm?.scoring as RatingScalesCalculationResult[]}
              isLoading={ratingScalesSecondForm === null}
              sx={{ flex: 1 }}
            >
              <Box sx={{ height: 220, flex: 1, display: "flex" }}>
                <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
              </Box>
            </LoadingRatingScalesSummary>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "28px" }}>
          <LoadingRatingScalesSections sections={ratingScalesFirstForm?.sections as RatingScalesFormSection[]} isLoading={ratingScalesFirstForm === null} sx={{ flex: 1 }}>
            <Box sx={{ height: 605, flex: 1, display: "flex" }}>
              <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
            </Box>
          </LoadingRatingScalesSections>
          {secondRatingScaleId && (
            <LoadingRatingScalesSections sections={ratingScalesSecondForm?.sections as RatingScalesFormSection[]} isLoading={ratingScalesSecondForm === null} sx={{ flex: 1 }}>
              <Box sx={{ height: 605, flex: 1, display: "flex" }}>
                <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
              </Box>
            </LoadingRatingScalesSections>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RatingScaleFormPage;
