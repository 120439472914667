import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import { TimeRangeInput } from "../model/patient.model";

export enum PatientProfileTab {
  REPORT,
  RATING_SCALES,
}

export enum ReportPeriod {
  THIRTY_DAYS = "30",
  SIXTY_DAYS = "60",
  NINETY_DAYS = "90",
}

export class PatientProfilePageState {
  private _activeTab: PatientProfileTab = PatientProfileTab.REPORT;
  private _numberOfDays: ReportPeriod | null = null;
  private _requestsListVersion = 0;

  constructor() {
    makeAutoObservable(this);
  }

  set activeTab(tab: PatientProfileTab) {
    this._activeTab = tab;
  }

  get activeTab(): PatientProfileTab {
    return this._activeTab;
  }

  isActive(tab: PatientProfileTab) {
    return this._activeTab === tab;
  }

  set numberOfDays(value: ReportPeriod | null) {
    this._numberOfDays = value;
  }

  get numberOfDays(): ReportPeriod | null {
    return this._numberOfDays;
  }

  set requestsListVersion(version: number) {
    this._requestsListVersion = version;
  }

  get requestsListVersion(): number {
    return this._requestsListVersion;
  }

  get timeRangeInput(): TimeRangeInput {
    const to = dayjs().subtract(1, "day");
    const from = dayjs().subtract(Number(this._numberOfDays), "days");
    const tzName = dayjs.tz.guess();
    return {
      from: from.valueOf(),
      to: to.valueOf(),
      tzName,
    };
  }
}
