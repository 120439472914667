import { ApiResponse } from "../Api";

export interface TerminablePromise<T> {
  promise: Promise<T>;
  abort: () => void;
}

export const createTerminableRequest = <T>(request: (signal: AbortSignal) => Promise<ApiResponse<T>>) => {
  const abortController = new AbortController();
  const resultPromise = request(abortController.signal) as Promise<ApiResponse<T>>;

  return {
    promise: resultPromise,
    abort: () => abortController.abort(),
  };
};
