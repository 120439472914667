import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import { InvitationData } from "../model/invitationData.model";
import { Trans, useTranslation } from "react-i18next";
import DefaultInput from "./DefaultInput";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { useState } from "react";
import TextHeader from "./TextHeader";
import { invitationApi } from "../api/InvitationApi";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../state/AppState";
import { patterns, validate } from "../util/validation";
import { hasErrorKey } from "../api/Api";
import { getErrorState } from "../state/ErrorState";
import { userApi } from "../api/UserApi";
import ExpiredInvitation from "./ExpiredInvitation";

const HrnValidation = ({ accessData }: { accessData: InvitationData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [hrn, setHrn] = useState("");
  const { user, notification } = useAppState();
  const errorState = getErrorState();
  const disabledProceedBtn = hrn.length < 1 || loading || errorMsg === t("contact-guardian-receive-email");
  const navigate = useNavigate();
  const [expiredInvitation, setExpiredInvitation] = useState<boolean>(false);

  const validateHrn = async (hrn: string) => {
    if (user.invitationId && user.invitationToken) {
      return invitationApi.validateHrn(user.invitationId, user.invitationToken, hrn);
    } else {
      throw Error("Invitation data does not exist");
    }
  };

  const handleHrnSubmit = async (hrn: string) => {
    if (hrn.length > 0 && errorMsg !== t("contact-guardian-receive-email")) {
      setLoading(true);
      const response = await validateHrn(hrn);
      if (response.ok) {
        setErrorMsg("");
        user.registrationToken = response.data?.registrationToken || null;
        if (user.registrationToken) {
          navigate(`/register/step1${user.invitationUrl()}`);
        } else {
          await handleRegisteredUser();
        }
      } else if (hasErrorKey("hrn.wrong", response.error)) {
        setErrorMsg(t("hrn-is-wrong-try-again"));
      } else if (hasErrorKey("hrn.exceeded", response.error)) {
        setErrorMsg(t("contact-guardian-receive-email"));
      } else if (response.error.code === 403) {
        setExpiredInvitation(true);
      } else {
        errorState.code = response.error.code;
      }
      setLoading(false);
    }
  };

  const handleRegisteredUser = async () => {
    const meResponse = await userApi.me();
    if (meResponse.ok) {
      //user is logged in
      notification.addNotification({
        title: t("new-patient-record"),
        message: (
          <Trans
            i18nKey="access-to-patient"
            components={{ b: <b></b> }}
            values={{
              newPatientFirstName: user.newPatientFirstName,
              newPatientLastName: user.newPatientLastName,
            }}
          />
        ) as unknown as string,
      });
      user.clearInvitationData();
      navigate("/");
    } else {
      navigate("/login");
    }
  };

  const handleInputChange = (value: string, setter: (value: string) => void, errorSetter: (value: string) => void) => {
    setter(value);
    const error = validate(value, [patterns.requiredField]);
    if (error) {
      errorSetter(t(error));
    } else {
      errorSetter("");
    }
  };

  if (expiredInvitation) {
    return <ExpiredInvitation />;
  }

  return (
    <>
      <TextHeader sx={{ paddingBottom: "57px" }}>{t("new-invitation")}</TextHeader>
      <TextHeader sx={{ fontSize: "21px", lineHeight: "24px" }}>{t("dear-healthcare-professional")}</TextHeader>
      <Box sx={{ paddingTop: "24px", display: "flex", flexWrap: "wrap", columnGap: "8px" }}>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>
          <Trans
            i18nKey="invitation-to-review"
            components={{ b: <b></b> }}
            values={{
              adminGuardianFirstName: accessData.adminGuardianFirstName,
              adminGuardianLastName: accessData.adminGuardianLastName,
              newPatientFirstName: accessData.patientFirstName,
              newPatientLastName: accessData.patientLastName,
            }}
          />
        </DefaultText>
      </Box>
      <Box sx={{ paddingTop: "24px", paddingBottom: "40px", display: "flex", flexWrap: "wrap", columnGap: "8px" }}>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>
          <Trans i18nKey="to-proceed-enter-the-childs" components={{ b: <b></b> }} />
        </DefaultText>
      </Box>
      <DefaultInput
        label={t("child-health-record-number")}
        value={hrn}
        onChange={(value) => handleInputChange(value, setHrn, setErrorMsg)}
        onSubmit={handleHrnSubmit}
        error={!!errorMsg}
        errorMsg={errorMsg}
      />
      <DefaultPrimaryButton
        onClick={() => {
          handleHrnSubmit(hrn);
        }}
        isLoading={loading}
        disabled={disabledProceedBtn}
        loadingText={t("validating")}
        sx={{ width: "323px", marginTop: "48px" }}
      >
        {t("proceed")}
      </DefaultPrimaryButton>
    </>
  );
};

export default HrnValidation;
