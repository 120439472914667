import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultText from "./DefaultText";
import { DoseChangeHistoryEntry } from "../model/report.model";
import { getTimeAgo, reformatDate } from "../util/date";
import CalendarBlankIcon from "./icons/CalendarBlankIcon";
import DefaultPrimaryButton from "./DefaultPrimaryButton";

interface DoseChangeHistoryProps {
  doseChangeHistory: DoseChangeHistoryEntry[];
  handleClose: () => void;
  initiallyScheduledDose: string;
}

const DoseChangeHistory = ({ doseChangeHistory, handleClose, initiallyScheduledDose }: DoseChangeHistoryProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: "flex", paddingBottom: "24px" }}>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("initially-scheduled-dose")} -</DefaultText>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 700, paddingLeft: "3px" }}>{initiallyScheduledDose}</DefaultText>
      </Box>
      <Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", backgroundColor: "rgba(231, 234, 238, 1)", padding: "12px 10px" }}>
          <DefaultText sx={{ fontSize: "12px", marginRight: "10px", borderRight: "1px solid rgba(179, 187, 196, 1)" }}>{t("last-change")}</DefaultText>
          <DefaultText sx={{ fontSize: "12px", marginRight: "10px", borderRight: "1px solid rgba(179, 187, 196, 1)" }}>{t("changed-on")}</DefaultText>
          <DefaultText sx={{ fontSize: "12px" }}>{t("dose-change")}</DefaultText>
        </Box>
        {doseChangeHistory.map((dose, index) => (
          <Box key={index} sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", padding: "8px 10px", border: "1px solid rgba(222, 227, 233, 1)", borderTop: "none" }}>
            <DefaultText sx={{ fontSize: "12px", fontWeight: 700 }}>{getTimeAgo(dose.date)}</DefaultText>
            <Box sx={{ display: "flex", gap: "4px" }}>
              <CalendarBlankIcon sx={{ width: "16px", height: "16px" }} />
              <DefaultText sx={{ fontSize: "12px" }}>{reformatDate(dose.date, "YYYY-MM-DD HH:mm:ss", "MM/DD/YYYY")}</DefaultText>
            </Box>
            <Box sx={{ display: "flex", gap: "3px" }}>
              <DefaultText sx={{ fontSize: "12px" }}>{t("from").toLowerCase()}</DefaultText>
              <DefaultText sx={{ fontSize: "12px", fontWeight: 700 }}>{dose.previousDoseSize}</DefaultText>
              <DefaultText sx={{ fontSize: "12px" }}>{t("to").toLowerCase()}</DefaultText>
              <DefaultText sx={{ fontSize: "12px", fontWeight: 700 }}>
                {dose.newDoseSize} {dose.doseUnit}
              </DefaultText>
            </Box>
          </Box>
        ))}
      </Box>
      <DefaultPrimaryButton onClick={handleClose} sx={{ width: "166px", marginTop: "32px", float: "right" }}>
        {t("close")}
      </DefaultPrimaryButton>
    </>
  );
};

export default DoseChangeHistory;
