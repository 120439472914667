import { t } from "i18next";
import { ChartData, DeviceMedicationLoggedBy, DoseUnit, ScheduleType } from "../model/report.model";
import { reformatDate } from "../util/date";

interface ChartTooltipProps {
  element: ChartData;
  header?: string;
  scheduleType?: ScheduleType;
  doseUnit?: DoseUnit;
}

interface DefaultDosesCardProps {
  value: number;
  limit: number;
  type: string;
  doseSourceText?: boolean;
}

export const ChartTooltip = ({ element, header, scheduleType, doseUnit }: ChartTooltipProps): string => {
  const getColorSchemeByType = (
    type?: string
  ): {
    borderColor: string;
    background: string;
    color: string;
  } => {
    let borderColor;
    let background;
    let color;
    switch (type) {
      case "taken":
        borderColor = "rgba(72, 197, 29, 1)";
        background = "rgba(218, 255, 205, 1)";
        color = "rgba(43, 142, 8, 1)";
        break;
      case "missed":
        borderColor = "rgba(252, 137, 137, 1)";
        background = "rgba(255, 242, 242, 1)";
        color = "rgba(221, 49, 49, 1)";
        break;
      case "skipped":
        borderColor = "rgba(254, 183, 1, 1)";
        background = "rgba(255, 245, 221, 1)";
        color = "rgba(228, 168, 11, 1)";
        break;
      default:
        borderColor = "rgba(213, 219, 227, 1)";
        background = "rgba(249, 249, 249, 1)";
        color = "rgba(4, 11, 16, 1)";
        break;
    }
    return {
      borderColor,
      background,
      color,
    };
  };
  const dosesCard = ({ type, value, limit }: DefaultDosesCardProps): string => {
    const { borderColor, background, color } = getColorSchemeByType(type);
    return `
      <div>
        <div style="
          background: ${background};
          width: 100px;
          height: 84px;
          border: 1px solid ${borderColor};
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;"
        >
          <p style="color: ${color}; padding-bottom: 4px;">${t(type)}</p>
          <p style="font-size: 28px; color: ${color}; line-height: 32px; font-weight: 700;">${value.toString()}</p>
          <p style="color: ${color};">
            ${t("of")} ${limit} ${t("scheduled")}
          </p>
        </div>
      </div>
    `;
  };
  const displayLoggedBy = (loggedBy: string[] = [], status?: string): string => {
    const { color } = getColorSchemeByType(status);
    const statusText = status ? `<span style="color: ${color};">${t(status)}</span> - ` : "";
    const loggedByText = loggedBy
      .map((loggedBy) => {
        if (loggedBy === DeviceMedicationLoggedBy.AUTOMATICALLY_CAPTURED) {
          return t(loggedBy);
        }
        return loggedBy;
      })
      .join(", ");
    return loggedByText ? `${statusText}${loggedByText}` : "";
  };
  return `
    <style>
      p {
        margin: 0px;
        font-size: 10px;
        line-height: 16px;
        color: rgba(78, 93, 105, 1);
        white-space: nowrap;
      }
    </style>
    <div id="tooltip-content"
    style="
      display: flex;
      background: rgba(249, 249, 249, 1);
      flex-direction: column;
      gap: 16px;
      padding: 16px 24px;
      background-color: rgba(255, 255, 255, 1);
      justify-content: center;
      width: fit-content;
      box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
      border-radius: 16px;"
    >
      <p style="font-size: 21px; line-height: 24px; font-weight: 700; color: rgba(4, 11, 16, 1);">${reformatDate(element.date, "YYYY-MM-DD", "MMMM D, YYYY")}</p>
      <div style="display: flex; gap: 16px;">
        <div style="display: flex; flex-wrap: wrap;">
          <div style="display: flex; gap: 16px;">
            ${
              header
                ? `
              <div style="
                background: rgba(249, 249, 249, 1);
                border: 1px solid rgba(213, 219, 227, 1);
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 0px 16px 8px 16px;
                gap: 2px;
                height: 76px;
                min-width: 68px;"
              >
                <p style="font-size: 28px; line-height: 32px; font-weight: 700;">${element.observationRating || "-"}</p>
                ${header ? ` <p>${t(header)}</p>` : ""}
              </div>`
                : ""
            }
            ${
              element.medication
                ? `
              <div style="
                background: rgba(249, 249, 249, 1);
                border: 1px solid rgba(213, 219, 227, 1);
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 0px 16px 8px 16px;
                gap: 2px;
                height: 76px;
                min-width: 148px;"
              >
                <p style="font-size: 28px; line-height: 32px; font-weight: 700;">
                ${element.medication.doseTaken}/${element.medication.doseBaseLine ? element.medication.doseBaseLine : 0} ${t(doseUnit as string)}
                </p>
                <p>${t("taken")} / ${t("scheduled").slice(0, 1).toUpperCase() + t("scheduled").slice(1, t("scheduled").length)}</p>
              </div>`
                : ""
            }
          </div>
          <div style="display: flex; flex-wrap: wrap; margin-top: 4px;">
            ${
              element.observationLoggedBy !== undefined
                ? `<div style="display: flex; width: 100%"><p>${t("observation.loggedBy")}:</p></div>
                   <div style="display: flex;"><p>${displayLoggedBy([element.observationLoggedBy])}</p></div>`
                : ""
            }
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap;">
          <div style="display: flex; gap: 16px;">
            ${
              element.medication
                ? `
                ${dosesCard({ type: "taken", value: element.medication.doseSummary.taken, limit: element.medication.doseSummary.total })}
                ${dosesCard({
                  type: "missed",
                  value: element.medication.doseSummary.missed,
                  limit: element.medication.doseSummary.total,
                })}
                ${scheduleType === "DEVICE" ? "" : dosesCard({ type: "skipped", value: element.medication.doseSummary.skipped, limit: element.medication.doseSummary.total })}
                ${dosesCard({
                  type: scheduleType === "DEVICE" ? "not-synchronized" : "no-data",
                  value: element.medication.doseSummary.noData,
                  limit: element.medication.doseSummary.total,
                })}`
                : ""
            }
          </div>
          <div style="display: flex; flex-wrap: wrap; margin-top: 4px;">
          ${
            element.medication?.loggedBy.taken.length || element.medication?.loggedBy.missed.length || element.medication?.loggedBy.skipped.length
              ? ` <div style="display: flex; width: 100%">
                    <p>${t("medication.loggedBy")}:</p>
                  </div>
                  <div style="display: flex;">
                    <p style="white-space: normal;">
                    ${[
                      displayLoggedBy(element.medication?.loggedBy.taken, "taken"),
                      displayLoggedBy(element.medication?.loggedBy.missed, "missed"),
                      displayLoggedBy(element.medication?.loggedBy.skipped, "skipped"),
                    ]
                      .filter((text) => text)
                      .join(`; `)}
                    </p>
                  </div>`
              : ""
          }
          </div>
        </div>
      </div>
    </div>
 `;
};

export default ChartTooltip;
