import { Box, SxProps } from "@mui/material";
import { t } from "i18next";
import DefaultAccordion from "./DefaultAccordion";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import InfoBlackIcon from "./icons/InfoBlackIcon";
import { RatingScalesFormSection } from "../model/ratingScales.model";
import DefaultTooltip from "./DefaultTooltip";
import RatingScalesQuestion from "./RatingScalesQuestion";

interface RatingScalesSectionsProps {
  sections: RatingScalesFormSection[];
  sx?: SxProps;
}

const RatingScalesSections = ({ sections, sx }: RatingScalesSectionsProps) => {
  return (
    <Box sx={{ ...sx }}>
      <TextHeader sx={{ marginBottom: "24px" }}>{t("questions")}</TextHeader>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        {sections
          .sort((a, b) => a.order - b.order)
          .map((section, index) => (
            <DefaultAccordion
              key={index}
              defaultExpanded
              summary={
                <>
                  {section.directions ? (
                    <DefaultTooltip
                      title={
                        <Box
                          sx={{
                            padding: "16px",
                            width: "300px",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            border: "1px solid rgba(231, 234, 238, 1)",
                            boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
                          }}
                        >
                          <DefaultText sx={{ fontWeight: 700, paddingBottom: "4px" }}>{t("instruction")}</DefaultText>
                          <DefaultText sx={{ fontSize: "12px" }}>{section.directions}</DefaultText>
                        </Box>
                      }
                    >
                      <Box sx={{ display: "flex" }}>
                        <DefaultText sx={{ fontSize: "21px", lineHeight: "24px", fontWeight: 700 }}>{section.name}</DefaultText>
                        <InfoBlackIcon sx={{ width: "24px", height: "24px", paddingLeft: "8px" }} />
                      </Box>
                    </DefaultTooltip>
                  ) : (
                    <DefaultText sx={{ fontSize: "21px", lineHeight: "24px", fontWeight: 700 }}>{section.name}</DefaultText>
                  )}
                  {section.firstQuestionIndex && (
                    <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", marginTop: "8px" }}>
                      ({t("questions").toLowerCase()} {section.firstQuestionIndex}-{section.lastQuestionIndex})
                    </DefaultText>
                  )}
                </>
              }
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: section.firstQuestionIndex ? "22px" : "30px", paddingBottom: "16px" }}>
                {section.questions.map((question, index) => (
                  <Box key={index}>
                    <RatingScalesQuestion question={question} />
                    {index + 1 < section.questions.length && section.firstQuestionIndex && <hr style={{ width: "100%", border: "0.5px solid rgba(213, 219, 227, 1)" }} />}
                  </Box>
                ))}
              </Box>
            </DefaultAccordion>
          ))}
      </Box>
    </Box>
  );
};

export default RatingScalesSections;
