import { Box } from "@mui/material";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import DefaultInput from "./DefaultInput";
import { patientApi } from "../api/PatientApi";
import { hasErrorKey } from "../api/Api";
import { getErrorState } from "../state/ErrorState";
import { useBeforeUnload, useParams } from "react-router-dom";
import { patterns, validate } from "../util/validation";
import { useAppState } from "../state/AppState";

interface LogoutConfirmationProps {
  handleClose: () => void;
  onSuccess: (healthRecordNumber: string) => void;
  healthRecordNumber?: string;
}

const EditHRN = ({ healthRecordNumber, onSuccess, handleClose }: LogoutConfirmationProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { id } = useParams();
  const [newHRN, setNewHRN] = useState("");
  const errorState = getErrorState();
  const { notification } = useAppState();
  const disabledSave = loading || !newHRN || (!!healthRecordNumber && newHRN === healthRecordNumber);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (newHRN) {
          event.preventDefault();
          // Included for legacy support, e.g. Chrome/Edge < 119
          event.returnValue = true;
        }
      },
      [newHRN]
    )
  );

  const handleSave = async () => {
    if (!newHRN || (healthRecordNumber && newHRN === healthRecordNumber)) {
      return;
    }
    setLoading(true);
    const response = await patientApi.setHrn(id as string, newHRN);
    if (response.ok) {
      setErrorMsg("");
      onSuccess(newHRN);
      handleClose();
      notification.addNotification({
        title: t("hrn.updated"),
        message: (<Trans i18nKey="hrn.updated-successfully" components={{ b: <b></b> }} />) as unknown as string,
      });
    } else if (hasErrorKey("healthRecordNumber.assigned", response.error)) {
      setErrorMsg(t("hrn.duplicate"));
    } else {
      errorState.code = response.error.code;
    }
    setLoading(false);
  };

  const handleInputChange = (value: string, setter: (value: string) => void, errorSetter: (value: string) => void) => {
    setter(value);
    const error = validate(value, [patterns.requiredField]);
    if (error) {
      errorSetter(t(error));
    } else {
      errorSetter("");
    }
  };

  return (
    <>
      <Box>
        {healthRecordNumber ? <DefaultInput label={t("hrn.current")} value={healthRecordNumber} disabled={true} sx={{ paddingBottom: "24px", width: "100%" }} /> : null}
        <DefaultInput
          label={t("hrn.new")}
          value={newHRN}
          placeholder={t("hrn.start-typing-new")}
          error={!!errorMsg}
          errorMsg={errorMsg}
          onChange={(value) => handleInputChange(value, setNewHRN, setErrorMsg)}
          onSubmit={handleSave}
          sx={{ paddingBottom: "24px", width: "100%" }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "16px" }}>
        <DefaultSecondaryButton onClick={handleClose} sx={{ width: "160px", height: "44px", marginRight: "20px" }}>
          {t("cancel")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleSave} sx={{ width: "166px", height: "44px" }} isLoading={loading} disabled={disabledSave}>
          {t("save")}
        </DefaultPrimaryButton>
      </Box>
    </>
  );
};

export default EditHRN;
