import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import { Trans, useTranslation } from "react-i18next";
import RedArrowUpIcon from "./icons/RedArrowUpIcon";
import GreenArrowDownIcon from "./icons/GreenArrowDownIcon";
import { useEffect, useState } from "react";
import { ObservationReportEntry } from "../model/report.model";

const ObservationItem = (item: ObservationReportEntry) => {
  const [timeString, setTimeString] = useState<string>("");
  const { t } = useTranslation();
  const header = item.code.replace(/_/g, "-").toLowerCase();

  useEffect(() => {
    if (item.averageObservationLastDoseDifference) {
      const minutes = Math.floor(item.averageObservationLastDoseDifference / 60000);
      if (minutes < 60) {
        setTimeString(`${minutes} ${minutes === 1 ? t("minute") : t("minutes")}`);
      } else {
        const hours = Math.floor(minutes / 60);
        setTimeString(`${hours} ${hours === 1 ? t("hour") : t("hours")}`);
      }
    }
  }, [item.averageObservationLastDoseDifference, t]);

  return (
    <>
      <Box
        sx={{
          width: "230px",
          padding: "18px 24px;",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          backgroundColor: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(232, 229, 255, 1)",
          borderRadius: "8px",
        }}
      >
        <DefaultText sx={{ fontWeight: 700, display: "flex", alignItems: "center", height: "32px" }}>{t(header)}</DefaultText>
        <Box sx={{ display: "flex", gap: "19px" }}>
          <Box sx={{ width: "64px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end", paddingBottom: "6px" }}>
            {item.averageRatingChange !== 0 && item.averageRatingChange && (
              <Box sx={{ display: "flex", justifyContent: "center", gap: "4px", paddingBottom: "8px" }}>
                <Box
                  sx={{
                    width: "25px",
                    height: "24px",
                    backgroundColor: `${item.averageRatingChange > 0 ? "rgba(255, 242, 242, 1)" : "rgba(218, 255, 205, 1)"}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                  }}
                >
                  <DefaultText sx={{ color: `${item.averageRatingChange > 0 ? "rgba(221, 49, 49, 1)" : "rgba(43, 142, 8, 1)"}`, fontSize: "12px" }}>
                    {Math.abs(item.averageRatingChange)}
                  </DefaultText>
                </Box>
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: `${item.averageRatingChange > 0 ? "rgba(255, 242, 242, 1)" : "rgba(218, 255, 205, 1)"}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                  }}
                >
                  {item.averageRatingChange > 0 ? <RedArrowUpIcon /> : <GreenArrowDownIcon />}
                </Box>
              </Box>
            )}
            <Box sx={{ width: "56px", height: "100px", display: "flex", alignItems: "end", backgroundColor: "rgba(240, 242, 243, 1)" }}>
              <Box sx={{ width: "inherit", height: `${item.averageRating * 20}px`, backgroundColor: "rgba(94, 198, 182, 1)" }} />
            </Box>
            <DefaultText
              sx={{ color: item.averageRating > 1.2 ? "rgba(255, 255, 255, 1)" : "rgba(0,0,0,1)", fontSize: "18px", lineHeight: "24px", fontWeight: 700, marginTop: "-30px" }}
            >
              {item.averageRating}
            </DefaultText>
          </Box>
          <Box sx={{ justifyContent: "end", display: "flex", flexDirection: "column", height: "132px" }}>
            <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", fontSize: "12px", paddingBottom: "8px" }}>
              <Trans i18nKey="average-rating-based-on" components={{ b: <b style={{ color: "rgba(4, 11, 16, 1)" }}></b> }} values={{ ratingsCount: item.ratingsCount }} />
            </DefaultText>
            <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", fontSize: "12px" }}>
              <Trans i18nKey="highest-rating-logged" components={{ b: <b style={{ color: "rgba(4, 11, 16, 1)" }}></b> }} values={{ highestRating: item.highestRating }} />
            </DefaultText>
            {item.averageObservationLastDoseDifference && (
              <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", fontSize: "12px", paddingTop: "12px" }}>
                <Trans i18nKey="average-time-observation" components={{ b: <b style={{ color: "rgba(4, 11, 16, 1)" }}></b> }} values={{ timeString }} />
              </DefaultText>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ObservationItem;
