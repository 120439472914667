import { Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import PencilSimpleLineIcon from "./icons/PencilSimpleLineIcon";
import TrashIcon from "./icons/TrashIcon";
import { useAppState } from "../state/AppState";
import Link from "./Link";

const MyProfile = () => {
  const { t } = useTranslation();
  const { user } = useAppState();

  return (
    <>
      <TextHeader sx={{ fontSize: "24px", marginBottom: "32px" }}> {t("my-profile")}</TextHeader>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", width: "365px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <DefaultText sx={{ fontWeight: 700 }}>{t("first-name")}</DefaultText>
            <DefaultText>{user.loggedUser?.firstName}</DefaultText>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <DefaultText sx={{ fontWeight: 700 }}>{t("email")}</DefaultText>
            <DefaultText>{user.loggedUser?.email}</DefaultText>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <DefaultText sx={{ fontWeight: 700 }}>{t("healthcare-provider-practice")}</DefaultText>
            {user.loggedUser?.healthcareProvider ? (
              <>
                <DefaultText sx={{ marginTop: "8px" }}>{user.loggedUser?.healthcareProvider.name}</DefaultText>
                <DefaultText sx={{ marginTop: "4px" }}>{user.loggedUser?.healthcareProvider.location}</DefaultText>
                <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)" }}>
                  {t("NPI")} {user.loggedUser?.healthcareProvider.npi}
                </DefaultText>
              </>
            ) : (
              <>
                <DefaultText sx={{ marginTop: "8px" }}>{user.loggedUser?.practiceName}</DefaultText>
                <DefaultText sx={{ marginTop: "4px" }}>{user.loggedUser?.practiceLocation}</DefaultText>
                <DefaultText sx={{ fontSize: "12px", width: "350px", marginTop: "16px" }}>
                  <Trans i18nKey="custom-healthcare-provider-emr-hint" />
                </DefaultText>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", width: "300px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <DefaultText sx={{ fontWeight: 700 }}>{t("last-name")}</DefaultText>
            <DefaultText>{user.loggedUser?.lastName}</DefaultText>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <DefaultText sx={{ fontWeight: 700 }}>{t("specialty")}</DefaultText>
            <DefaultText>{t(`specialty.${user.loggedUser?.specialty}`)}</DefaultText>
          </Box>
        </Box>
      </Box>
      <Link to={"/settings/my-profile/edit"}>
        <DefaultSecondaryButton sx={{ marginTop: "40px", width: "160px", fontSize: "14px" }} startIcon={<PencilSimpleLineIcon />}>
          {t("edit-data")}
        </DefaultSecondaryButton>
      </Link>
      <hr style={{ width: "100%", border: "1px solid rgba(230,234,239,1)", marginTop: "40px", marginBottom: "40px" }} />
      <TextHeader sx={{ fontSize: "24px", marginBottom: "32px" }}> {t("delete-account")}</TextHeader>
      <DefaultSecondaryButton sx={{ width: "160px", fontSize: "14px" }} startIcon={<TrashIcon />}>
        {t("delete-account")}
      </DefaultSecondaryButton>
    </>
  );
};

export default MyProfile;
