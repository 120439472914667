import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppState } from "../state/AppState";
import { useCallback } from "react";
import DefaultSelect from "./DefaultSelect";
import DefaultDatePicker from "./DefaultDatePicker";
import { ReportPeriod } from "../state/PatientProfilePageState";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

const ReportTypeSelectorTab = () => {
  const { t } = useTranslation();
  const { patientProfilePageState } = useAppState();
  const { pathname } = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { from, to } = patientProfilePageState.timeRangeInput;
  const reportTypeObjects = Object.values(ReportPeriod).map((reportType) => ({
    label: `${t("last")} ${reportType} ${t("days")}`,
    value: reportType,
  }));

  const handleReportTypeChange = useCallback(
    (value: string) => {
      patientProfilePageState.numberOfDays = value as ReportPeriod;
      const isOnTrackingHistoryCharts = pathname.includes("trackingHistory");
      if (isOnTrackingHistoryCharts) {
        const observationType = searchParams.get("observationType");
        const scheduleType = searchParams.get("scheduleType");
        const scheduleId = searchParams.get("scheduleId");
        const queryParams = new URLSearchParams();
        if (observationType) {
          queryParams.append("observationType", observationType);
        }
        if (scheduleType) {
          queryParams.append("scheduleType", scheduleType);
        }
        if (scheduleId) {
          queryParams.append("scheduleId", scheduleId);
        }
        navigate(`/patients/${params.id}/${value}${"/trackingHistory?" + queryParams.toString()}`);
      } else {
        navigate(`/patients/${params.id}/${value}`);
      }
    },
    [navigate, params.id, pathname, patientProfilePageState, searchParams]
  );

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "32px 16px" }}>
      <Box>
        <DefaultSelect
          sx={{ width: "370px" }}
          label={t("report-type")}
          value={patientProfilePageState.numberOfDays ? patientProfilePageState.numberOfDays : "30"}
          selectHandler={handleReportTypeChange}
          options={reportTypeObjects}
        />
      </Box>
      <Box>
        <DefaultDatePicker label={t("from")} value={new Date(from)} disabled />
      </Box>
      <Box>
        <DefaultDatePicker label={t("to")} value={new Date(to)} disabled />
      </Box>
    </Box>
  );
};

export default observer(ReportTypeSelectorTab);
