import { Box } from "@mui/material";
import { useAppState } from "../state/AppState";
import { ChartData, TrackingHistoryChartData, PatientTrackingHistoryReport } from "../model/report.model";
import TrackingHistoryChart from "./TrackingHistoryChart";
import { observer } from "mobx-react-lite";
import ChartLegend from "./ChartLegend";
import DefaultText from "./DefaultText";
import InfoIcon from "./icons/InfoIcon";
import { useTranslation } from "react-i18next";

interface TrackingHistoryChartsGroupProps {
  trackingHistoryData: PatientTrackingHistoryReport;
}

const TrackingHistoryChartsGroup = ({ trackingHistoryData }: TrackingHistoryChartsGroupProps) => {
  const { t } = useTranslation();
  const { patientProfilePageState } = useAppState();

  const observationChartData = !!trackingHistoryData.data.find((item) => item.observations.length > 0);
  const medicationChartData = !!trackingHistoryData.data.find((item) => item.medication !== undefined);
  const medicationBaseline = !!trackingHistoryData.data.find((item) => item.medication?.doseBaseLine !== undefined);

  const transformData = (originalData: PatientTrackingHistoryReport): TrackingHistoryChartData[] => {
    const transformedData: TrackingHistoryChartData[] = [];
    const chartData: ChartData[] = [];

    originalData.data.forEach((item) => {
      if (observationChartData) {
        item.observations.forEach((observation) => {
          const existingData = transformedData.find((data) => data.observationNameCode === observation.code);
          if (existingData) {
            existingData.chartData.push({
              date: item.date,
              observationRating: observation.rating,
              observationLoggedBy: observation.loggedBy,
              medication: item.medication,
            });
          } else {
            const newData: TrackingHistoryChartData = {
              observationNameCode: observation.code,
              doseUnit: originalData.doseUnit,
              chartData: [
                {
                  date: item.date,
                  observationRating: observation.rating || undefined,
                  observationLoggedBy: observation.loggedBy || undefined,
                  medication: item.medication,
                },
              ],
            };
            transformedData.push(newData);
          }
        });
      } else if (medicationChartData) {
        const newChartData: ChartData = {
          date: item.date,
          medication: item.medication,
        };
        chartData.push(newChartData);
        if (transformedData.length === 0) {
          const newData: TrackingHistoryChartData = {
            doseUnit: originalData.doseUnit,
            chartData: chartData,
          };
          transformedData.push(newData);
        } else {
          transformedData[0].chartData = chartData;
        }
      }
    });
    return transformedData;
  };

  return (
    <>
      <ChartLegend rating={observationChartData} trend={observationChartData} doseTaken={medicationChartData} baseline={medicationBaseline} />
      <Box sx={{ display: "flex", padding: "16px", borderRadius: "8px", backgroundColor: "rgba(249, 249, 249, 1)", marginBottom: "40px" }}>
        <InfoIcon sx={{ width: "16px", height: "16px", marginRight: "8px" }} />
        <DefaultText>{t("chart-detailed-information")}</DefaultText>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "40px" }}>
        {transformData(trackingHistoryData).map((chartData, index) => (
          <TrackingHistoryChart key={index} data={chartData as TrackingHistoryChartData} numberOfDays={parseInt(patientProfilePageState.numberOfDays as string)} />
        ))}
      </Box>
    </>
  );
};

export default observer(TrackingHistoryChartsGroup);
