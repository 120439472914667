import { SxProps } from "@mui/material";
import DefaultChip from "./DefaultChip";

interface DosesSourceChipProps {
  source: string;
  sx?: SxProps;
}

const DosesSourceChip = ({ source, sx }: DosesSourceChipProps) => {
  return (
    <DefaultChip
      text={source}
      sx={{
        marginTop: "4px",
        width: "100px",
        height: "24px",
        border: "1px solid rgba(179, 187, 196, 1)",
        fontSize: "10px",
        color: "rgba(78, 93, 105, 1)",
        ...sx,
      }}
    />
  );
};

export default DosesSourceChip;
