import { Box, SxProps } from "@mui/material";
import PillDoserIcon from "./icons/PillDoserIcon";
import { formatTimestamp } from "../util/date";
import DefaultText from "./DefaultText";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { DosageManagerInfo } from "../model/report.model";
import TextLabel from "./TextLabel";
import DoseChangeInformation from "./DoseChangeInformation";
import { useTranslation } from "react-i18next";

interface DoserInformationProps {
  dosageManagerInformation: DosageManagerInfo;
  initiallyScheduledDose: string;
  sx?: SxProps;
}

const DoserInformation = ({ dosageManagerInformation, initiallyScheduledDose, sx }: DoserInformationProps) => {
  const { t } = useTranslation();
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 4,
    borderRadius: 16,
    width: 200,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "rgba(231, 234, 238, 1)",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "rgba(145, 84, 201, 1)",
    },
  }));
  return (
    <>
      <Box sx={{ display: "flex", paddingY: "12px", gap: "20px" }}>
        <PillDoserIcon />
        <Box>
          <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)", ...sx }}>
            {t("last-synced")}: {formatTimestamp(dosageManagerInformation.lastSyncTimestamp, "MM/DD/YYYY HH:mm")}
          </DefaultText>
          <TextLabel sx={{ color: "rgba(145, 84, 201, 1)", fontWeight: 700, paddingBottom: "8px" }}>
            {dosageManagerInformation.remainingDoses} {dosageManagerInformation.remainingDoses <= 1 ? t("dose") : t("doses")} {t("left")}
          </TextLabel>
          <BorderLinearProgress variant="determinate" value={dosageManagerInformation.remainingDosesPercentage} />
        </Box>
      </Box>
      {dosageManagerInformation.doseChangeHistory.length > 0 && (
        <DoseChangeInformation doseChangeHistory={dosageManagerInformation.doseChangeHistory} initiallyScheduledDose={initiallyScheduledDose} />
      )}
    </>
  );
};

export default DoserInformation;
