import { IconButton, Menu as MUIMenu, MenuItem, MenuList } from "@mui/material";
import ChevronUpIcon from "./icons/ChevronUpIcon";
import ChevronDownIcon from "./icons/ChevronDownIcon";
import { SetStateAction, useState } from "react";
import PowerIcon from "./icons/PowerIcon";
import DefaultText from "./DefaultText";
import { useTranslation } from "react-i18next";
import DefaultModal from "./DefaultModal";
import LogoutConfirmation from "./LogoutConfirmation";
import Link from "./Link";
import SettingsIcon from "./icons/SettingsIcon";

const UserProfileMenu = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: { currentTarget: SetStateAction<HTMLElement | null> }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</IconButton>
      <MUIMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              minWidth: "208px",
            },
          },
        }}
      >
        <MenuList>
          <Link to="/settings/my-profile">
            <MenuItem onClick={handleClose} sx={{ padding: "0px 24px" }}>
              <SettingsIcon sx={{ marginRight: "10px" }} />
              <DefaultText sx={{ fontSize: "12px", lineHeight: "40px" }}>{t("settings")}</DefaultText>
            </MenuItem>
          </Link>
          <MenuItem onClick={handleLogout} sx={{ minHeight: "40px", padding: "0px 24px" }}>
            <PowerIcon sx={{ marginRight: "10px" }} />
            <DefaultText sx={{ fontSize: "12px", lineHeight: "40px" }}>{t("logout")}</DefaultText>
          </MenuItem>
        </MenuList>
      </MUIMenu>
      <DefaultModal modalTitle={t("logout.confirm")} isOpen={isModalOpen} onClose={handleCloseModal}>
        <LogoutConfirmation handleClose={handleCloseModal} />
      </DefaultModal>
    </div>
  );
};

export default UserProfileMenu;
