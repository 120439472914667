import { UserRole } from "./user.model";

export enum EventCategory {
  GOOD_DAY = "GOOD_DAY",
  BAD_DAY = "BAD_DAY",
}

export interface EventPerformer {
  fullName: string;
  role: UserRole;
  active: boolean;
  daysLoggedCount: number;
}

export interface EventDto {
  category: EventCategory;
  hcpEventGroup: string;
  daysLoggedCount: number;
  performers: EventPerformer[];
}
