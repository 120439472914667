import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import { Trans, useTranslation } from "react-i18next";
import { EventDto, EventPerformer } from "../model/event.model";
import DefaultModal from "./DefaultModal";
import { useState } from "react";
import EventSummary from "./EventSummary";
import { useAppState } from "../state/AppState";

interface EventsGroupProps {
  eventCategory: string;
  patientEvents: EventDto[];
}

const EventsGroup = ({ eventCategory, patientEvents }: EventsGroupProps) => {
  const { t } = useTranslation();
  const { patientProfilePageState } = useAppState();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [modalEventGroup, setModalEventGroup] = useState<EventDto | null>(null);

  const handleViewDetails = (modalEventGroup: EventDto) => {
    setDetailsOpen(true);
    setModalEventGroup(modalEventGroup);
  };

  return (
    <Box sx={{ flex: 1 }}>
      <DefaultText sx={{ fontSize: "18px", lineHeight: "24px", paddingBottom: "8px" }}>{eventCategory}</DefaultText>
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "9px" }}>
        {patientEvents.map((eventGroup, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgba(255, 255, 255, 1)",
              padding: "16px",
              border: "1px solid rgba(232, 229, 255, 1)",
              borderRadius: "8px",
              minWidth: "184px",
            }}
          >
            <DefaultText sx={{ fontSize: "14px", fontWeight: 700, paddingBottom: "8px" }}>{eventGroup.hcpEventGroup}</DefaultText>
            <TextHeader>{eventGroup.daysLoggedCount === 0 ? "-" : eventGroup.daysLoggedCount}</TextHeader>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)" }}>{t("days-logged-events")}</DefaultText>
              <Box onClick={() => handleViewDetails(eventGroup)} sx={{ cursor: "pointer" }}>
                <DefaultText sx={{ fontSize: "12px", color: "rgba(145, 84, 201, 1)", textDecoration: "underline" }}>{t("view-details")}</DefaultText>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <DefaultModal
        titleElement={
          <>
            <DefaultText sx={{ fontSize: "21px", lineHeight: "24px" }}>
              <Trans i18nKey={"event-summary-last"} values={{ numberOfDays: patientProfilePageState.numberOfDays }} />
            </DefaultText>
            <DefaultText sx={{ marginTop: "12px", fontSize: "16px", lineHeight: "24px" }}>
              {eventCategory} - <b>{modalEventGroup?.hcpEventGroup}</b>
            </DefaultText>
          </>
        }
        isOpen={detailsOpen}
        onClose={() => setDetailsOpen(false)}
      >
        <EventSummary performers={modalEventGroup?.performers as EventPerformer[]} handleClose={() => setDetailsOpen(false)} />
      </DefaultModal>
    </Box>
  );
};

export default EventsGroup;
