import { MedicationTrackingData, ScheduleType } from "../model/report.model";
import TextHeader from "./TextHeader";
import TrackingResults from "./TrackingResults";
import EmptyTrackingResult from "./EmptyTrackingResult";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

interface MedicationsTrackingResultsProps {
  medicationTrackingSummary: MedicationTrackingData[];
}

const MedicationsTrackingResults = ({ medicationTrackingSummary }: MedicationsTrackingResultsProps) => {
  const deviceMedicationSummary: MedicationTrackingData[] = medicationTrackingSummary.filter((data) => data.scheduleType === ScheduleType.DEVICE);
  const manualMedicationSummary: MedicationTrackingData[] = medicationTrackingSummary.filter((data) => data.scheduleType === ScheduleType.MANUAL);
  const { t } = useTranslation();

  return (
    <>
      <TextHeader sx={{ marginBottom: "32px" }}>{t("overview")}</TextHeader>
      {deviceMedicationSummary.length > 0 && (
        <Box sx={{ paddingBottom: "36px" }}>
          <TextHeader sx={{ marginBottom: "24px", fontSize: "24px" }}>{t("dosage-manager-tracking-results")}</TextHeader>
          {deviceMedicationSummary.map((data, index) => (
            <TrackingResults key={index} medicationTrackingData={data} />
          ))}
        </Box>
      )}

      {manualMedicationSummary.length > 0 && (
        <>
          <TextHeader sx={{ marginBottom: "24px", fontSize: "24px" }}>{t("manual-tracking-results")}</TextHeader>
          {manualMedicationSummary.map((data, index) => (
            <TrackingResults key={index} medicationTrackingData={data} sx={{ marginBottom: "32px" }} />
          ))}
        </>
      )}

      {medicationTrackingSummary.length === 0 && <EmptyTrackingResult header={t("medications")} message={t("medications-tracking-info")} sx={{ marginBottom: "32px" }} />}
    </>
  );
};

export default MedicationsTrackingResults;
