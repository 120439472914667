import dayjs, { Dayjs, extend } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import utc from "dayjs/plugin/utc";
extend(advancedFormat);
extend(customParseFormat);
extend(utc);
extend(timezone);

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

export const reformatDate = (date: string, currentFormat: string, newFormat: string): string => dayjs(date, currentFormat).format(newFormat);

export const formatTimestamp = (timestamp: number, format: string): string => dayjs(timestamp).format(format);

export const yearsPassedSinceDate = (date: string): number => dayjs().diff(dayjs(date), "years");

export const dayjsDate = (date: Date): Dayjs => dayjs(date);

export const getTimeAgo = (date: string): string => {
  return timeAgo.format(Date.parse(date));
};
