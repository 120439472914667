import { useTranslation } from "react-i18next";
import DefaultDosesCard from "./DefaultDosesCard";
import { Box } from "@mui/material";
import DosesSourceChip from "./DosesSourceChip";

interface TakenDosesCardProps {
  value: number;
  limit: number;
  doseSourceText?: boolean;
}
const TakenDosesCard = ({ value, limit, doseSourceText }: TakenDosesCardProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <DefaultDosesCard
        text={t("taken")}
        value={value}
        limit={limit}
        sx={{
          borderColor: "rgba(72, 197, 29, 1)",
          background: "rgba(218, 255, 205, 1)",
          color: "rgba(43, 142, 8, 1)",
        }}
      />
      {doseSourceText && <DosesSourceChip source={t("auto-captured")} />}
    </Box>
  );
};

export default TakenDosesCard;
