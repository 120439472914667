import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ObservationsGroup from "./ObservationsGroup";
import { MoodTrackingData, ObservationTrackingData, ObservationType, ScheduleType } from "../model/report.model";
import PatientMood from "./PatientMood";

interface ObservationsTrackingResultsProps {
  observationTrackingSummary: ObservationTrackingData[];
  moodTrackingSummary: MoodTrackingData[];
  scheduleType?: ScheduleType;
  scheduleId?: string;
}

const ObservationsTrackingResults = ({ observationTrackingSummary, moodTrackingSummary, scheduleType, scheduleId }: ObservationsTrackingResultsProps) => {
  const { t } = useTranslation();
  const symptomsSummary: ObservationTrackingData = observationTrackingSummary.find((data) => data.observationType === ObservationType.SYMPTOMS) as ObservationTrackingData;
  const reboundSymptomsSummary: ObservationTrackingData = observationTrackingSummary.find(
    (data) => data.observationType === ObservationType.REBOUND_SYMPTOMS
  ) as ObservationTrackingData;
  const sideEffectsSummary: ObservationTrackingData = observationTrackingSummary.find((data) => data.observationType === ObservationType.SIDE_EFFECTS) as ObservationTrackingData;

  return (
    <>
      <Box sx={{ paddingBottom: "24px" }}>
        <TextHeader sx={{ marginBottom: "24px", fontSize: "24px" }}>{t("tracked-observations")}</TextHeader>
        <ObservationsGroup data={symptomsSummary.data} observationType={ObservationType.SYMPTOMS} scheduleType={scheduleType} scheduleId={scheduleId} />
        <ObservationsGroup
          data={reboundSymptomsSummary.data}
          observationType={ObservationType.REBOUND_SYMPTOMS}
          scheduleType={scheduleType}
          scheduleId={scheduleId}
          sx={{ height: "140px", paddingBottom: "90px" }}
        />
        <ObservationsGroup
          data={sideEffectsSummary.data}
          observationType={ObservationType.SIDE_EFFECTS}
          scheduleType={scheduleType}
          scheduleId={scheduleId}
          sx={{ height: "140px", paddingBottom: "90px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <PatientMood data={moodTrackingSummary} sx={{ minHeight: "164px" }} />
        </Box>
      </Box>
    </>
  );
};

export default ObservationsTrackingResults;
