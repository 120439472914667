import { useParams } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import DefaultRadioGroup from "./DefaultRadioGroup";
import { ratingScalesApi } from "../api/RatingScalesApi";
import { RatingScalesFormType, RatingScalesRespondent } from "../model/ratingScales.model";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultSelect from "./DefaultSelect";
import { validate, patterns } from "../util/validation";
import DefaultText from "./DefaultText";
import { useAppState } from "../state/AppState";
import DefaultTooltip from "./DefaultTooltip";
import { useClosePageModal } from "../util/closePageModal";

interface RequestVanderbiltFormProps {
  handleClose: () => void;
}

export interface DetailedRespondentData {
  label: string;
  value: string;
  fullName: string;
  extendedRole: string;
}

const RequestVanderbiltForm = ({ handleClose }: RequestVanderbiltFormProps) => {
  const { notification, patientProfilePageState } = useAppState();
  const { t } = useTranslation();
  const { id } = useParams();
  const [formType, setFormType] = useState("");
  const [respondents, setRespondents] = useState<RatingScalesRespondent[]>([]);
  const [respondentsFetched, setRespondentsFetched] = useState(false);
  const [selectedRespondentId, setSelectedRespondent] = useState("");
  const [selectedRespondentError, setSelectedRespondentError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const disabledSaveBtn = !formType || !respondentsFetched || selectedRespondentError.length > 0 || !selectedRespondentId || loading;

  useClosePageModal(formType);

  const ratingScalesFormTypes = [
    { value: RatingScalesFormType.ADHD_VANDERBILT_BASELINE, label: t(RatingScalesFormType.ADHD_VANDERBILT_BASELINE.toLowerCase()) },
    { value: RatingScalesFormType.ADHD_VANDERBILT_FOLLOW_UP, label: t(RatingScalesFormType.ADHD_VANDERBILT_FOLLOW_UP.toLowerCase()) },
  ];

  const handleFormType = (event: string) => {
    setFormType(event);
  };

  const handleRespondent = (event: string) => {
    setSelectedRespondent(event);
    const error = validate(event, [patterns.requiredField]);
    if (error) {
      setSelectedRespondentError(t(error));
    } else {
      setSelectedRespondentError("");
    }
  };

  const handleSend = async () => {
    setLoading(true);
    const ratingScalesRequest = {
      respondentId: selectedRespondentId,
      type: formType as RatingScalesFormType,
    };
    const result = await ratingScalesApi.createFormRequest(id as string, ratingScalesRequest);
    if (result.ok) {
      notification.addNotification({ title: t("rating-scale-form-sent"), message: t("respondent-will-be-notified") });
      patientProfilePageState.requestsListVersion++;
      handleClose();
    }
    setLoading(false);
  };

  const respondentExtendedRole = (respondent: RatingScalesRespondent): string => {
    if (respondent.relationType) {
      return `${t(respondent.role.toLowerCase())}, ${t(respondent.relationType.toLowerCase())}`;
    } else if (respondent.teacherProfession) {
      return `${t(respondent.role.toLowerCase())}, ${t(respondent.teacherProfession.toLowerCase())}`;
    } else {
      return t(respondent.role.toLowerCase());
    }
  };

  const formattedRespondents = useMemo((): DetailedRespondentData[] => {
    return respondents.map((respondent) => {
      return {
        value: respondent.id,
        label: `${respondent.firstName} ${respondent.lastName} - ${respondentExtendedRole(respondent)}`,
        fullName: `${respondent.firstName} ${respondent.lastName}`,
        extendedRole: respondentExtendedRole(respondent),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respondents]);

  const findRespondentById = (selectedRespondentId: string): DetailedRespondentData | undefined => {
    return formattedRespondents.find((respondent) => respondent.value === selectedRespondentId);
  };

  const handleCancel = () => {
    handleClose();
  };

  useEffect(() => {
    const getData = async () => {
      const result = await ratingScalesApi.getRespondents(id as string);
      if (result.ok && result.data) {
        setRespondents(result.data);
        setRespondentsFetched(true);
      }
    };
    getData();
  }, [id]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <DefaultRadioGroup data={ratingScalesFormTypes} value={formType} handleChange={handleFormType} sx={{ paddingBottom: "32px" }} />
        {!respondentsFetched && formType && <Skeleton variant="rounded" sx={{ height: "72px", width: "420px" }} />}
        {respondentsFetched && formType && respondents.length > 0 ? (
          <DefaultSelect
            value={selectedRespondentId}
            error={selectedRespondentError.length > 0}
            errorMsg={selectedRespondentError}
            selectHandler={handleRespondent}
            label={t("select-respondent")}
            placeholder={t("please-choose-one")}
            displayEmpty
            options={formattedRespondents}
            renderOption={(option: DetailedRespondentData) => (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "4px", padding: "2px 4px" }}>
                <DefaultText sx={{ fontWeight: 700 }}>{option.fullName}</DefaultText>
                <DefaultText sx={{ fontSize: "12px" }}>{option.extendedRole}</DefaultText>
              </Box>
            )}
            renderValue={
              <DefaultTooltip
                sx={{ maxWidth: "640px" }}
                title={
                  <Box sx={{ padding: "16px", display: "flex", flexDirection: "column", gap: "4px", flexWrap: "wrap" }}>
                    <DefaultText sx={{ fontWeight: 700 }}>{findRespondentById(selectedRespondentId)?.fullName}</DefaultText>
                    <DefaultText sx={{ fontSize: "12px" }}>{findRespondentById(selectedRespondentId)?.extendedRole}</DefaultText>
                  </Box>
                }
              >
                <DefaultText>{`${findRespondentById(selectedRespondentId)?.label}`}</DefaultText>
              </DefaultTooltip>
            }
            sx={{ width: "420px" }}
          />
        ) : (
          respondentsFetched && formType && <DefaultText>{t("no-respondents-available")}</DefaultText>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "16px", paddingTop: "40px" }}>
        <DefaultSecondaryButton onClick={handleCancel} sx={{ height: "44px", minWidth: "84px" }}>
          {t("cancel")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleSend} isLoading={loading} disabled={disabledSaveBtn} sx={{ height: "44px", minWidth: "90px" }}>
          {t("send")}
        </DefaultPrimaryButton>
      </Box>
    </>
  );
};

export default RequestVanderbiltForm;
