import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { RatingScalesFormAnsweredQuestion } from "../model/ratingScales.model";
import DefaultChip from "./DefaultChip";

interface RatingScalesQuestionProps {
  question: RatingScalesFormAnsweredQuestion;
  sx?: SxProps;
}

const RatingScalesQuestion = ({ question, sx }: RatingScalesQuestionProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: question.order ? undefined : "column",
        width: "100%",
        justifyContent: "space-between",
        gap: question.order ? "100px" : "4px",
        ...sx,
      }}
    >
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: question.type === "text" ? 700 : 400 }}>
        {question.order && `${question.order}. `}
        {question.text}
      </DefaultText>
      {question.answer?.text && question.answer?.integer !== undefined && (
        <DefaultChip
          text={`${question.answer?.text}${question.answer?.integer ? ` (${question.answer?.integer})` : ""}`}
          sx={{ padding: "4px 10px", backgroundColor: "rgba(240, 244, 250, 1)", fontSize: "16px" }}
        />
      )}
      {(!question.answer?.text && question.answer?.integer !== undefined) || (question.answer?.text && question.answer?.integer === undefined) ? (
        <DefaultText sx={{ fontSize: "18px", lineHeight: "24px", fontWeight: question.type === "text" ? 400 : 700 }}>
          {question.answer?.text || question.answer?.integer}
        </DefaultText>
      ) : undefined}
    </Box>
  );
};

export default RatingScalesQuestion;
