import { Box, SxProps } from "@mui/material";
import { t } from "i18next";
import DefaultAccordion from "./DefaultAccordion";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import WarningIcon from "./icons/Warning";
import { RatingScalesCalculationResult } from "../model/ratingScales.model";
import CalculationResult from "./CalculationResult";

interface RatingScalesSummaryProps {
  scoringInstructions: RatingScalesCalculationResult[];
  sx?: SxProps;
}

const RatingScalesSummary = ({ scoringInstructions, sx }: RatingScalesSummaryProps) => {
  return (
    <Box sx={{ ...sx }}>
      <TextHeader sx={{ marginBottom: "24px" }}>{t("summary")}</TextHeader>
      <DefaultAccordion
        summary={
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <DefaultText sx={{ fontSize: "21px", lineHeight: "24px", fontWeight: 700 }}>{t("scoring-and-instructions")}</DefaultText>
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px" }}>
              {scoringInstructions.map(
                (scoringInstruction, index) =>
                  scoringInstruction.suspected && (
                    <DefaultText
                      key={index}
                      sx={{ fontWeight: 700, color: "#966E07", padding: "12px 16px", backgroundColor: "#FFEAA7", border: "1px solid #966E07", borderRadius: "8px" }}
                    >
                      {t("suspected")} {scoringInstruction.conditionName}
                    </DefaultText>
                  )
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "32px",
                margin: "0 0 0 -33px",
                border: "1px solid rgba(213, 219, 227, 1)",
                backgroundColor: "rgba(247, 248, 251, 1)",
              }}
            >
              <WarningIcon black sx={{ width: "60px", height: "60px", marginRight: "20px" }} />
              <DefaultText sx={{ lineHeight: "24px" }}>{t("scoring-and-instructions-warning")}</DefaultText>
            </Box>
            <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("scoring-and-instructions-summary")}</DefaultText>
          </Box>
        }
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          {scoringInstructions.map((scoringInstruction, index) => (
            <CalculationResult key={index} scoringInstruction={scoringInstruction} sx={{ marginBottom: "16px" }} />
          ))}
        </Box>
      </DefaultAccordion>
    </Box>
  );
};

export default RatingScalesSummary;
