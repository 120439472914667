import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import hcpImg from "../assets/img/hcp.png";

const HcpImageDescription = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundImage: `url(${hcpImg})`,
        backgroundPosition: "center",
        width: "769px",
        height: "944px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        borderRadius: "30px",
      }}
    >
      <TextHeader sx={{ color: "rgba(255, 255, 255, 1)", padding: "100px 5% 15px 5%" }}>{t("hcp-image-header")}</TextHeader>
      <DefaultText sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "18x", lineHeight: "24px", width: "350px" }}>{t("hcp-image-message")}</DefaultText>
    </Box>
  );
};

export default HcpImageDescription;
