import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { useTranslation } from "react-i18next";

interface DefaultDosesCardProps {
  value: number;
  limit: number;
  text: string;
  sx?: SxProps;
}

const DefaultDosesCard = ({ text, value, limit, sx }: DefaultDosesCardProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        sx={{
          background: "rgba(249, 249, 249, 1)",
          width: "100px",
          height: "84px",
          border: "1px solid rgba(213, 219, 227, 1)",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          ...sx,
        }}
      >
        <DefaultText sx={{ fontSize: "10px", ...sx, paddingBottom: "4px" }}>{text}</DefaultText>
        <DefaultText sx={{ fontSize: "28px", lineHeight: "32px", fontWeight: 700, ...sx }}>{value.toString()}</DefaultText>
        <DefaultText sx={{ fontSize: "10px", ...sx }}>
          {t("of")} {limit} {t("scheduled")}
        </DefaultText>
      </Box>
    </Box>
  );
};

export default DefaultDosesCard;
