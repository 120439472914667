import { Box, Link, Modal, SxProps } from "@mui/material";
import React from "react";
import DefaultText from "./DefaultText";
import CloseIcon from "./icons/CloseIcon";

interface DefaultModalProps {
  modalTitle?: string;
  titleElement?: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  sx?: SxProps;
}

const DefaultModal = ({ modalTitle, titleElement, children, isOpen, onClose, sx }: DefaultModalProps) => {
  return (
    <>
      <Modal open={isOpen} onClose={onClose} sx={{ fontSize: "12px", color: "rgba(112, 124, 136, 1)", ...sx }}>
        <Box
          sx={{
            width: "580px",
            padding: "40px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "8px",
            background: "#FFFFFF",
            boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
            maxHeight: "600px",
            overflowY: "auto",
            outline: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              paddingBottom: "24px",
              marginBottom: "24px",
              borderBottom: "1px solid rgba(179, 187, 196, 1)",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {modalTitle && <DefaultText sx={{ fontSize: "21px", lineHeight: "24px" }}>{modalTitle}</DefaultText>}
              {titleElement}
            </Box>
            <Link component="button" onClick={onClose}>
              <CloseIcon />
            </Link>
          </Box>
          {children}
        </Box>
      </Modal>
    </>
  );
};

export default DefaultModal;
