import { Api, ApiResponse, ApiResponsePromise, withErrorHandling, withErrorPage } from "./Api";
import { InvitationData, VerificationData } from "../model/invitationData.model";
import { RegistrationDto } from "../model/user.model";
import { HealthcareProviders } from "../model/healthcareProvider.model";
import { TerminablePromise, createTerminableRequest } from "./controllers/TerminableRequest";

class InvitationApi extends Api {
  getAccessData = (invitationId: string, invitationToken: string): ApiResponsePromise<InvitationData> => {
    const url = `invitations/${invitationId}/access`;
    return this.post(url, withErrorHandling({ requestPayload: { token: invitationToken } }));
  };
  validateHrn = (invitationId: string, invitationToken: string, patientHealthRecordNumber: string): ApiResponsePromise<VerificationData> => {
    const url = `invitations/${invitationId}/verification`;
    return this.post(url, withErrorHandling({ requestPayload: { token: invitationToken, patientHealthRecordNumber } }));
  };
  registerHcp = (registrationDto: RegistrationDto): ApiResponsePromise<void> => {
    const { invitationId, registrationToken, firstName, lastName, password, specialty, providerId, practiceName, practiceLocation, acceptedTermsAndConditions } = registrationDto;
    const url = `invitations/${invitationId}/register`;
    const requestPayload = { registrationToken, firstName, lastName, password, specialty, providerId, practiceName, practiceLocation, acceptedTermsAndConditions };
    return this.post(url, withErrorPage({ requestPayload, expectJsonResponse: false }));
  };
  getHealthcareProviders = (invitationId: string, registrationToken: string, namePrefix: string): TerminablePromise<HealthcareProviders> => {
    const url = `invitations/${invitationId}/healthcareProviders?namePrefix=${namePrefix}`;
    const response = createTerminableRequest<ApiResponse<HealthcareProviders>>((signal) => this.get(url, withErrorHandling({ authHeader: registrationToken, signal })));
    return {
      promise: response.promise.then((apiResponse) => apiResponse.data) as Promise<HealthcareProviders>,
      abort: response.abort,
    };
  };
}

export const invitationApi = new InvitationApi();
