import { Box, Skeleton } from "@mui/material";
import MedicationsTrackingResults from "./MedicationsTrackingResults";
import { MedicationTrackingData, ObservationTrackingData, MoodTrackingData, PatientReport, ScheduleType } from "../model/report.model";
import { useState, useEffect } from "react";
import { patientApi } from "../api/PatientApi";
import { withLoader } from "../hoc/withLoader";
import { useAppState } from "../state/AppState";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import ObservationsTrackingResults from "./ObservationsTrackingResults";
import PatientEvents from "./PatientEvents";
import { userApi } from "../api/UserApi";
import { EventDto } from "../model/event.model";
import { PatientProfileTab } from "../state/PatientProfilePageState";

const LoadingMedicationsTrackingResults = withLoader(MedicationsTrackingResults);
const LoadingObservationsTrackingResults = withLoader(ObservationsTrackingResults);
const LoadingEventsTrackingResults = withLoader(PatientEvents);

const PatientReports = () => {
  const { id } = useParams();
  const [patientReport, setPatientReport] = useState<PatientReport | null>(null);
  const { dictionary, patientProfilePageState } = useAppState();
  const [scheduleType, setScheduleType] = useState<ScheduleType | undefined>(undefined);
  const [scheduleId, setScheduleId] = useState<string | undefined>(undefined);
  const [eventsTrackingSummary, setEventsTrackingSummary] = useState<EventDto[] | null>(null);

  const scheduleData = (reportData: PatientReport) => {
    if (reportData.medicationTrackingSummary.length > 0) {
      const primaryScheduleData = reportData.medicationTrackingSummary.find((medication: MedicationTrackingData) => medication.scheduleType === ScheduleType.DEVICE);
      if (primaryScheduleData) {
        setScheduleType(primaryScheduleData.scheduleType);
        setScheduleId(primaryScheduleData.scheduleId);
      } else {
        const secondaryScheduleData = reportData.medicationTrackingSummary.find((medication: MedicationTrackingData) => medication.scheduleType === ScheduleType.MANUAL);
        setScheduleType(secondaryScheduleData?.scheduleType);
        setScheduleId(secondaryScheduleData?.scheduleId);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      const reportResponse = await patientApi.getReport(id as string, patientProfilePageState.timeRangeInput);
      if (reportResponse.ok && reportResponse.data) {
        setPatientReport(reportResponse.data);
        scheduleData(reportResponse.data);
      }
      const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
      const dictionaryResponse = await userApi.getDictionary(userLocale);
      if (dictionaryResponse.ok && dictionaryResponse.data) {
        dictionary.eventTypes = dictionaryResponse.data.eventTypes;
        dictionary.hcpEventGroups = dictionaryResponse.data.hcpEventGroups;
      }
      const eventsResponse = await patientApi.getEvents(id as string, patientProfilePageState.timeRangeInput);
      if (eventsResponse.ok && eventsResponse.data) {
        setEventsTrackingSummary(eventsResponse.data as []);
      }
    };
    setEventsTrackingSummary(null);
    setPatientReport(null);
    if (patientProfilePageState.numberOfDays) {
      getData();
    }
  }, [dictionary, id, patientProfilePageState, patientProfilePageState.timeRangeInput]);

  useEffect(() => {
    patientProfilePageState.activeTab = PatientProfileTab.REPORT;
  }, [patientProfilePageState]);

  return (
    <Box sx={{ minWidth: "940px" }}>
      <LoadingMedicationsTrackingResults medicationTrackingSummary={patientReport?.medicationTrackingSummary as MedicationTrackingData[]} isLoading={patientReport === null}>
        <Box sx={{ height: 32, flex: 1, display: "flex", paddingBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 0.3 }} />
        </Box>
        <Box sx={{ height: 200, flex: 1, display: "flex", paddingBottom: "36px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
      </LoadingMedicationsTrackingResults>
      <LoadingObservationsTrackingResults
        observationTrackingSummary={patientReport?.observationTrackingSummary as ObservationTrackingData[]}
        moodTrackingSummary={patientReport?.moodTrackingSummary as MoodTrackingData[]}
        scheduleType={scheduleType}
        scheduleId={scheduleId}
        isLoading={patientReport === null}
      >
        <Box sx={{ height: 32, flex: 1, display: "flex", paddingBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 0.3 }} />
        </Box>
        <Box sx={{ height: 365, flex: 1, display: "flex", paddingBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
        <Box sx={{ height: 365, flex: 1, display: "flex", paddingBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
        <Box sx={{ height: 365, flex: 1, display: "flex", paddingBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
        <Box sx={{ height: 215, flex: 1, display: "flex", paddingBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
      </LoadingObservationsTrackingResults>
      <LoadingEventsTrackingResults patientEvents={eventsTrackingSummary as EventDto[]} isLoading={eventsTrackingSummary === null}>
        <Box sx={{ height: 360, flex: 1, display: "flex", paddingBottom: "24px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
        </Box>
      </LoadingEventsTrackingResults>
    </Box>
  );
};

export default observer(PatientReports);
