import { Box, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultText from "./DefaultText";
import { CareTeamMember } from "../model/user.model";

interface CareTeamMembersProps {
  careTeamMembers: CareTeamMember[];
  sx?: SxProps;
}

const CareTeamMembers = ({ careTeamMembers, sx }: CareTeamMembersProps) => {
  const { t } = useTranslation();

  const subRole = (careTeamMember: CareTeamMember) => {
    if (careTeamMember.specialty) {
      return t(`specialty.${careTeamMember.specialty}`);
    } else if (careTeamMember.teacherProfession) {
      return t(careTeamMember.teacherProfession.toLocaleLowerCase());
    } else if (careTeamMember.relationType) {
      return t(careTeamMember.relationType.toLocaleLowerCase());
    }
  };

  return (
    <Box sx={{ ...sx }}>
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", backgroundColor: "rgba(231, 234, 238, 1)", padding: "12px 10px" }}>
        <DefaultText sx={{ fontSize: "12px", marginRight: "10px", borderRight: "1px solid rgba(179, 187, 196, 1)" }}>{t("name")}</DefaultText>
        <DefaultText sx={{ fontSize: "12px", marginRight: "10px", borderRight: "1px solid rgba(179, 187, 196, 1)" }}>{t("role")}</DefaultText>
        <DefaultText sx={{ fontSize: "12px" }}>{t("email")}</DefaultText>
      </Box>
      {careTeamMembers.map((careTeamMember, index) => (
        <Box
          key={index}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            alignItems: "center",
            padding: "8px 10px",
            border: "1px solid rgba(222, 227, 233, 1)",
            borderTop: "none",
          }}
        >
          <DefaultText
            sx={{ fontSize: "12px", fontWeight: 700, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          >{`${careTeamMember.firstName} ${careTeamMember.lastName}`}</DefaultText>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <DefaultText sx={{ fontSize: "12px" }}>{t(careTeamMember.role.toLocaleLowerCase())}</DefaultText>
            <DefaultText sx={{ fontSize: "12px", color: "rgba(78, 93, 105, 1)" }}>{subRole(careTeamMember)}</DefaultText>
          </Box>
          <DefaultText sx={{ fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{careTeamMember.email}</DefaultText>
        </Box>
      ))}
    </Box>
  );
};

export default CareTeamMembers;
